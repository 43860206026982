<template>
  <div
    id="termsconditions"
    class="container"
  >
    <p>
      <b><u>Terms and Conditions</u></b>
    </p>
    <ul class="terms-and-conditions">
      <li class="terms-and-conditions">
        1. SERVICES AND SUPPORT
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            1.1. THE ENTROLYTICS PLATFORM AND ASSOCIATED TOOLS PROVIDED AS PART OF THIS SERVICE ARE FOR RESEARCH AND NON-CLINICAL USE ONLY. 
          </li>
          <li class="terms-and-conditions">
            1.2. Subject to the terms and conditions of this Agreement, Motilent
            will provide You with access to the Services (Entrolytics) through
            the internet. The Services are subject to modification from time to
            time at Motilent’s sole discretion, for any purpose deemed
            appropriate by Motilent. Motilent will use reasonable efforts to
            give You prior written notice of any such modification. Motilent
            reserves the right to monitor Your Services usage to ensure that You
            are abiding by terms in the Order Form.
          </li>
          <li class="terms-and-conditions">
            1.3. Motilent will undertake commercially reasonable efforts to
            provide the Services. Notwithstanding the foregoing, Motilent
            reserves the right to suspend You access to the Services: (i) for
            scheduled or emergency maintenance, or (ii) in the event You are in
            breach of this Agreement, including failure to pay any amounts due
            to Motilent.
          </li>
          <li class="terms-and-conditions">
            1.4. Motilent will use reasonable efforts to provide You with its
            standard support services as indicated on Your Order Form (if
            applicable).
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        2. RESTRICTIONS AND RESPONSIBILITIES
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            2.1. You will not, and will not permit any third party to: reverse
            engineer, decompile, disassemble or otherwise attempt to discover
            the source code, object code or underlying structure, ideas or
            algorithms of the Services or any software, documentation or data
            related to the Services (“Software”) (provided that reverse
            engineering is prohibited only to the extent such prohibition is not
            contrary to applicable law); modify, translate, or create derivative
            works based on the Services or Software; use the Services or
            Software for timesharing or service bureau purposes or for any
            purpose other than for its own benefit; or use the Services or
            Software other than in accordance with this Agreement and in
            compliance with all applicable laws and regulations (including but
            not limited to any European privacy laws), intellectual property,
            consumer and child protection, obscenity or defamation).
          </li>
          <li class="terms-and-conditions">
            2.2. You will cooperate with Motilent in connection with the
            performance of this Agreement by making available such personnel and
            information as may be reasonably required and taking such other
            actions as Motilent may reasonably request. You will also cooperate
            with Motilent in establishing a password or other procedures for
            verifying that only designated employees of You have access to any
            administrative functions of the Services.
          </li>
          <li class="terms-and-conditions">
            2.3. You will be responsible for maintaining the security of Your
            account, passwords (including but not limited to administrative and
            user passwords) and files, and for all uses of Your account with or
            without You’re knowledge or consent.
          </li>
          <li class="terms-and-conditions">
            2.4 You will ensure any and all patient identifiable information is
            removed from data and fully anonymised prior to upload or transfer
            to Motilent Software.
          </li>
        </ul>
      </li>

      <li class="terms-and-conditions">
        3. CONFIDENTIALITY
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            3.1. Each party (the “Receiving Party”) understands that the other
            party (the “Disclosing Party”) has disclosed or may disclose
            information relating to the Disclosing Party’s technology or
            business (hereinafter referred to as “Confidential Information” of
            the Disclosing Party).
          </li>
          <li class="terms-and-conditions">
            3.2. The Receiving Party agrees: (i) not to divulge to any third
            party any such Confidential Information, (ii) to give access to such
            Confidential Information solely to those employees with a need to
            have access thereto for purposes of this Agreement, and (iii) to
            take the same security precautions to protect against disclosure or
            unauthorized use of such Confidential Information that the party
            takes with its own proprietary information, but in no event will a
            party apply less than reasonable precautions to protect such
            Confidential Information. The Disclosing Party agrees that the
            foregoing will not apply with respect to any information that the
            Receiving Party can document (a) is or becomes generally available
            to the public without any action by, or involvement of, the
            Receiving Party, or (b) was in its possession or known by it prior
            to receipt from the Disclosing Party, or (c) was rightfully
            disclosed to it without restriction by a third party, or (d) was
            independently developed without use of any Confidential Information
            of the Disclosing Party. Nothing in this Agreement will prevent the
            Receiving Party from disclosing the Confidential Information
            pursuant to any judicial or governmental order, provided that the
            Receiving Party gives the Disclosing Party reasonable prior notice
            of such disclosure to contest such order. In any event, Motilent may
            collect data with respect to and report on the aggregate response
            rate and other aggregate measures of the Services’ performance.
          </li>
          <li class="terms-and-conditions">
            3.3. You acknowledges that Motilent does not wish to receive any
            Confidential Information from You that is not necessary for Motilent
            to perform its obligations under this Agreement, and, unless the
            parties specifically agree otherwise, Motilent may reasonably
            presume that any unrelated information received from You is not
            proprietary nor Confidential Information.
          </li>
          <li class="terms-and-conditions">
            3.4. Both Parties will have the right to disclose the existence
            (including but not limited to mentioning Parties names and logos in
            marketing and advertising material) but not the terms and conditions
            of this Agreement, unless such disclosure is approved in writing by
            both Parties prior to such disclosure, or is included in a filing
            required to be made by a Party with a governmental authority
            (provided such party will use reasonable efforts to obtain
            confidential treatment or a protective order) or is made on a
            confidential basis as reasonably necessary to potential investors or
            acquirors.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        4. INTELLECTUAL PROPERTY RIGHTS
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            4.1. Except as expressly set forth herein, Motilent alone (and its
            licensors, where applicable) will retain all intellectual property
            rights relating to the Service or the Software or any suggestions,
            ideas, enhancement requests, feedback, recommendations or other
            information provided by You or any third party relating to the
            Service and/or the Software, which are hereby assigned to Motilent.
            You will not copy, distribute, reproduce or use any of the foregoing
            except as expressly permitted under this Agreement. This Agreement
            is not a sale and does not convey to You any rights of ownership in
            or related to the Service or Software, or any intellectual property
            rights.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        5. TERMINATION
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            5.1. Subject to earlier termination as provided in the Order Form,
            this Service Agreement is for the Service Term as specified in the
            Order Form.
          </li>
          <li class="terms-and-conditions">
            5.2. Motilent may terminate this Agreement upon thirty (30) days
            prior notice to You. Additionally, either party may terminate this
            Agreement, without notice, (i) upon the institution by or against
            the other party of insolvency, receivership or bankruptcy
            proceedings, (ii) upon the other party&#39;s making an assignment
            for the benefit of creditors, (iii) upon the other party&#39;s
            dissolution or ceasing to do business or (iv) gross or repeated
            breaches of data privacy obligations.
          </li>
          <li class="terms-and-conditions">
            5.3. All sections of this Service Agreement which by their nature
            should survive termination will survive termination, including,
            without limitation, restrictions, accrued rights to payment,
            confidentiality obligations, intellectual property rights, warranty
            disclaimers, and limitations of liability.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        6. WARRANTY DISCLAIMER
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            6.1. WITHOUT LIMITING MOTILENT’S OBLIGATIONS UNDER SECTION 1.2, THE
            SERVICES AND MOTILENT CONFIDENTIAL INFORMATION AND ANYTHING PROVIDED
            IN CONNECTION WITH THIS AGREEMENT ARE PROVIDED &quot;AS-IS,&quot;
            WITHOUT ANY WARRANTIES OF ANY KIND. MOTILENT HEREBY DISCLAIMS ALL
            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ALL
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, TITLE, AND NON-INFRINGEMENT.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        7. LIMITATION OF LIABILITY
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            7.1. IN NO EVENT WILL MOTILENT BE LIABLE FOR ANY INDIRECT, PUNITIVE,
            INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN
            ANY WAY CONNECTED WITH THE USE OF THE SERVICES OR ANYTHING PROVIDED
            IN CONNECTION WITH THIS AGREEMENT, THE DELAY OR INABILITY TO USE THE
            SERVICES OR ANYTHING PROVIDED IN CONNECTION WITH THIS AGREEMENT OR
            OTHERWISE ARISING FROM THIS AGREEMENT, INCLUDING WITHOUT LIMITATION,
            LOSS OF REVENUE OR ANTICIPATED PROFITS OR LOST BUSINESS OR LOST
            SALES, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
            STRICT LIABILITY, OR OTHERWISE, EVEN IF MOTILENT HAS BEEN ADVISED OF
            THE POSSIBILITY OF DAMAGES. THE TOTAL LIABILITY OF MOTILENT, WHETHER
            BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE OR STRICT LIABILITY),
            OR OTHERWISE, WILL NOT EXCEED, IN THE AGGREGATE, THE LESSER OF (i)
            TEN THOUSAND DOLLARS, OR (ii) THE FEES PAID TO MOTILENT HEREUNDER IN
            THE THREE MONTH PERIOD ENDING ON THE DATE THAT A CLAIM OR DEMAND IS
            FIRST ASSERTED. THE FOREGOING LIMITATIONS WILL APPLY NOTWITHSTANDING
            ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        8. MISCELLANEOUS
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            8.1. If any provision of this Agreement is found to be unenforceable
            or invalid, that provision will be limited or eliminated to the
            minimum extent necessary so that this Agreement will otherwise
            remain in full force and effect and enforceable. This Agreement is
            not assignable, transferable or sublicensable by You except with
            Motilent’s prior written consent. Motilent may transfer and assign
            any of its rights and obligations under this Agreement with written
            notice to You. Both parties agree that this Agreement is the
            complete and exclusive statement of the mutual understanding of the
            parties and supersedes and cancels all previous written and oral
            agreements, communications and other understandings relating to the
            subject matter of this Agreement, and that all waivers and
            modifications must be in a writing signed by both parties, except as
            otherwise provided herein. No agency, partnership, joint venture, or
            employment is created as a result of this Agreement and You does not
            have any authority of any kind to bind Motilent in any respect
            whatsoever. In any action or proceeding to enforce rights under this
            Agreement, the prevailing party will be entitled to recover costs
            and attorneys’ fees. All notices under this Agreement will be in
            writing and will be deemed to have been duly given when received, if
            personally delivered; when receipt is electronically confirmed, if
            transmitted by facsimile or e-mail; and upon receipt, if sent by
            certified or registered mail (return receipt requested), postage
            prepaid. Motilent will not be liable for any loss resulting from a
            cause over which it does not have direct control. This Agreement
            will be governed by the laws of England and Wales. You agrees to
            participate in press announcements, case studies, trade shows, or
            other forms reasonably requested by Motilent. Motilent is permitted
            to disclose that You are one of its customers to any third-party at
            its sole discretion.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      <b><u>Data Processing Agreement</u></b>
    </p>
    <ul class="terms-and-conditions">
      <li class="terms-and-conditions">
        Data Processing Agreement (“<b>Agreement</b>“) for use of the
        Entrolytics Service between
      </li>
      <li class="terms-and-conditions">
        "You"
      </li>
      <li class="terms-and-conditions">
        (the <b>“Company”</b>) and
      </li>
      <li class="terms-and-conditions">
        Motilent
      </li>
      <li class="terms-and-conditions">
        (the “Data Processor”)
      </li>
      <li class="terms-and-conditions">
        (together as the <b>“Parties”</b>)
      </li>
      <li class="terms-and-conditions">
        WHEREAS
      </li>
      <li class="terms-and-conditions">
        (A) The Company acts as a Data Controller.
      </li>
      <li class="terms-and-conditions">
        (B) The Company wishes to subcontract certain Services, which imply the
        processing of personal data, to the Data Processor.
      </li>
      <li class="terms-and-conditions">
        (C) The Parties seek to implement a data processing agreement that
        complies with the requirements of the current legal framework in
        relation to data processing and with the Regulation (EU) 2016/679 of the
        European Parliament and of the Council of 27 April 2016 on the
        protection of natural persons with regard to the processing of personal
        data and on the free movement of such data, and repealing Directive
        95/46/EC (General Data Protection Regulation).
      </li>
      <li class="terms-and-conditions">
        (D) The Parties wish to lay down their rights and obligations.
      </li>
    </ul>
    <ul class="terms-and-conditions">
      IT IS AGREED AS FOLLOWS:
    </ul>
    <ul class="terms-and-conditions">
      <li class="terms-and-conditions">
        1. Definitions and Interpretation
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            1.1. Unless otherwise defined herein, capitalized terms and
            expressions used in this Agreement shall have the following meaning:
            <ul class="terms-and-conditions">
              <li class="terms-and-conditions">
                1.1.1. “Agreement” means this Data Processing Agreement and all
                Schedules;
              </li>
              <li class="terms-and-conditions">
                1.1.2. “Company Personal Data” means any Personal Data Processed
                by a ontracted Processor on behalf of Company pursuant to or in
                connection with the Principal Agreement;
              </li>
              <li class="terms-and-conditions">
                1.1.3. “Contracted Processor” means a Sub processor;
              </li>
              <li class="terms-and-conditions">
                1.1.4. “Data Protection Laws” means EU Data Protection Laws and,
                to the extent applicable, the data protection or privacy laws of
                any other country;
              </li>
              <li class="terms-and-conditions">
                1.1.5. “EEA” means the European Economic Area;
              </li>
              <li class="terms-and-conditions">
                1.1.7. “GDPR” means EU General Data Protection Regulation
                2016/679;
              </li>
              <li class="terms-and-conditions">
                1.1.8. “Data Transfer” means:
                <ul class="terms-and-conditions">
                  <li class="terms-and-conditions">
                    1.1.8.1. a transfer of Company Personal Data from the
                    Company to a Contracted Processor; or
                  </li>
                  <li class="terms-and-conditions">
                    1.1.8.2. an onward transfer of Company Personal Data from a
                    Contracted Processor to a Subcontracted Processor, or
                    between two establishments of a Contracted Processor, in
                    each case, where such transfer would be prohibited by Data
                    Protection Laws (or by the terms of data transfer agreements
                    put in place to address the data transfer restrictions of
                    Data Protection Laws);
                  </li>
                </ul>
              </li>
              <li class="terms-and-conditions">
                1.1.9. “Motilent” is a company registered in England and Wales
                Registered Number: 08730130 at the following address:
                IDEALondon, 69 Wilson Street, London, EC2A 2BB
              </li>
              <li class="terms-and-conditions">
                1.1.10. The contact details of Motilent&#39;s data protection
                officer is Alex Menys
              </li>
              <li class="terms-and-conditions">
                1.1.11. “Services” means the Entrolytics service and associated
                support services the Company provides.
              </li>
              <li class="terms-and-conditions">
                1.1.12. “Sub processor” means any person appointed by or on
                behalf of Processor to process Personal Data on behalf of the
                Company in connection with the Agreement.
              </li>
            </ul>
          </li>
          <li class="terms-and-conditions">
            1.2. The terms, “Commission”, “Controller”, “Data Subject”, “Member
            State”, “Personal Data”, “Personal Data Breach”, “Processing” and
            “Supervisory Authority” shall have the same meaning as in the GDPR,
            and their cognate terms shall be construed accordingly.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        2. Processing of Company Personal Data
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            2.1. Processor shall:
            <ul class="terms-and-conditions">
              <li class="terms-and-conditions">
                2.1.1. comply with all applicable Data Protection Laws in the
                Processing of Company Personal Data; and
              </li>
              <li class="terms-and-conditions">
                2.1.2. not Process Company Personal Data other than on the
                relevant Company’s documented instructions.
              </li>
            </ul>
          </li>
          <li class="terms-and-conditions">
            2.2. The Company instructs Processor to process Company Personal
            Data.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        3. Processor Personnel
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            3.1. Processor shall take reasonable steps to ensure the reliability
            of any employee, agent or contractor of any Contracted Processor who
            may have access to the Company Personal Data, ensuring in each case
            that access is strictly limited to those individuals who need to
            know / access the relevant Company Personal Data, as strictly
            necessary for the purposes of the Services, and to comply with
            Applicable Laws in the context of that individual’s duties to the
            Contracted Processor, ensuring that all such individuals are subject
            to confidentiality undertakings or professional or statutory
            obligations of confidentiality.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        4. Security
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            4.1. Taking into account the state of the art, the costs of
            implementation and the nature, scope, context and purposes of
            Processing as well as the risk of varying likelihood and severity
            for the rights and freedoms of natural persons, Processor shall in
            relation to the Company Personal Data implement appropriate
            technical and organizational measures to ensure a level of security
            appropriate to that risk, including, as appropriate, the measures
            referred to in Article 32(1) of the GDPR.
          </li>
          <li class="terms-and-conditions">
            4.2. In assessing the appropriate level of security, Processor shall
            take account in particular of the risks that are presented by
            Processing, in particular from a Personal Data Breach.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        5. Subprocessing
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            5.1. Processor shall not appoint (or disclose any Company Personal
            Data to) any Sub processor unless required or authorised by the
            Company.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        6. Data Subject Rights
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            6.1. Taking into account the nature of the Processing, Processor
            shall assist the Company by implementing appropriate technical and
            organisational measures, insofar as this is possible, for the
            fulfilment of the Company obligations, as reasonably understood by
            Company, to respond to requests to exercise Data Subject rights
            under the Data Protection Laws.
          </li>
          <li class="terms-and-conditions">
            6.2. Processor shall:
            <ul class="terms-and-conditions">
              <li class="terms-and-conditions">
                6.2.1. promptly notify Company if it receives a request from a
                Data Subject under any Data Protection Law in respect of Company
                Personal Data; and
              </li>
              <li class="terms-and-conditions">
                6.2.2. ensure that it does not respond to that request except on
                the documented instructions of Company or as required by
                Applicable Laws to which the Processor is subject, in which case
                Processor shall to the extent permitted by Applicable Laws
                inform Company of that legal requirement before the Contracted
                Processor responds to the request.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        7. Personal Data Breach
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            7.1. Processor shall notify Company without undue delay upon
            Processor becoming aware of a Personal Data Breach affecting Company
            Personal Data, providing Company with sufficient information to
            allow the Company to meet any obligations to report or inform Data
            Subjects of the Personal Data Breach under the Data Protection Laws.
          </li>
          <li class="terms-and-conditions">
            7.2. Processor shall co-operate with the Company and take reasonable
            commercial steps as are directed by Company to assist in the
            investigation, mitigation and remediation of each such Personal Data
            Breach.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        8. Data Protection Impact Assessment and Prior Consultation Processor
        shall provide reasonable assistance to the Company with any data
        protection impact assessments, and prior consultations with Supervising
        Authorities or other competent data privacy authorities, which Company
        reasonably considers to be required by article 35 or 36 of the GDPR or
        equivalent provisions of any other Data Protection Law, in each case
        solely in relation to Processing of Company Personal Data by, and taking
        into account the nature of the Processing and information available to,
        the Contracted Processors.
      </li>
      <li class="terms-and-conditions">
        9. Deletion or return of Company Personal Data
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            9.1. Subject to this section 9 Processor shall promptly and in any
            event within 10 business days of the date of cessation of any
            Services involving the Processing of Company Personal Data (the
            “Cessation Date”), delete and procure the deletion of all copies of
            those Company Personal Data.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        10. Audit rights
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            10.1. Subject to this section 10, Processor shall make available to
            the Company on request all information necessary to demonstrate
            compliance with this Agreement, and shall allow for and contribute
            to audits, including inspections, by the Company or an auditor
            mandated by the Company in relation to the Processing of the Company
            Personal Data by the Contracted Processors.
          </li>
          <li class="terms-and-conditions">
            10.2. Information and audit rights of the Company only arise under
            section
            <ul class="terms-and-conditions">
              <li class="terms-and-conditions">
                10.2.1 to the extent that the Agreement does not otherwise give
                them information and audit rights meeting the relevant
                requirements of Data Protection Law.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        11. Data Transfer
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            11.1. The Processor may not transfer or authorize the transfer of
            Data to countries outside the EU and/or the European Economic Area
            (EEA) without the prior written consent of the Company. If personal
            data processed under this Agreement is transferred from a country
            within the European Economic Area to a country outside the European
            Economic Area, the Parties shall ensure that the personal data are
            adequately protected. To achieve this, the Parties shall, unless
            agreed otherwise, rely on EU approved standard contractual clauses
            for the transfer of personal data.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        12. General Terms
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            12.1. Confidentiality. Each Party must keep this Agreement and
            information it receives about the other Party and its business in
            connection with this Agreement (“Confidential Information”)
            confidential and must not use or disclose that Confidential
            Information without the prior written consent of the other Party
            except to the extent that:
            <ul class="terms-and-conditions">
              <li class="terms-and-conditions">
                (a) disclosure is required by law;
              </li>
              <li class="terms-and-conditions">
                (b) the relevant information is already in the public domain.
              </li>
            </ul>
          </li>
          <li class="terms-and-conditions">
            12.2. Notices. All notices and communications given under this
            Agreement must be in writing and will be delivered personally, sent
            by post or sent by email to the address or email address set out in
            the heading of this Agreement at such other address as notified from
            time to time by the Parties changing address.
          </li>
        </ul>
      </li>
      <li class="terms-and-conditions">
        13. Governing Law and Jurisdiction
        <ul class="terms-and-conditions">
          <li class="terms-and-conditions">
            13.1. This Agreement is governed by the laws of England and Wales.
          </li>
        </ul>
      </li>
    </ul>
    <ul class="terms-and-conditions">
      By using the Service provided by Motilent, Inc. You acknowledge that You
      have read and agree to these Terms and Conditions, to the Data Processing
      Agreement, and to the
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.motilent.co.uk/privacy-policy/"
      >Privacy Policy</a>.
    </ul>
  </div>
</template>
<script>
export default {
  name: 'TermsConditions',
};
</script>
