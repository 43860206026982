<i18n>
{
  "en": {
    "newprovider": "New provider",
    "nameProvider": "Name of the provider",
    "urlProvider": "Configuration URL of the provider",
    "urlnotvalid": "This url is not valid"

  },
  "fr": {
    "newprovider": "Nouveau provider",
    "nameProvider": "Nom du provider",
    "urlProvider": "URL de configuration",
    "urlnotvalid": "Cette url n'est pas valide"
  }
}
</i18n>

<template>
  <div>
    <div class="my-3 selection-button-container provider-position">
      <h4>
        <button
          type="button"
          class="btn btn-link btn-sm d-md-none"
          @click.stop="cancel"
        >
          <span>
            <v-icon name="arrow-left" color="white" />
          </span>
        </button>
        {{ $t('newprovider') }}
      </h4>
    </div>
    <form @submit.prevent="createProvider">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-3">
          <b>{{ $t('nameProvider') }}</b>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-9 mb-3">
          <input
            v-model="provider.name"
            v-focus
            type="text"
            :placeholder="$t('nameProvider')"
            class="form-control"
            required
            maxlength="1024"
          />
          <field-obligatory :state="provider.name !== ''" />
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-3">
          <b>{{ $t('urlProvider') }}</b>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-9 mb-3">
          <div class="input-group mb-3">
            <input
              v-model="provider.url"
              type="text"
              :placeholder="$t('urlProvider')"
              class="form-control"
              required
              maxlength="1024"
            />
            <div v-if="show" class="input-group-append">
              <state-provider
                :loading="loading"
                :check-u-r-l="checkedURL"
                :class-icon="'ml-2 mt-2'"
              />
            </div>
            <field-obligatory :state="provider.url !== ''" />
            <field-obligatory
              v-if="provider.url !== ''"
              :state="checkUrl(provider.url)"
              :text="$t('urlnotvalid')"
            />
          </div>
        </div>
      </div>
      <create-cancel-button
        :disabled="disabledCreate"
        class-col="offset-md-3 col-md-9"
        @cancel="cancel"
      />
    </form>
  </div>
</template>

<script>
import StateProvider from '@/components/providers/StateProvider';
import FieldObligatory from '@/components/globals/FieldObligatory';
import CreateCancelButton from '@/components/globals/CreateCancelButton';
import { validator } from '@/mixins/validator.js';

export default {
  name: 'NewProvider',
  components: { StateProvider, FieldObligatory, CreateCancelButton },
  mixins: [validator],
  props: {
    albumID: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      provider: {
        name: '',
        url: '',
      },
      show: false,
      loading: false,
      checkedURL: false,
    };
  },
  computed: {
    disabledCreate() {
      return (
        this.provider.name === '' ||
        this.provider.url === '' ||
        !this.checkUrl(this.provider.url) ||
        this.loading
      );
    },
  },
  methods: {
    createProvider() {
      this.setStateProvider(false, true, true);
      this.$store
        .dispatch('postProvider', {
          query: this.provider,
          albumID: this.albumID,
        })
        .then(res => {
          if (res.status !== 201) {
            this.setStateProvider(false, false, true);
          } else {
            this.$emit('done');
          }
        })
        .catch(err => {
          this.setStateProvider(false, false, true);
          // eslint-disable-next-line
          console.log(err);
        });
    },
    setStateProvider(checkURL, loading, show) {
      this.checkedURL = checkURL;
      this.loading = loading;
      this.show = show;
    },
    cancel() {
      this.$emit('done');
    },
  },
};
</script>
