<template>
  <div class="row">
    <div class="col-lg-2 col-xl-auto mb-4">
      <study-menu
        :study="study"
      />
    </div>
    <div

      class="col-sm-12 col-md-12 col-lg-10 col-xl-10"
    >
      <list-series
        v-if="study.flag.view === 'series' && (album.is_admin || getClientRoles().StudySeries)"
        :study="study"
        :source="source"
      />
      <comments-and-notifications
        v-if="study.flag.view === 'comments' && getClientRoles().StudyComments"
        :id="study.StudyInstanceUID.Value[0]"
        :write-comments="this.$route.name !== 'viewnologin'"
        scope="studies"
      />
      <study-metadata
        v-if="study.flag.view === 'study' && (album.is_admin || getClientRoles().StudyMetadata)"
        :id="study.StudyInstanceUID.Value[0]"
        scope="studies"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import commentsAndNotifications from '@/components/comments/commentsAndNotifications';
import studyMetadata from '@/components/study/studyMetadata';
import StudyMenu from '@/components/studieslist/StudyMenu';
import ListSeries from '@/components/series/ListSeries';
import { CurrentUser } from '../../mixins/currentuser.js';

export default {
  name: 'ListItemDetails',
  components: {
    commentsAndNotifications, studyMetadata, StudyMenu, ListSeries,
  },
  mixins: [CurrentUser],
  props: {
    studyUID: {
      type: String,
      required: true,
      default: '',
    },
    source: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    study() {
      return this.$store.getters.getStudyByUID(this.studyUID);
    },
    ...mapGetters({
      album: 'album'
    }),
  },
};
</script>
