<i18n>{
  "en": {
    "modality": "Modality",
    "numberimages": "Number of images",
    "description": "Description",
    "seriesdate": "Series date",
    "seriestime": "Series time",
    "openviewer": "Open Viewer",
    "applicationentity": "Application entity"
  },
  "fr": {
    "modality": "Modalité",
    "numberimages": "Nombre d'images",
    "description": "Description",
    "seriesdate": "Date de la série",
    "seriestime": "Heure de la série",
    "openviewer": "Ouvrir la visionneuse",
    "applicationentity": "Application entity"
  }
}
</i18n>

<template>
  <div class="seriesSummaryContainer">
    <div class="row justify-content-center">
      <div class="mb-2 preview">
        <div
          class="d-flex flex-row justify-content-center"
        >
          <img
            v-if="!loadingImage"
            :class="checkSR ? 'pointer' : ''"
            :src="serie.imgSrc"
            width="150"
            height="150"
            @click="checkSR ? openTab() : ''"
          >
          <bounce-loader
            :loading="loadingImage"
            color="white"
          />
        </div>
      </div>
    </div>
    <div class="row mb-2 ml-2">
      <b-form-checkbox
        v-model="isSelected"
      >
        <span
          class="pointer word-break font-white"
        >
          {{ imageTitle }}  <br>
          {{ serieDate }}
        </span>
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue';
import { ViewerToken } from '../../mixins/tokens.js';
import { CurrentUser } from '../../mixins/currentuser.js';
import { Viewer } from '@/mixins/viewer.js';

export default {
  name: 'SeriesSummary',
  components: { BounceLoader },
  mixins: [ViewerToken, CurrentUser, Viewer],
  props: {
    serie: {
      type: Object,
      required: true,
      default: () => {},
    },
    study: {
      type: Object,
      required: true,
      default: () => {},
    },
    albumId: {
      type: String,
      required: true,
      default: '',
    },
    source: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      studies: 'studies',
      series: 'series',
    }),
    checkSR() {
      if (this.serie.Modality !== undefined && this.serie.Modality.Value !== undefined) {
        return !this.serie.Modality.Value[0].includes('SR');
      }
      return true;
    },
    imageTitle() {
      let modality = '';
      let description = '';
      let numberInstances = '';
      if (this.serie.Modality !== undefined && this.serie.Modality.Value !== undefined) {
        [modality] = this.serie.Modality.Value;
      }
      if (this.serie.NumberOfSeriesRelatedInstances !== undefined && this.serie.NumberOfSeriesRelatedInstances.Value !== undefined) {
        numberInstances = `[ ${this.serie.NumberOfSeriesRelatedInstances.Value[0]} ]`;
      }
      if (this.serie.SeriesDescription !== undefined && this.serie.SeriesDescription.Value !== undefined) {
        [description] = this.serie.SeriesDescription.Value;
      }
      return `${modality} - ${description} ${numberInstances}`;
    },
    serieDate() {
      let seriesDate = '';
      let seriesTime = '';
      if (this.serie.SeriesDate && this.serie.SeriesDate.Value !== undefined) {
        seriesDate = Vue.options.filters.formatDate(this.serie.SeriesDate.Value[0]);
      }
      if (this.serie.SeriesTime && this.serie.SeriesTime.Value !== undefined) {
        seriesTime = Vue.options.filters.formatTM(this.serie.SeriesTime.Value[0]);
      }
      return `${seriesDate} ${seriesTime}`;
    },
    seriesInstanceUID() {
      return this.serie.SeriesInstanceUID.Value[0];
    },
    studyInstanceUID() {
      return this.study.StudyInstanceUID.Value[0];
    },
    selected() {
      return this.serie.flag.is_selected;
    },
    isSelected: {
      // getter
      get() {
        return this.selected;
      },
      // setter
      set(newValue) {
        const params = {
          StudyInstanceUID: this.studyInstanceUID,
          SeriesInstanceUID: this.seriesInstanceUID,
          flag: 'is_selected',
          value: newValue,
        };
        if (this.serie.flag.is_selected !== newValue) {
          this.$store.dispatch('setFlagByStudyUIDSerieUID', params).then(() => {
            this.setCheckBoxStudy();
          });
        }
      },
    },
    loadingImage() {
      return (this.serie.imgSrc === '');
    },
  },
  watch: {
  },
  created() {
    // this.setImageSerie()
  },
  methods: {
    /*
    setImageSerie () {
      let params = {
        StudyInstanceUID: this.studyInstanceUID,
        SeriesInstanceUID: this.seriesInstanceUID
      }
      this.loading = true
      this.$store.dispatch('setSerieImage', params).then(res => {
        this.loading = false
      })
    },
    */
    setCheckBoxStudy() {
      if (this.checkAllSerieSelected(this.study, true)) {
        this.$store.dispatch('setFlagByStudyUID', {
          StudyInstanceUID: this.studyInstanceUID,
          flag: 'is_indeterminate',
          value: false,
        });
        this.$store.dispatch('setFlagByStudyUID', {
          StudyInstanceUID: this.studyInstanceUID,
          flag: 'is_selected',
          value: true,
        });
      } else if (this.checkAllSerieSelected(this.study, false)) {
        this.$store.dispatch('setFlagByStudyUID', {
          StudyInstanceUID: this.studyInstanceUID,
          flag: 'is_indeterminate',
          value: false,
        });
        this.$store.dispatch('setFlagByStudyUID', {
          StudyInstanceUID: this.studyInstanceUID,
          flag: 'is_selected',
          value: false,
        });
      } else {
        this.$store.dispatch('setFlagByStudyUID', {
          StudyInstanceUID: this.studyInstanceUID,
          flag: 'is_indeterminate',
          value: true,
        });
        this.$store.dispatch('setFlagByStudyUID', {
          StudyInstanceUID: this.studyInstanceUID,
          flag: 'is_selected',
          value: false,
        });
      }
    },
    checkAllSerieSelected(study, value) {
      let allSelected = true;
      Object.keys(this.series[this.studyInstanceUID]).forEach((serieUID) => {
        allSelected = allSelected && (this.series[this.studyInstanceUID][serieUID].flag.is_selected === value);
      });
      return allSelected;
    },
    getSourceQueries() {
      if (Object.keys(this.source).length > 0) {
        return `${encodeURIComponent(this.source.key)}=${encodeURIComponent(this.source.value)}`;
      }
      return '';
    },
    openTab() {
      const windowProps = this.setWindowsProps();
      const albumId = this.albumId ? this.albumId : 'inbox';
      const openWindow = window.open('', windowProps.name);
      const queryparams = {
        studyInstanceUids: this.studyInstanceUID,
        seriesInstanceUids: this.seriesInstanceUID,
        albumId,
      };

      openWindow.location.href = this.openWebViewer(queryparams);
    },
    setWindowsProps() {
      const windowProps = {};

      windowProps.name = `VIEWER-${this.studyInstanceUID}`;
      windowProps.id = 'Viewer';

      return windowProps;
    },
  },
};

</script>
