export default {
  name2dicom: {
    PatientName: '00100010',
    FileMetaInfoGroupLength: '00020000',
    FileMetaInfoVersion: '00020001',
    MediaStorageSOPClassUID: '00020002',
    MediaStorageSOPInstanceUID: '00020003',
    TransferSyntaxUID: '00020010',
    ImplementationClassUID: '00020012',
    ImplementationVersionName: '00020013',
    SourceApplicationEntityTitle: '00020016',
    PrivateInformationCreatorUID: '00020100',
    PrivateInformation: '00020102',
    FileSetID: '00041130',
    FileSetDescriptorFileID: '00041141',
    SpecificCharacterSetOfFile: '00041142',
    FirstDirectoryRecordOffset: '00041200',
    LastDirectoryRecordOffset: '00041202',
    FileSetConsistencyFlag: '00041212',
    DirectoryRecordSequence: '00041220',
    OffsetOfNextDirectoryRecord: '00041400',
    RecordInUseFlag: '00041410',
    LowerLevelDirectoryEntityOffset: '00041420',
    DirectoryRecordType: '00041430',
    PrivateRecordUID: '00041432',
    ReferencedFileID: '00041500',
    MRDRDirectoryRecordOffset: '00041504',
    ReferencedSOPClassUIDInFile: '00041510',
    ReferencedSOPInstanceUIDInFile: '00041511',
    ReferencedTransferSyntaxUIDInFile: '00041512',
    ReferencedRelatedSOPClassUIDInFile: '0004151A',
    NumberOfReferences: '00041600',
    IdentifyingGroupLength: '00080000',
    LengthToEnd: '00080001',
    SpecificCharacterSet: '00080005',
    LanguageCodeSequence: '00080006',
    ImageType: '00080008',
    RecognitionCode: '00080010',
    InstanceCreationDate: '00080012',
    InstanceCreationTime: '00080013',
    InstanceCreatorUID: '00080014',
    SOPClassUID: '00080016',
    SOPInstanceUID: '00080018',
    RelatedGeneralSOPClassUID: '0008001A',
    OriginalSpecializedSOPClassUID: '0008001B',
    StudyDate: '00080020',
    SeriesDate: '00080021',
    AcquisitionDate: '00080022',
    ContentDate: '00080023',
    OverlayDate: '00080024',
    CurveDate: '00080025',
    AcquisitionDateTime: '0008002A',
    StudyTime: '00080030',
    SeriesTime: '00080031',
    AcquisitionTime: '00080032',
    ContentTime: '00080033',
    OverlayTime: '00080034',
    CurveTime: '00080035',
    DataSetType: '00080040',
    DataSetSubtype: '00080041',
    NuclearMedicineSeriesType: '00080042',
    AccessionNumber: '00080050',
    QueryRetrieveLevel: '00080052',
    RetrieveAETitle: '00080054',
    InstanceAvailability: '00080056',
    FailedSOPInstanceUIDList: '00080058',
    Modality: '00080060',
    ModalitiesInStudy: '00080061',
    SOPClassesInStudy: '00080062',
    ConversionType: '00080064',
    PresentationIntentType: '00080068',
    Manufacturer: '00080070',
    InstitutionName: '00080080',
    InstitutionAddress: '00080081',
    InstitutionCodeSequence: '00080082',
    ReferringPhysicianName: '00080090',
    ReferringPhysicianAddress: '00080092',
    ReferringPhysicianTelephoneNumber: '00080094',
    ReferringPhysicianIDSequence: '00080096',
    CodeValue: '00080100',
    CodingSchemeDesignator: '00080102',
    CodingSchemeVersion: '00080103',
    CodeMeaning: '00080104',
    MappingResource: '00080105',
    ContextGroupVersion: '00080106',
    ContextGroupLocalVersion: '00080107',
    ContextGroupExtensionFlag: '0008010B',
    CodingSchemeUID: '0008010C',
    ContextGroupExtensionCreatorUID: '0008010D',
    ContextIdentifier: '0008010F',
    CodingSchemeIDSequence: '00080110',
    CodingSchemeRegistry: '00080112',
    CodingSchemeExternalID: '00080114',
    CodingSchemeName: '00080115',
    ResponsibleOrganization: '00102299',
    ContextUID: '00080117',
    TimezoneOffsetFromUTC: '00080201',
    NetworkID: '00081000',
    StationName: '00081010',
    StudyDescription: '00081030',
    ProcedureCodeSequence: '00081032',
    SeriesDescription: '0008103E',
    InstitutionalDepartmentName: '00081040',
    PhysiciansOfRecord: '00081048',
    PhysiciansOfRecordIDSequence: '00081049',
    PerformingPhysicianName: '00081050',
    PerformingPhysicianIDSequence: '00081052',
    NameOfPhysicianReadingStudy: '00081060',
    PhysicianReadingStudyIDSequence: '00081062',
    OperatorsName: '00081070',
    OperatorIDSequence: '00081072',
    AdmittingDiagnosesDescription: '00081080',
    AdmittingDiagnosesCodeSequence: '00081084',
    ManufacturersModelName: '00081090',
    ReferencedResultsSequence: '00081100',
    ReferencedStudySequence: '00081110',
    ReferencedProcedureStepSequence: '00400330',
    ReferencedSeriesSequence: '00081115',
    ReferencedPatientSequence: '00081120',
    ReferencedVisitSequence: '00081125',
    ReferencedOverlaySequence: '50..2600',
    ReferencedWaveformSequence: '0008113A',
    ReferencedImageSequence: '00081140',
    ReferencedCurveSequence: '00081145',
    ReferencedInstanceSequence: '0008114A',
    ReferencedSOPClassUID: '00081150',
    ReferencedSOPInstanceUID: '00081155',
    SOPClassesSupported: '0008115A',
    ReferencedFrameNumber: '00081160',
    SimpleFrameList: '00081161',
    CalculatedFrameList: '00081162',
    TimeRange: '00081163',
    FrameExtractionSequence: '00081164',
    TransactionUID: '00081195',
    FailureReason: '00081197',
    FailedSOPSequence: '00081198',
    ReferencedSOPSequence: '00081199',
    OtherReferencedStudiesSequence: '00081200',
    RelatedSeriesSequence: '00081250',
    LossyImageCompression: '00282110',
    DerivationDescription: '00082111',
    SourceImageSequence: '00082112',
    StageName: '00082120',
    StageNumber: '00082122',
    NumberOfStages: '00082124',
    ViewName: '00082127',
    ViewNumber: '00082128',
    NumberOfEventTimers: '00082129',
    NumberOfViewsInStage: '0008212A',
    EventElapsedTimes: '00082130',
    EventTimerNames: '00082132',
    EventTimerSequence: '00082133',
    EventTimeOffset: '00082134',
    EventCodeSequence: '00082135',
    StartTrim: '00082142',
    StopTrim: '00082143',
    RecommendedDisplayFrameRate: '00082144',
    TransducerPosition: '00082200',
    TransducerOrientation: '00082204',
    AnatomicStructure: '00082208',
    AnatomicRegionSequence: '00082218',
    AnatomicRegionModifierSequence: '00082220',
    PrimaryAnatomicStructureSequence: '00082228',
    AnatomicStructureOrRegionSequence: '00082229',
    AnatomicStructureModifierSequence: '00082230',
    TransducerPositionSequence: '00082240',
    TransducerPositionModifierSequence: '00082242',
    TransducerOrientationSequence: '00082244',
    TransducerOrientationModifierSeq: '00082246',
    AnatomicEntrancePortalCodeSeqTrial: '00082253',
    AnatomicApproachDirCodeSeqTrial: '00082255',
    AnatomicPerspectiveDescrTrial: '00082256',
    AnatomicPerspectiveCodeSeqTrial: '00082257',
    AlternateRepresentationSequence: '00083001',
    IrradiationEventUID: '00083010',
    IdentifyingComments: '00084000',
    FrameType: '00089007',
    ReferencedImageEvidenceSequence: '00089092',
    ReferencedRawDataSequence: '00089121',
    CreatorVersionUID: '00089123',
    DerivationImageSequence: '00089124',
    SourceImageEvidenceSequence: '00089154',
    PixelPresentation: '00089205',
    VolumetricProperties: '00089206',
    VolumeBasedCalculationTechnique: '00089207',
    ComplexImageComponent: '00089208',
    AcquisitionContrast: '00089209',
    DerivationCodeSequence: '00089215',
    GrayscalePresentationStateSequence: '00089237',
    ReferencedOtherPlaneSequence: '00089410',
    FrameDisplaySequence: '00089458',
    RecommendedDisplayFrameRateInFloat: '00089459',
    SkipFrameRangeFlag: '00089460',
    FullFidelity: '00091001',
    SuiteID: '00091002',
    ProductID: '00091004',
    ImageActualDate: '00091027',
    ServiceID: '00091030',
    MobileLocationNumber: '00091031',
    EquipmentUID: '000910E3',
    GenesisVersionNow: '00211018',
    ExamRecordChecksum: '000910E7',
    ActualSeriesDataTimeStamp: '000910E9',
    PatientGroupLength: '00100000',
    PatientID: '00100020',
    IssuerOfPatientID: '00100021',
    TypeOfPatientID: '00100022',
    PatientBirthDate: '00100030',
    PatientBirthTime: '00100032',
    PatientSex: '00100040',
    PatientInsurancePlanCodeSequence: '00100050',
    PatientPrimaryLanguageCodeSeq: '00100101',
    PatientPrimaryLanguageCodeModSeq: '00100102',
    OtherPatientIDs: '00101000',
    OtherPatientNames: '00101001',
    OtherPatientIDsSequence: '00101002',
    PatientBirthName: '00101005',
    PatientAge: '00101010',
    PatientSize: '00101020',
    PatientWeight: '00101030',
    PatientAddress: '00101040',
    InsurancePlanIdentification: '00101050',
    PatientMotherBirthName: '00101060',
    MilitaryRank: '00101080',
    BranchOfService: '00101081',
    MedicalRecordLocator: '00101090',
    MedicalAlerts: '00102000',
    Allergies: '00102110',
    CountryOfResidence: '00102150',
    RegionOfResidence: '00102152',
    PatientTelephoneNumbers: '00102154',
    EthnicGroup: '00102160',
    Occupation: '00102180',
    SmokingStatus: '001021A0',
    AdditionalPatientHistory: '001021B0',
    PregnancyStatus: '001021C0',
    LastMenstrualDate: '001021D0',
    PatientReligiousPreference: '001021F0',
    PatientSpeciesDescription: '00102201',
    PatientSpeciesCodeSequence: '00102202',
    PatientSexNeutered: '00102203',
    AnatomicalOrientationType: '00102210',
    PatientBreedDescription: '00102292',
    PatientBreedCodeSequence: '00102293',
    BreedRegistrationSequence: '00102294',
    BreedRegistrationNumber: '00102295',
    BreedRegistryCodeSequence: '00102296',
    ResponsiblePerson: '00102297',
    ResponsiblePersonRole: '00102298',
    PatientComments: '00104000',
    ExaminedBodyThickness: '00109431',
    PatientStatus: '00111010',
    ClinicalTrialSponsorName: '00120010',
    ClinicalTrialProtocolID: '00120020',
    ClinicalTrialProtocolName: '00120021',
    ClinicalTrialSiteID: '00120030',
    ClinicalTrialSiteName: '00120031',
    ClinicalTrialSubjectID: '00120040',
    ClinicalTrialSubjectReadingID: '00120042',
    ClinicalTrialTimePointID: '00120050',
    ClinicalTrialTimePointDescription: '00120051',
    ClinicalTrialCoordinatingCenter: '00120060',
    PatientIdentityRemoved: '00120062',
    DeidentificationMethod: '00120063',
    DeidentificationMethodCodeSequence: '00120064',
    ClinicalTrialSeriesID: '00120071',
    ClinicalTrialSeriesDescription: '00120072',
    DistributionType: '00120084',
    ConsentForDistributionFlag: '00120085',
    AcquisitionGroupLength: '00180000',
    ContrastBolusAgent: '00180010',
    ContrastBolusAgentSequence: '00180012',
    ContrastBolusAdministrationRoute: '00180014',
    BodyPartExamined: '00180015',
    ScanningSequence: '00180020',
    SequenceVariant: '00180021',
    ScanOptions: '00180022',
    MRAcquisitionType: '00180023',
    SequenceName: '00180024',
    AngioFlag: '00180025',
    InterventionDrugInformationSeq: '00180026',
    InterventionDrugStopTime: '00180027',
    InterventionDrugDose: '00180028',
    InterventionDrugSequence: '00180029',
    AdditionalDrugSequence: '0018002A',
    Radionuclide: '00180030',
    Radiopharmaceutical: '00180031',
    EnergyWindowCenterline: '00180032',
    EnergyWindowTotalWidth: '00180033',
    InterventionDrugName: '00180034',
    InterventionDrugStartTime: '00180035',
    InterventionSequence: '00180036',
    TherapyType: '00180037',
    InterventionStatus: '00180038',
    TherapyDescription: '00180039',
    InterventionDescription: '0018003A',
    CineRate: '00180040',
    InitialCineRunState: '00180042',
    SliceThickness: '00180050',
    KVP: '00180060',
    CountsAccumulated: '00180070',
    AcquisitionTerminationCondition: '00180071',
    EffectiveDuration: '00180072',
    AcquisitionStartCondition: '00180073',
    AcquisitionStartConditionData: '00180074',
    AcquisitionEndConditionData: '00180075',
    RepetitionTime: '00180080',
    EchoTime: '00180081',
    InversionTime: '00180082',
    NumberOfAverages: '00180083',
    ImagingFrequency: '00180084',
    ImagedNucleus: '00180085',
    EchoNumber: '00180086',
    MagneticFieldStrength: '00180087',
    SpacingBetweenSlices: '00180088',
    NumberOfPhaseEncodingSteps: '00180089',
    DataCollectionDiameter: '00180090',
    EchoTrainLength: '00180091',
    PercentSampling: '00180093',
    PercentPhaseFieldOfView: '00180094',
    PixelBandwidth: '00180095',
    DeviceSerialNumber: '00181000',
    DeviceUID: '00181002',
    DeviceID: '00181003',
    PlateID: '00181004',
    GeneratorID: '00181005',
    GridID: '00181006',
    CassetteID: '00181007',
    GantryID: '00181008',
    SecondaryCaptureDeviceID: '00181010',
    HardcopyCreationDeviceID: '00181011',
    DateOfSecondaryCapture: '00181012',
    TimeOfSecondaryCapture: '00181014',
    SecondaryCaptureDeviceManufacturer: '00181016',
    HardcopyDeviceManufacturer: '00181017',
    SecondaryCaptureDeviceModelName: '00181018',
    SecondaryCaptureDeviceSoftwareVers: '00181019',
    HardcopyDeviceSoftwareVersion: '0018101A',
    HardcopyDeviceModelName: '0018101B',
    SoftwareVersion: '00181020',
    VideoImageFormatAcquired: '00181022',
    DigitalImageFormatAcquired: '00181023',
    ProtocolName: '00181030',
    ContrastBolusRoute: '00181040',
    ContrastBolusVolume: '00181041',
    ContrastBolusStartTime: '00181042',
    ContrastBolusStopTime: '00181043',
    ContrastBolusTotalDose: '00181044',
    SyringeCounts: '00181045',
    ContrastFlowRate: '00181046',
    ContrastFlowDuration: '00181047',
    ContrastBolusIngredient: '00181048',
    ContrastBolusConcentration: '00181049',
    SpatialResolution: '00181050',
    TriggerTime: '00181060',
    TriggerSourceOrType: '00181061',
    NominalInterval: '00181062',
    FrameTime: '00181063',
    CardiacFramingType: '00181064',
    FrameTimeVector: '00181065',
    FrameDelay: '00181066',
    ImageTriggerDelay: '00181067',
    MultiplexGroupTimeOffset: '00181068',
    TriggerTimeOffset: '00181069',
    SynchronizationTrigger: '0018106A',
    SynchronizationChannel: '0018106C',
    TriggerSamplePosition: '0018106E',
    RadiopharmaceuticalRoute: '00181070',
    RadiopharmaceuticalVolume: '00181071',
    RadiopharmaceuticalStartTime: '00181072',
    RadiopharmaceuticalStopTime: '00181073',
    RadionuclideTotalDose: '00181074',
    RadionuclideHalfLife: '00181075',
    RadionuclidePositronFraction: '00181076',
    RadiopharmaceuticalSpecActivity: '00181077',
    RadiopharmaceuticalStartDateTime: '00181078',
    RadiopharmaceuticalStopDateTime: '00181079',
    BeatRejectionFlag: '00181080',
    LowRRValue: '00181081',
    HighRRValue: '00181082',
    IntervalsAcquired: '00181083',
    IntervalsRejected: '00181084',
    PVCRejection: '00181085',
    SkipBeats: '00181086',
    HeartRate: '00181088',
    CardiacNumberOfImages: '00181090',
    TriggerWindow: '00181094',
    ReconstructionDiameter: '00181100',
    DistanceSourceToDetector: '00181110',
    DistanceSourceToPatient: '00181111',
    EstimatedRadiographicMagnification: '00181114',
    GantryDetectorTilt: '00181120',
    GantryDetectorSlew: '00181121',
    TableHeight: '00181130',
    TableTraverse: '00181131',
    TableMotion: '00181134',
    TableVerticalIncrement: '00181135',
    TableLateralIncrement: '00181136',
    TableLongitudinalIncrement: '00181137',
    TableAngle: '00181138',
    TableType: '0018113A',
    RotationDirection: '00181140',
    AngularPosition: '00181141',
    RadialPosition: '00181142',
    ScanArc: '00181143',
    AngularStep: '00181144',
    CenterOfRotationOffset: '00181145',
    RotationOffset: '00181146',
    FieldOfViewShape: '00181147',
    FieldOfViewDimensions: '00181149',
    ExposureTime: '00181150',
    XRayTubeCurrent: '00181151',
    Exposure: '00181152',
    ExposureInMicroAmpSec: '00181153',
    AveragePulseWidth: '00181154',
    RadiationSetting: '00181155',
    RectificationType: '00181156',
    RadiationMode: '0018115A',
    ImageAreaDoseProduct: '0018115E',
    FilterType: '00181160',
    TypeOfFilters: '00181161',
    IntensifierSize: '00181162',
    ImagerPixelSpacing: '00181164',
    Grid: '00181166',
    GeneratorPower: '00181170',
    CollimatorGridName: '00181180',
    CollimatorType: '00181181',
    FocalDistance: '00181182',
    XFocusCenter: '00181183',
    YFocusCenter: '00181184',
    FocalSpots: '00181190',
    AnodeTargetMaterial: '00181191',
    BodyPartThickness: '001811A0',
    CompressionForce: '001811A2',
    DateOfLastCalibration: '00181200',
    TimeOfLastCalibration: '00181201',
    ConvolutionKernel: '00181210',
    UpperLowerPixelValues: '00181240',
    ActualFrameDuration: '00181242',
    CountRate: '00181243',
    PreferredPlaybackSequencing: '00181244',
    ReceiveCoilName: '00181250',
    TransmitCoilName: '00181251',
    PlateType: '00181260',
    PhosphorType: '00181261',
    ScanVelocity: '00181300',
    WholeBodyTechnique: '00181301',
    ScanLength: '00181302',
    AcquisitionMatrix: '00181310',
    InPlanePhaseEncodingDirection: '00181312',
    FlipAngle: '00181314',
    VariableFlipAngleFlag: '00181315',
    SAR: '00181316',
    'DB-Dt': '00181318',
    AcquisitionDeviceProcessingDescr: '00181400',
    AcquisitionDeviceProcessingCode: '00181401',
    CassetteOrientation: '00181402',
    CassetteSize: '00181403',
    ExposuresOnPlate: '00181404',
    RelativeXRayExposure: '00181405',
    ColumnAngulation: '00181450',
    TomoLayerHeight: '00181460',
    TomoAngle: '00181470',
    TomoTime: '00181480',
    TomoType: '00181490',
    TomoClass: '00181491',
    NumberOfTomosynthesisSourceImages: '00181495',
    PositionerMotion: '00181500',
    PositionerType: '00181508',
    PositionerPrimaryAngle: '00181510',
    PositionerSecondaryAngle: '00181511',
    PositionerPrimaryAngleIncrement: '00181520',
    PositionerSecondaryAngleIncrement: '00181521',
    DetectorPrimaryAngle: '00181530',
    DetectorSecondaryAngle: '00181531',
    ShutterShape: '00181600',
    ShutterLeftVerticalEdge: '00181602',
    ShutterRightVerticalEdge: '00181604',
    ShutterUpperHorizontalEdge: '00181606',
    ShutterLowerHorizontalEdge: '00181608',
    CenterOfCircularShutter: '00181610',
    RadiusOfCircularShutter: '00181612',
    VerticesOfPolygonalShutter: '00181620',
    ShutterPresentationValue: '00181622',
    ShutterOverlayGroup: '00181623',
    ShutterPresentationColorCIELabVal: '00181624',
    CollimatorShape: '00181700',
    CollimatorLeftVerticalEdge: '00181702',
    CollimatorRightVerticalEdge: '00181704',
    CollimatorUpperHorizontalEdge: '00181706',
    CollimatorLowerHorizontalEdge: '00181708',
    CenterOfCircularCollimator: '00181710',
    RadiusOfCircularCollimator: '00181712',
    VerticesOfPolygonalCollimator: '00181720',
    AcquisitionTimeSynchronized: '00181800',
    TimeSource: '00181801',
    TimeDistributionProtocol: '00181802',
    NTPSourceAddress: '00181803',
    PageNumberVector: '00182001',
    FrameLabelVector: '00182002',
    FramePrimaryAngleVector: '00182003',
    FrameSecondaryAngleVector: '00182004',
    SliceLocationVector: '00182005',
    DisplayWindowLabelVector: '00182006',
    NominalScannedPixelSpacing: '00182010',
    DigitizingDeviceTransportDirection: '00182020',
    RotationOfScannedFilm: '00182030',
    IVUSAcquisition: '00183100',
    IVUSPullbackRate: '00183101',
    IVUSGatedRate: '00183102',
    IVUSPullbackStartFrameNumber: '00183103',
    IVUSPullbackStopFrameNumber: '00183104',
    LesionNumber: '00183105',
    AcquisitionComments: '00184000',
    OutputPower: '00185000',
    TransducerData: '00185010',
    FocusDepth: '00185012',
    ProcessingFunction: '00185020',
    PostprocessingFunction: '00185021',
    MechanicalIndex: '00185022',
    BoneThermalIndex: '00185024',
    CranialThermalIndex: '00185026',
    SoftTissueThermalIndex: '00185027',
    SoftTissueFocusThermalIndex: '00185028',
    SoftTissueSurfaceThermalIndex: '00185029',
    DynamicRange: '00185030',
    TotalGain: '00185040',
    DepthOfScanField: '00185050',
    PatientPosition: '00185100',
    ViewPosition: '00185101',
    ProjectionEponymousNameCodeSeq: '00185104',
    ImageTransformationMatrix: '00185210',
    ImageTranslationVector: '00185212',
    Sensitivity: '00186000',
    SequenceOfUltrasoundRegions: '00186011',
    RegionSpatialFormat: '00186012',
    RegionDataType: '00186014',
    RegionFlags: '00186016',
    RegionLocationMinX0: '00186018',
    RegionLocationMinY0: '0018601A',
    RegionLocationMaxX1: '0018601C',
    RegionLocationMaxY1: '0018601E',
    ReferencePixelX0: '00186020',
    ReferencePixelY0: '00186022',
    PhysicalUnitsXDirection: '00186024',
    PhysicalUnitsYDirection: '00186026',
    ReferencePixelPhysicalValueX: '00186028',
    ReferencePixelPhysicalValueY: '0018602A',
    PhysicalDeltaX: '0018602C',
    PhysicalDeltaY: '0018602E',
    TransducerFrequency: '00186030',
    TransducerType: '00186031',
    PulseRepetitionFrequency: '00186032',
    DopplerCorrectionAngle: '00186034',
    SteeringAngle: '00186036',
    DopplerSampleVolumeXPosRetired: '00186038',
    DopplerSampleVolumeXPosition: '00186039',
    DopplerSampleVolumeYPosRetired: '0018603A',
    DopplerSampleVolumeYPosition: '0018603B',
    TMLinePositionX0Retired: '0018603C',
    TMLinePositionX0: '0018603D',
    TMLinePositionY0Retired: '0018603E',
    TMLinePositionY0: '0018603F',
    TMLinePositionX1Retired: '00186040',
    TMLinePositionX1: '00186041',
    TMLinePositionY1Retired: '00186042',
    TMLinePositionY1: '00186043',
    PixelComponentOrganization: '00186044',
    PixelComponentMask: '00186046',
    PixelComponentRangeStart: '00186048',
    PixelComponentRangeStop: '0018604A',
    PixelComponentPhysicalUnits: '0018604C',
    PixelComponentDataType: '0018604E',
    NumberOfTableBreakPoints: '00186050',
    TableOfXBreakPoints: '00186052',
    TableOfYBreakPoints: '00186054',
    NumberOfTableEntries: '00186056',
    TableOfPixelValues: '00186058',
    TableOfParameterValues: '0018605A',
    RWaveTimeVector: '00186060',
    DetectorConditionsNominalFlag: '00187000',
    DetectorTemperature: '00187001',
    DetectorType: '00187004',
    DetectorConfiguration: '00187005',
    DetectorDescription: '00187006',
    DetectorMode: '00187008',
    DetectorID: '0018700A',
    DateOfLastDetectorCalibration: '0018700C',
    TimeOfLastDetectorCalibration: '0018700E',
    DetectorExposuresSinceCalibration: '00187010',
    DetectorExposuresSinceManufactured: '00187011',
    DetectorTimeSinceLastExposure: '00187012',
    DetectorActiveTime: '00187014',
    DetectorActiveOffsetFromExposure: '00187016',
    DetectorBinning: '0018701A',
    DetectorElementPhysicalSize: '00187020',
    DetectorElementSpacing: '00187022',
    DetectorActiveShape: '00187024',
    DetectorActiveDimensions: '00187026',
    DetectorActiveOrigin: '00187028',
    DetectorManufacturerName: '0018702A',
    DetectorManufacturersModelName: '0018702B',
    FieldOfViewOrigin: '00187030',
    FieldOfViewRotation: '00187032',
    FieldOfViewHorizontalFlip: '00187034',
    GridAbsorbingMaterial: '00187040',
    GridSpacingMaterial: '00187041',
    GridThickness: '00187042',
    GridPitch: '00187044',
    GridAspectRatio: '00187046',
    GridPeriod: '00187048',
    GridFocalDistance: '0018704C',
    FilterMaterial: '00187050',
    FilterThicknessMinimum: '00187052',
    FilterThicknessMaximum: '00187054',
    ExposureControlMode: '00187060',
    ExposureControlModeDescription: '00187062',
    ExposureStatus: '00187064',
    PhototimerSetting: '00187065',
    ExposureTimeInMicroSec: '00188150',
    XRayTubeCurrentInMicroAmps: '00188151',
    ContentQualification: '00189004',
    PulseSequenceName: '00189005',
    MRImagingModifierSequence: '00189006',
    EchoPulseSequence: '00189008',
    InversionRecovery: '00189009',
    FlowCompensation: '00189010',
    MultipleSpinEcho: '00189011',
    MultiPlanarExcitation: '00189012',
    PhaseContrast: '00189014',
    TimeOfFlightContrast: '00189015',
    Spoiling: '00189016',
    SteadyStatePulseSequence: '00189017',
    EchoPlanarPulseSequence: '00189018',
    TagAngleFirstAxis: '00189019',
    MagnetizationTransfer: '00189020',
    T2Preparation: '00189021',
    BloodSignalNulling: '00189022',
    SaturationRecovery: '00189024',
    SpectrallySelectedSuppression: '00189025',
    SpectrallySelectedExcitation: '00189026',
    SpatialPresaturation: '00189027',
    Tagging: '00189028',
    OversamplingPhase: '00189029',
    TagSpacingFirstDimension: '00189030',
    GeometryOfKSpaceTraversal: '00189032',
    SegmentedKSpaceTraversal: '00189033',
    RectilinearPhaseEncodeReordering: '00189034',
    TagThickness: '00189035',
    PartialFourierDirection: '00189036',
    CardiacSynchronizationTechnique: '00189037',
    ReceiveCoilManufacturerName: '00189041',
    MRReceiveCoilSequence: '00189042',
    ReceiveCoilType: '00189043',
    QuadratureReceiveCoil: '00189044',
    MultiCoilDefinitionSequence: '00189045',
    MultiCoilConfiguration: '00189046',
    MultiCoilElementName: '00189047',
    MultiCoilElementUsed: '00189048',
    MRTransmitCoilSequence: '00189049',
    TransmitCoilManufacturerName: '00189050',
    TransmitCoilType: '00189051',
    SpectralWidth: '00189052',
    ChemicalShiftReference: '00189053',
    VolumeLocalizationTechnique: '00189054',
    MRAcquisitionFrequencyEncodeSteps: '00189058',
    Decoupling: '00189059',
    DecoupledNucleus: '00189060',
    DecouplingFrequency: '00189061',
    DecouplingMethod: '00189062',
    DecouplingChemicalShiftReference: '00189063',
    KSpaceFiltering: '00189064',
    TimeDomainFiltering: '00189065',
    NumberOfZeroFills: '00189066',
    BaselineCorrection: '00189067',
    ParallelReductionFactorInPlane: '00189069',
    CardiacRRIntervalSpecified: '00189070',
    AcquisitionDuration: '0019105A',
    FrameAcquisitionDateTime: '00189074',
    DiffusionDirectionality: '00189075',
    DiffusionGradientDirectionSequence: '00189076',
    ParallelAcquisition: '00189077',
    ParallelAcquisitionTechnique: '00189078',
    InversionTimes: '00189079',
    MetaboliteMapDescription: '00189080',
    PartialFourier: '00189081',
    EffectiveEchoTime: '00189082',
    MetaboliteMapCodeSequence: '00189083',
    ChemicalShiftSequence: '00189084',
    CardiacSignalSource: '00189085',
    DiffusionBValue: '00189087',
    DiffusionGradientOrientation: '00189089',
    VelocityEncodingDirection: '00189090',
    VelocityEncodingMinimumValue: '00189091',
    NumberOfKSpaceTrajectories: '00189093',
    CoverageOfKSpace: '00189094',
    SpectroscopyAcquisitionPhaseRows: '00189095',
    ParallelReductFactorInPlaneRetired: '00189096',
    TransmitterFrequency: '00189098',
    ResonantNucleus: '00189100',
    FrequencyCorrection: '00189101',
    'MRSpectroscopyFOV-GeometrySequence': '00189103',
    SlabThickness: '00189104',
    SlabOrientation: '00189105',
    MidSlabPosition: '00189106',
    MRSpatialSaturationSequence: '00189107',
    MRTimingAndRelatedParametersSeq: '00189112',
    MREchoSequence: '00189114',
    MRModifierSequence: '00189115',
    MRDiffusionSequence: '00189117',
    CardiacTriggerSequence: '00189118',
    MRAveragesSequence: '00189119',
    'MRFOV-GeometrySequence': '00189125',
    VolumeLocalizationSequence: '00189126',
    SpectroscopyAcquisitionDataColumns: '00189127',
    DiffusionAnisotropyType: '00189147',
    FrameReferenceDateTime: '00189151',
    MRMetaboliteMapSequence: '00189152',
    ParallelReductionFactorOutOfPlane: '00189155',
    SpectroscopyOutOfPlanePhaseSteps: '00189159',
    BulkMotionStatus: '00189166',
    ParallelReductionFactSecondInPlane: '00189168',
    CardiacBeatRejectionTechnique: '00189169',
    RespiratoryMotionCompTechnique: '00189170',
    RespiratorySignalSource: '00189171',
    BulkMotionCompensationTechnique: '00189172',
    BulkMotionSignalSource: '00189173',
    ApplicableSafetyStandardAgency: '00189174',
    ApplicableSafetyStandardDescr: '00189175',
    OperatingModeSequence: '00189176',
    OperatingModeType: '00189177',
    OperatingMode: '00189178',
    SpecificAbsorptionRateDefinition: '00189179',
    GradientOutputType: '00189180',
    SpecificAbsorptionRateValue: '00189181',
    GradientOutput: '00189182',
    FlowCompensationDirection: '00189183',
    TaggingDelay: '00189184',
    RespiratoryMotionCompTechDescr: '00189185',
    RespiratorySignalSourceID: '00189186',
    ChemicalShiftsMinIntegrateLimitHz: '00189195',
    ChemicalShiftsMaxIntegrateLimitHz: '00189196',
    MRVelocityEncodingSequence: '00189197',
    FirstOrderPhaseCorrection: '00189198',
    WaterReferencedPhaseCorrection: '00189199',
    MRSpectroscopyAcquisitionType: '00189200',
    RespiratoryCyclePosition: '00189214',
    VelocityEncodingMaximumValue: '00189217',
    TagSpacingSecondDimension: '00189218',
    TagAngleSecondAxis: '00189219',
    FrameAcquisitionDuration: '00189220',
    MRImageFrameTypeSequence: '00189226',
    MRSpectroscopyFrameTypeSequence: '00189227',
    MRAcqPhaseEncodingStepsInPlane: '00189231',
    MRAcqPhaseEncodingStepsOutOfPlane: '00189232',
    SpectroscopyAcqPhaseColumns: '00189234',
    CardiacCyclePosition: '00189236',
    SpecificAbsorptionRateSequence: '00189239',
    RFEchoTrainLength: '00189240',
    GradientEchoTrainLength: '00189241',
    ChemicalShiftsMinIntegrateLimitPPM: '00189295',
    ChemicalShiftsMaxIntegrateLimitPPM: '00189296',
    CTAcquisitionTypeSequence: '00189301',
    AcquisitionType: '00189302',
    TubeAngle: '00189303',
    CTAcquisitionDetailsSequence: '00189304',
    RevolutionTime: '00189305',
    SingleCollimationWidth: '00189306',
    TotalCollimationWidth: '00189307',
    CTTableDynamicsSequence: '00189308',
    TableSpeed: '00191023',
    TableFeedPerRotation: '00189310',
    SpiralPitchFactor: '00189311',
    CTGeometrySequence: '00189312',
    DataCollectionCenterPatient: '00189313',
    CTReconstructionSequence: '00189314',
    ReconstructionAlgorithm: '00189315',
    ConvolutionKernelGroup: '00189316',
    ReconstructionFieldOfView: '00189317',
    ReconstructionTargetCenterPatient: '00189318',
    ReconstructionAngle: '00189319',
    ImageFilter: '00189320',
    CTExposureSequence: '00189321',
    ReconstructionPixelSpacing: '00189322',
    ExposureModulationType: '00189323',
    EstimatedDoseSaving: '00189324',
    CTXRayDetailsSequence: '00189325',
    CTPositionSequence: '00189326',
    TablePosition: '00189327',
    ExposureTimeInMilliSec: '00189328',
    CTImageFrameTypeSequence: '00189329',
    XRayTubeCurrentInMilliAmps: '00189330',
    ExposureInMilliAmpSec: '00189332',
    ConstantVolumeFlag: '00189333',
    FluoroscopyFlag: '00189334',
    SourceToDataCollectionCenterDist: '00189335',
    ContrastBolusAgentNumber: '00189337',
    ContrastBolusIngredientCodeSeq: '00189338',
    ContrastAdministrationProfileSeq: '00189340',
    ContrastBolusUsageSequence: '00189341',
    ContrastBolusAgentAdministered: '00189342',
    ContrastBolusAgentDetected: '00189343',
    ContrastBolusAgentPhase: '00189344',
    CTDIvol: '00189345',
    CTDIPhantomTypeCodeSequence: '00189346',
    CalciumScoringMassFactorPatient: '00189351',
    CalciumScoringMassFactorDevice: '00189352',
    EnergyWeightingFactor: '00189353',
    CTAdditionalXRaySourceSequence: '00189360',
    ProjectionPixelCalibrationSequence: '00189401',
    DistanceSourceToIsocenter: '00189402',
    DistanceObjectToTableTop: '00189403',
    ObjectPixelSpacingInCenterOfBeam: '00189404',
    PositionerPositionSequence: '00189405',
    TablePositionSequence: '00189406',
    CollimatorShapeSequence: '00189407',
    'XA-XRFFrameCharacteristicsSequence': '00189412',
    FrameAcquisitionSequence: '00189417',
    XRayReceptorType: '00189420',
    AcquisitionProtocolName: '00189423',
    AcquisitionProtocolDescription: '00189424',
    ContrastBolusIngredientOpaque: '00189425',
    DistanceReceptorPlaneToDetHousing: '00189426',
    IntensifierActiveShape: '00189427',
    IntensifierActiveDimensions: '00189428',
    PhysicalDetectorSize: '00189429',
    PositionOfIsocenterProjection: '00189430',
    FieldOfViewSequence: '00189432',
    FieldOfViewDescription: '00189433',
    ExposureControlSensingRegionsSeq: '00189434',
    ExposureControlSensingRegionShape: '00189435',
    ExposureControlSensRegionLeftEdge: '00189436',
    ExposureControlSensRegionRightEdge: '00189437',
    CenterOfCircExposControlSensRegion: '00189440',
    RadiusOfCircExposControlSensRegion: '00189441',
    ColumnAngulationPatient: '00189447',
    BeamAngle: '00189449',
    FrameDetectorParametersSequence: '00189451',
    CalculatedAnatomyThickness: '00189452',
    CalibrationSequence: '00189455',
    ObjectThicknessSequence: '00189456',
    PlaneIdentification: '00189457',
    FieldOfViewDimensionsInFloat: '00189461',
    IsocenterReferenceSystemSequence: '00189462',
    PositionerIsocenterPrimaryAngle: '00189463',
    PositionerIsocenterSecondaryAngle: '00189464',
    PositionerIsocenterDetRotAngle: '00189465',
    TableXPositionToIsocenter: '00189466',
    TableYPositionToIsocenter: '00189467',
    TableZPositionToIsocenter: '00189468',
    TableHorizontalRotationAngle: '00189469',
    TableHeadTiltAngle: '00189470',
    TableCradleTiltAngle: '00189471',
    FrameDisplayShutterSequence: '00189472',
    AcquiredImageAreaDoseProduct: '00189473',
    CArmPositionerTabletopRelationship: '00189474',
    XRayGeometrySequence: '00189476',
    IrradiationEventIDSequence: '00189477',
    XRay3DFrameTypeSequence: '00189504',
    ContributingSourcesSequence: '00189506',
    XRay3DAcquisitionSequence: '00189507',
    PrimaryPositionerScanArc: '00189508',
    SecondaryPositionerScanArc: '00189509',
    PrimaryPositionerScanStartAngle: '00189510',
    SecondaryPositionerScanStartAngle: '00189511',
    PrimaryPositionerIncrement: '00189514',
    SecondaryPositionerIncrement: '00189515',
    StartAcquisitionDateTime: '00189516',
    EndAcquisitionDateTime: '00189517',
    ApplicationName: '00189524',
    ApplicationVersion: '00189525',
    ApplicationManufacturer: '00189526',
    AlgorithmType: '00189527',
    AlgorithmDescription: '00189528',
    XRay3DReconstructionSequence: '00189530',
    ReconstructionDescription: '00189531',
    PerProjectionAcquisitionSequence: '00189538',
    DiffusionBMatrixSequence: '00189601',
    DiffusionBValueXX: '00189602',
    DiffusionBValueXY: '00189603',
    DiffusionBValueXZ: '00189604',
    DiffusionBValueYY: '00189605',
    DiffusionBValueYZ: '00189606',
    DiffusionBValueZZ: '00189607',
    DecayCorrectionDateTime: '00189701',
    StartDensityThreshold: '00189715',
    TerminationTimeThreshold: '00189722',
    DetectorGeometry: '00189725',
    AxialDetectorDimension: '00189727',
    PETPositionSequence: '00189735',
    NumberOfIterations: '00189739',
    NumberOfSubsets: '00189740',
    PETFrameTypeSequence: '00189751',
    ReconstructionType: '00189756',
    DecayCorrected: '00189758',
    AttenuationCorrected: '00189759',
    ScatterCorrected: '00189760',
    DeadTimeCorrected: '00189761',
    GantryMotionCorrected: '00189762',
    PatientMotionCorrected: '00189763',
    RandomsCorrected: '00189765',
    SensitivityCalibrated: '00189767',
    DepthsOfFocus: '00189801',
    ExclusionStartDatetime: '00189804',
    ExclusionDuration: '00189805',
    ImageDataTypeSequence: '00189807',
    DataType: '00189808',
    AliasedDataType: '0018980B',
    ContributingEquipmentSequence: '0018A001',
    ContributionDateTime: '0018A002',
    ContributionDescription: '0018A003',
    NumberOfCellsIInDetector: '00191002',
    CellNumberAtTheta: '00191003',
    CellSpacing: '00191004',
    HorizFrameOfRef: '0019100F',
    SeriesContrast: '00191011',
    LastPseq: '00191012',
    StartNumberForBaseline: '00191013',
    EndNumberForBaseline: '00191014',
    StartNumberForEnhancedScans: '00191015',
    EndNumberForEnhancedScans: '00191016',
    SeriesPlane: '00191017',
    FirstScanRas: '00191018',
    FirstScanLocation: '00191019',
    LastScanRas: '0019101A',
    LastScanLoc: '0019101B',
    DisplayFieldOfView: '0019101E',
    MidScanTime: '00191024',
    MidScanFlag: '00191025',
    DegreesOfAzimuth: '00191026',
    GantryPeriod: '00191027',
    XRayOnPosition: '0019102A',
    XRayOffPosition: '0019102B',
    NumberOfTriggers: '0019102C',
    AngleOfFirstView: '0019102E',
    TriggerFrequency: '0019102F',
    ScanFOVType: '00191039',
    StatReconFlag: '00191040',
    ComputeType: '00191041',
    SegmentNumber: '00620004',
    TotalSegmentsRequested: '00191043',
    InterscanDelay: '00191044',
    ViewCompressionFactor: '00191047',
    TotalNoOfRefChannels: '0019104A',
    DataSizeForScanData: '0019104B',
    ReconPostProcflag: '00191052',
    CTWaterNumber: '00191057',
    CTBoneNumber: '00191058',
    NumberOfChannels: '50..2004',
    IncrementBetweenChannels: '0019105F',
    StartingView: '00191060',
    NumberOfViews: '00191061',
    IncrementBetweenViews: '00191062',
    DependantOnNoViewsProcessed: '0019106A',
    FieldOfViewInDetectorCells: '0019106B',
    ValueOfBackProjectionButton: '00191070',
    SetIfFatqEstimatesWereUsed: '00191071',
    ZChanAvgOverViews: '00191072',
    AvgOfLeftRefChansOverViews: '00191073',
    MaxLeftChanOverViews: '00191074',
    AvgOfRightRefChansOverViews: '00191075',
    MaxRightChanOverViews: '00191076',
    SecondEcho: '0019107D',
    NumberOfEchoes: '0019107E',
    TableDelta: '0019107F',
    Contiguous: '00191081',
    PeakSAR: '00191084',
    MonitorSAR: '00191085',
    CardiacRepetitionTime: '00191087',
    ImagesPerCardiacCycle: '00191088',
    ActualReceiveGainAnalog: '0019108A',
    ActualReceiveGainDigital: '0019108B',
    DelayAfterTrigger: '0019108D',
    Swappf: '0019108F',
    PauseInterval: '00191090',
    PulseTime: '00191091',
    SliceOffsetOnFreqAxis: '00191092',
    CenterFrequency: '00191093',
    TransmitGain: '00191094',
    AnalogReceiverGain: '00191095',
    DigitalReceiverGain: '00191096',
    BitmapDefiningCVs: '00191097',
    CenterFreqMethod: '00191098',
    PulseSeqMode: '0019109B',
    PulseSeqName: '0019109C',
    PulseSeqDate: '0019109D',
    InternalPulseSeqName: '0019109E',
    TransmittingCoil: '0019109F',
    SurfaceCoilType: '001910A0',
    ExtremityCoilFlag: '001910A1',
    RawDataRunNumber: '001910A2',
    CalibratedFieldStrength: '001910A3',
    SATFatWaterBone: '001910A4',
    ReceiveBandwidth: '001910A5',
    UserData01: '001910A7',
    UserData02: '001910A8',
    UserData03: '001910A9',
    UserData04: '001910AA',
    UserData05: '001910AB',
    UserData06: '001910AC',
    UserData07: '001910AD',
    UserData08: '001910AE',
    UserData09: '001910AF',
    UserData10: '001910B0',
    UserData11: '001910B1',
    UserData12: '001910B2',
    UserData13: '001910B3',
    UserData14: '001910B4',
    UserData15: '001910B5',
    UserData16: '001910B6',
    UserData17: '001910B7',
    UserData18: '001910B8',
    UserData19: '001910B9',
    UserData20: '001910BA',
    UserData21: '001910BB',
    UserData22: '001910BC',
    UserData23: '001910BD',
    ProjectionAngle: '001910BE',
    SaturationPlanes: '001910C0',
    SurfaceCoilIntensity: '001910C1',
    SATLocationR: '001910C2',
    SATLocationL: '001910C3',
    SATLocationA: '001910C4',
    SATLocationP: '001910C5',
    SATLocationH: '001910C6',
    SATLocationF: '001910C7',
    'SATThicknessR-L': '001910C8',
    'SATThicknessA-P': '001910C9',
    'SATThicknessH-F': '001910CA',
    PrescribedFlowAxis: '001910CB',
    VelocityEncoding: '001910CC',
    ThicknessDisclaimer: '001910CD',
    PrescanType: '001910CE',
    PrescanStatus: '001910CF',
    RawDataType: '00431032',
    ProjectionAlgorithm: '001910D3',
    FractionalEcho: '001910D5',
    PrepPulse: '001910D6',
    CardiacPhases: '001910D7',
    VariableEchoflag: '001910D8',
    ConcatenatedSAT: '001910D9',
    ReferenceChannelUsed: '001910DA',
    BackProjectorCoefficient: '001910DB',
    PrimarySpeedCorrectionUsed: '001910DC',
    OverrangeCorrectionUsed: '001910DD',
    DynamicZAlphaValue: '001910DE',
    UserData: '001910E0',
    VelocityEncodeScale: '001910E2',
    FastPhases: '001910F2',
    TransmissionGain: '001910F9',
    RelationshipGroupLength: '00200000',
    StudyInstanceUID: '0020000D',
    SeriesInstanceUID: '0020000E',
    StudyID: '00200010',
    SeriesNumber: '00200011',
    AcquisitionNumber: '00200012',
    InstanceNumber: '00200013',
    IsotopeNumber: '00200014',
    PhaseNumber: '00200015',
    IntervalNumber: '00200016',
    TimeSlotNumber: '00200017',
    AngleNumber: '00200018',
    ItemNumber: '00200019',
    PatientOrientation: '00200020',
    OverlayNumber: '00200022',
    CurveNumber: '00200024',
    LookupTableNumber: '00200026',
    ImagePosition: '00211054',
    ImagePositionPatient: '00200032',
    ImageOrientation: '00211055',
    ImageOrientationPatient: '00200037',
    Location: '00200050',
    FrameOfReferenceUID: '00200052',
    Laterality: '00200060',
    ImageLaterality: '00200062',
    ImageGeometryType: '00200070',
    MaskingImage: '00200080',
    TemporalPositionIdentifier: '00200100',
    NumberOfTemporalPositions: '00200105',
    TemporalResolution: '00200110',
    SynchronizationFrameOfReferenceUID: '00200200',
    SeriesInStudy: '00201000',
    AcquisitionsInSeries: '00201001',
    ImagesInAcquisition: '00201002',
    ImagesInSeries: '00251007',
    AcquisitionsInStudy: '00201004',
    ImagesInStudy: '00201005',
    Reference: '00201020',
    PositionReferenceIndicator: '00201040',
    SliceLocation: '00201041',
    OtherStudyNumbers: '00201070',
    NumberOfPatientRelatedStudies: '00201200',
    NumberOfPatientRelatedSeries: '00201202',
    NumberOfPatientRelatedInstances: '00201204',
    NumberOfStudyRelatedSeries: '00201206',
    NumberOfStudyRelatedInstances: '00201208',
    NumberOfSeriesRelatedInstances: '00201209',
    SourceImageIDs: '002031..',
    ModifyingDeviceID: '00203401',
    ModifiedImageID: '00203402',
    ModifiedImageDate: '00203403',
    ModifyingDeviceManufacturer: '00203404',
    ModifiedImageTime: '00203405',
    ModifiedImageDescription: '00203406',
    ImageComments: '00204000',
    OriginalImageIdentification: '00205000',
    OriginalImageIdentNomenclature: '00205002',
    StackID: '00209056',
    InStackPositionNumber: '00209057',
    FrameAnatomySequence: '00209071',
    FrameLaterality: '00209072',
    FrameContentSequence: '00209111',
    PlanePositionSequence: '00209113',
    PlaneOrientationSequence: '00209116',
    TemporalPositionIndex: '00209128',
    TriggerDelayTime: '00209153',
    FrameAcquisitionNumber: '00209156',
    DimensionIndexValues: '00209157',
    FrameComments: '00209158',
    ConcatenationUID: '00209161',
    InConcatenationNumber: '00209162',
    InConcatenationTotalNumber: '00209163',
    DimensionOrganizationUID: '00209164',
    DimensionIndexPointer: '00209165',
    FunctionalGroupPointer: '00209167',
    DimensionIndexPrivateCreator: '00209213',
    DimensionOrganizationSequence: '00209221',
    DimensionIndexSequence: '00209222',
    ConcatenationFrameOffsetNumber: '00209228',
    FunctionalGroupPrivateCreator: '00209238',
    NominalPercentageOfCardiacPhase: '00209241',
    NominalPercentOfRespiratoryPhase: '00209245',
    StartingRespiratoryAmplitude: '00209246',
    StartingRespiratoryPhase: '00209247',
    EndingRespiratoryAmplitude: '00209248',
    EndingRespiratoryPhase: '00209249',
    RespiratoryTriggerType: '00209250',
    RRIntervalTimeNominal: '00209251',
    ActualCardiacTriggerDelayTime: '00209252',
    RespiratorySynchronizationSequence: '00209253',
    RespiratoryIntervalTime: '00209254',
    NominalRespiratoryTriggerDelayTime: '00209255',
    RespiratoryTriggerDelayThreshold: '00209256',
    ActualRespiratoryTriggerDelayTime: '00209257',
    ImagePositionVolume: '00209301',
    ImageOrientationVolume: '00209302',
    ApexPosition: '00209308',
    DimensionDescriptionLabel: '00209421',
    PatientOrientationInFrameSequence: '00209450',
    FrameLabel: '00209453',
    AcquisitionIndex: '00209518',
    ContributingSOPInstancesRefSeq: '00209529',
    ReconstructionIndex: '00209536',
    SeriesFromWhichPrescribed: '00211035',
    SeriesRecordChecksum: '00211007',
    AcqreconRecordChecksum: '00211019',
    TableStartLocation: '00271050',
    ImageFromWhichPrescribed: '00211036',
    ScreenFormat: '00211037',
    AnatomicalReferenceForScout: '0021104A',
    LocationsInAcquisition: '0021104F',
    GraphicallyPrescribed: '00211050',
    RotationFromSourceXRot: '00211051',
    RotationFromSourceYRot: '00211052',
    RotationFromSourceZRot: '00211053',
    IntegerSlop: '0021105A',
    FloatSlop: '0021105F',
    AutoWindowLevelAlpha: '00211081',
    AutoWindowLevelBeta: '00211082',
    AutoWindowLevelWindow: '00211083',
    ToWindowLevelLevel: '00211084',
    TubeFocalSpotPosition: '00211090',
    BiopsyPosition: '00211091',
    BiopsyTLocation: '00211092',
    BiopsyRefLocation: '00211093',
    LightPathFilterPassThroughWavelen: '00220001',
    LightPathFilterPassBand: '00220002',
    ImagePathFilterPassThroughWavelen: '00220003',
    ImagePathFilterPassBand: '00220004',
    PatientEyeMovementCommanded: '00220005',
    PatientEyeMovementCommandCodeSeq: '00220006',
    SphericalLensPower: '00220007',
    CylinderLensPower: '00220008',
    CylinderAxis: '00220009',
    EmmetropicMagnification: '0022000A',
    IntraOcularPressure: '0022000B',
    HorizontalFieldOfView: '0022000C',
    PupilDilated: '0022000D',
    DegreeOfDilation: '0022000E',
    StereoBaselineAngle: '00220010',
    StereoBaselineDisplacement: '00220011',
    StereoHorizontalPixelOffset: '00220012',
    StereoVerticalPixelOffset: '00220013',
    StereoRotation: '00220014',
    AcquisitionDeviceTypeCodeSequence: '00220015',
    IlluminationTypeCodeSequence: '00220016',
    LightPathFilterTypeStackCodeSeq: '00220017',
    ImagePathFilterTypeStackCodeSeq: '00220018',
    LensesCodeSequence: '00220019',
    ChannelDescriptionCodeSequence: '0022001A',
    RefractiveStateSequence: '0022001B',
    MydriaticAgentCodeSequence: '0022001C',
    RelativeImagePositionCodeSequence: '0022001D',
    StereoPairsSequence: '00220020',
    LeftImageSequence: '00220021',
    RightImageSequence: '00220022',
    AxialLengthOfTheEye: '00220030',
    OphthalmicFrameLocationSequence: '00220031',
    ReferenceCoordinates: '00220032',
    DepthSpatialResolution: '00220035',
    MaximumDepthDistortion: '00220036',
    AlongScanSpatialResolution: '00220037',
    MaximumAlongScanDistortion: '00220038',
    OphthalmicImageOrientation: '00220039',
    DepthOfTransverseImage: '00220041',
    MydriaticAgentConcUnitsSeq: '00220042',
    AcrossScanSpatialResolution: '00220048',
    MaximumAcrossScanDistortion: '00220049',
    MydriaticAgentConcentration: '0022004E',
    IlluminationWaveLength: '00220055',
    IlluminationPower: '00220056',
    IlluminationBandwidth: '00220057',
    MydriaticAgentSequence: '00220058',
    NumberOfSeriesInStudy: '00231001',
    NumberOfUnarchivedSeries: '00231002',
    ReferenceImageField: '00231010',
    SummaryImage: '00231050',
    StartTimeSecsInFirstAxial: '00231070',
    NoofUpdatesToHeader: '00231074',
    IndicatesIfTheStudyHasCompleteInfo: '0023107D',
    LastPulseSequenceUsed: '00251006',
    LandmarkCounter: '00251010',
    NumberOfAcquisitions: '00251011',
    IndicatesNoofUpdatesToHeader: '00251014',
    SeriesCompleteFlag: '00251017',
    NumberOfImagesArchived: '00251018',
    LastImageNumberUsed: '00251019',
    PrimaryReceiverSuiteAndHost: '0025101A',
    ImageArchiveFlag: '00271006',
    ScoutType: '00271010',
    VmaMamp: '0027101C',
    VmaPhase: '0027101D',
    VmaMod: '0027101E',
    VmaClip: '0027101F',
    SmartScanOnOffFlag: '00271020',
    ForeignImageRevision: '00271030',
    ImagingMode: '00271031',
    PulseSequence: '00271032',
    ImagingOptions: '00271033',
    PlaneType: '00271035',
    ObliquePlane: '00271036',
    RASLetterOfImageLocation: '00271040',
    ImageLocation: '00280200',
    CenterRCoordOfPlaneImage: '00271042',
    CenterACoordOfPlaneImage: '00271043',
    CenterSCoordOfPlaneImage: '00271044',
    NormalRCoord: '00271045',
    NormalACoord: '00271046',
    NormalSCoord: '00271047',
    RCoordOfTopRightCorner: '00271048',
    ACoordOfTopRightCorner: '00271049',
    SCoordOfTopRightCorner: '0027104A',
    RCoordOfBottomRightCorner: '0027104B',
    ACoordOfBottomRightCorner: '0027104C',
    SCoordOfBottomRightCorner: '0027104D',
    TableEndLocation: '00271051',
    RASLetterForSideOfImage: '00271052',
    RASLetterForAnteriorPosterior: '00271053',
    RASLetterForScoutStartLoc: '00271054',
    RASLetterForScoutEndLoc: '00271055',
    ImageDimensionX: '00271060',
    ImageDimensionY: '00271061',
    NumberOfExcitations: '00271062',
    ImagePresentationGroupLength: '00280000',
    SamplesPerPixel: '00280002',
    SamplesPerPixelUsed: '00280003',
    PhotometricInterpretation: '00280004',
    ImageDimensions: '00280005',
    PlanarConfiguration: '00280006',
    NumberOfFrames: '00280008',
    FrameIncrementPointer: '00280009',
    FrameDimensionPointer: '0028000A',
    Rows: '00280010',
    Columns: '00280011',
    Planes: '00280012',
    UltrasoundColorDataPresent: '00280014',
    PixelSpacing: '00280030',
    ZoomFactor: '00280031',
    ZoomCenter: '00280032',
    PixelAspectRatio: '00280034',
    ImageFormat: '00280040',
    ManipulatedImage: '00280050',
    CorrectedImage: '00280051',
    CompressionRecognitionCode: '0028005F',
    CompressionCode: '00280060',
    CompressionOriginator: '00280061',
    CompressionLabel: '00280062',
    CompressionDescription: '00280063',
    CompressionSequence: '00280065',
    CompressionStepPointers: '00280066',
    RepeatInterval: '00280068',
    BitsGrouped: '00280069',
    PerimeterTable: '00280070',
    PerimeterValue: '00280071',
    PredictorRows: '00280080',
    PredictorColumns: '00280081',
    PredictorConstants: '00280082',
    BlockedPixels: '00280090',
    BlockRows: '00280091',
    BlockColumns: '00280092',
    RowOverlap: '00280093',
    ColumnOverlap: '00280094',
    BitsAllocated: '00280100',
    BitsStored: '00280101',
    HighBit: '00280102',
    PixelRepresentation: '00280103',
    '': '',
    SmallestValidPixelValue: '00280104',
    LargestValidPixelValue: '00280105',
    SmallestImagePixelValue: '00280106',
    LargestImagePixelValue: '00280107',
    SmallestPixelValueInSeries: '00280108',
    LargestPixelValueInSeries: '00280109',
    SmallestImagePixelValueInPlane: '00280110',
    LargestImagePixelValueInPlane: '00280111',
    PixelPaddingValue: '00280120',
    PixelPaddingRangeLimit: '00280121',
    QualityControlImage: '00280300',
    BurnedInAnnotation: '00280301',
    TransformLabel: '00280400',
    TransformVersionNumber: '00280401',
    NumberOfTransformSteps: '00280402',
    SequenceOfCompressedData: '00280403',
    DetailsOfCoefficients: '00280404',
    CoefficientCoding: '002804x2',
    CoefficientCodingPointers: '002804x3',
    DCTLabel: '00280700',
    DataBlockDescription: '00280701',
    DataBlock: '00280702',
    NormalizationFactorFormat: '00280710',
    ZonalMapNumberFormat: '00280720',
    ZonalMapLocation: '00280721',
    ZonalMapFormat: '00280722',
    AdaptiveMapFormat: '00280730',
    CodeNumberFormat: '00280740',
    CodeLabel: '002808x0',
    NumberOfTables: '002808x2',
    CodeTableLocation: '002808x3',
    BitsForCodeWord: '002808x4',
    ImageDataLocation: '002808x8',
    PixelSpacingCalibrationType: '00280A02',
    PixelSpacingCalibrationDescription: '00280A04',
    PixelIntensityRelationship: '00281040',
    PixelIntensityRelationshipSign: '00281041',
    WindowCenter: '00281050',
    WindowWidth: '00281051',
    RescaleIntercept: '00281052',
    RescaleSlope: '00281053',
    RescaleType: '00281054',
    WindowCenterAndWidthExplanation: '00281055',
    VOI_LUTFunction: '00281056',
    GrayScale: '00281080',
    RecommendedViewingMode: '00281090',
    GrayLookupTableDescriptor: '00281100',
    RedPaletteColorTableDescriptor: '00281101',
    GreenPaletteColorTableDescriptor: '00281102',
    BluePaletteColorTableDescriptor: '00281103',
    LargeRedPaletteColorTableDescr: '00281111',
    LargeGreenPaletteColorTableDescr: '00281112',
    LargeBluePaletteColorTableDescr: '00281113',
    PaletteColorTableUID: '00281199',
    GrayLookupTableData: '00281200',
    RedPaletteColorTableData: '00281201',
    GreenPaletteColorTableData: '00281202',
    BluePaletteColorTableData: '00281203',
    LargeRedPaletteColorTableData: '00281211',
    LargeGreenPaletteColorTableData: '00281212',
    LargeBluePaletteColorTableData: '00281213',
    LargePaletteColorLookupTableUID: '00281214',
    SegmentedRedColorTableData: '00281221',
    SegmentedGreenColorTableData: '00281222',
    SegmentedBlueColorTableData: '00281223',
    BreastImplantPresent: '00281300',
    PartialView: '00281350',
    PartialViewDescription: '00281351',
    PartialViewCodeSequence: '00281352',
    SpatialLocationsPreserved: '0028135A',
    DataPathAssignment: '00281402',
    BlendingLUT1Sequence: '00281404',
    BlendingWeightConstant: '00281406',
    BlendingLookupTableData: '00281408',
    BlendingLUT2Sequence: '0028140C',
    DataPathID: '0028140E',
    RGBLUTTransferFunction: '0028140F',
    AlphaLUTTransferFunction: '00281410',
    ICCProfile: '00282000',
    LossyImageCompressionRatio: '00282112',
    LossyImageCompressionMethod: '00282114',
    ModalityLUTSequence: '00283000',
    LUTDescriptor: '00283002',
    LUTExplanation: '00283003',
    ModalityLUTType: '00283004',
    LUTData: '00283006',
    VOILUTSequence: '00283010',
    SoftcopyVOILUTSequence: '00283110',
    ImagePresentationComments: '00284000',
    BiPlaneAcquisitionSequence: '00285000',
    RepresentativeFrameNumber: '00286010',
    FrameNumbersOfInterest: '00286020',
    FrameOfInterestDescription: '00286022',
    FrameOfInterestType: '00286023',
    MaskPointers: '00286030',
    RWavePointer: '00286040',
    MaskSubtractionSequence: '00286100',
    MaskOperation: '00286101',
    ApplicableFrameRange: '00286102',
    MaskFrameNumbers: '00286110',
    ContrastFrameAveraging: '00286112',
    MaskSubPixelShift: '00286114',
    TIDOffset: '00286120',
    MaskOperationExplanation: '00286190',
    PixelDataProviderURL: '00287FE0',
    DataPointRows: '00289001',
    DataPointColumns: '00289002',
    SignalDomainColumns: '00289003',
    LargestMonochromePixelValue: '00289099',
    DataRepresentation: '00289108',
    PixelMeasuresSequence: '00289110',
    FrameVOILUTSequence: '00289132',
    PixelValueTransformationSequence: '00289145',
    SignalDomainRows: '00289235',
    DisplayFilterPercentage: '00289411',
    FramePixelShiftSequence: '00289415',
    SubtractionItemID: '00289416',
    PixelIntensityRelationshipLUTSeq: '00289422',
    FramePixelDataPropertiesSequence: '00289443',
    GeometricalProperties: '00289444',
    GeometricMaximumDistortion: '00289445',
    ImageProcessingApplied: '00289446',
    MaskSelectionMode: '00289454',
    LUTFunction: '00289474',
    MaskVisibilityPercentage: '00289478',
    PixelShiftSequence: '00289501',
    RegionPixelShiftSequence: '00289502',
    VerticesOfTheRegion: '00289503',
    PixelShiftFrameRange: '00289506',
    LUTFrameRange: '00289507',
    ImageToEquipmentMappingMatrix: '00289520',
    EquipmentCoordinateSystemID: '00289537',
    LowerRangeOfPixels1a: '00291004',
    LowerRangeOfPixels1b: '00291005',
    LowerRangeOfPixels1c: '00291006',
    LowerRangeOfPixels1d: '00291007',
    LowerRangeOfPixels1e: '00291008',
    LowerRangeOfPixels1f: '00291009',
    LowerRangeOfPixels1g: '0029100A',
    LowerRangeOfPixels1h: '00291015',
    LowerRangeOfPixels1i: '00291016',
    LowerRangeOfPixels2: '00291017',
    UpperRangeOfPixels2: '00291018',
    LenOfTotHdrInBytes: '0029101A',
    VersionOfTheHdrStruct: '00291026',
    AdvantageCompOverflow: '00291034',
    AdvantageCompUnderflow: '00291035',
    StudyGroupLength: '00320000',
    StudyStatusID: '0032000A',
    StudyPriorityID: '0032000C',
    StudyIDIssuer: '00320012',
    StudyVerifiedDate: '00320032',
    StudyVerifiedTime: '00320033',
    StudyReadDate: '00320034',
    StudyReadTime: '00320035',
    ScheduledStudyStartDate: '00321000',
    ScheduledStudyStartTime: '00321001',
    ScheduledStudyStopDate: '00321010',
    ScheduledStudyStopTime: '00321011',
    ScheduledStudyLocation: '00321020',
    ScheduledStudyLocationAETitle: '00321021',
    ReasonForStudy: '00321030',
    RequestingPhysicianIDSequence: '00321031',
    RequestingPhysician: '00321032',
    RequestingService: '00321033',
    StudyArrivalDate: '00321040',
    StudyArrivalTime: '00321041',
    StudyCompletionDate: '00321050',
    StudyCompletionTime: '00321051',
    StudyComponentStatusID: '00321055',
    RequestedProcedureDescription: '00321060',
    RequestedProcedureCodeSequence: '00321064',
    RequestedContrastAgent: '00321070',
    StudyComments: '00324000',
    ReferencedPatientAliasSequence: '00380004',
    VisitStatusID: '00380008',
    AdmissionID: '00380010',
    IssuerOfAdmissionID: '00380011',
    RouteOfAdmissions: '00380016',
    ScheduledAdmissionDate: '0038001A',
    ScheduledAdmissionTime: '0038001B',
    ScheduledDischargeDate: '0038001C',
    ScheduledDischargeTime: '0038001D',
    ScheduledPatientInstitResidence: '0038001E',
    AdmittingDate: '00380020',
    AdmittingTime: '00380021',
    DischargeDate: '00380030',
    DischargeTime: '00380032',
    DischargeDiagnosisDescription: '00380040',
    DischargeDiagnosisCodeSequence: '00380044',
    SpecialNeeds: '00380050',
    ServiceEpisodeID: '00380060',
    IssuerOfServiceEpisodeID: '00380061',
    ServiceEpisodeDescription: '00380062',
    PertinentDocumentsSequence: '00380100',
    CurrentPatientLocation: '00380300',
    PatientInstitutionResidence: '00380400',
    PatientState: '00380500',
    PatientClinicalTrialParticipSeq: '00380502',
    VisitComments: '00384000',
    WaveformOriginality: '003A0004',
    NumberOfWaveformChannels: '003A0005',
    NumberOfWaveformSamples: '003A0010',
    SamplingFrequency: '003A001A',
    MultiplexGroupLabel: '003A0020',
    ChannelDefinitionSequence: '003A0200',
    WaveformChannelNumber: '003A0202',
    ChannelLabel: '003A0203',
    ChannelStatus: '003A0205',
    ChannelSourceSequence: '003A0208',
    ChannelSourceModifiersSequence: '003A0209',
    SourceWaveformSequence: '003A020A',
    ChannelDerivationDescription: '003A020C',
    ChannelSensitivity: '003A0210',
    ChannelSensitivityUnitsSequence: '003A0211',
    ChannelSensitivityCorrectionFactor: '003A0212',
    ChannelBaseline: '003A0213',
    ChannelTimeSkew: '003A0214',
    ChannelSampleSkew: '003A0215',
    ChannelOffset: '003A0218',
    WaveformBitsStored: '003A021A',
    FilterLowFrequency: '003A0220',
    FilterHighFrequency: '003A0221',
    NotchFilterFrequency: '003A0222',
    NotchFilterBandwidth: '003A0223',
    WaveformDataDisplayScale: '003A0230',
    WaveformDisplayBkgCIELabValue: '003A0231',
    WaveformPresentationGroupSequence: '003A0240',
    PresentationGroupNumber: '003A0241',
    ChannelDisplaySequence: '003A0242',
    ChannelRecommendDisplayCIELabValue: '003A0244',
    ChannelPosition: '003A0245',
    DisplayShadingFlag: '003A0246',
    FractionalChannelDisplayScale: '003A0247',
    AbsoluteChannelDisplayScale: '003A0248',
    MultiplexAudioChannelsDescrCodeSeq: '003A0300',
    ChannelIdentificationCode: '003A0301',
    ChannelMode: '003A0302',
    ScheduledStationAETitle: '00400001',
    ScheduledProcedureStepStartDate: '00400002',
    ScheduledProcedureStepStartTime: '00400003',
    ScheduledProcedureStepEndDate: '00400004',
    ScheduledProcedureStepEndTime: '00400005',
    ScheduledPerformingPhysiciansName: '00400006',
    ScheduledProcedureStepDescription: '00400007',
    ScheduledProtocolCodeSequence: '00400008',
    ScheduledProcedureStepID: '00400009',
    StageCodeSequence: '0040000A',
    ScheduledPerformingPhysicianIDSeq: '0040000B',
    ScheduledStationName: '00400010',
    ScheduledProcedureStepLocation: '00400011',
    PreMedication: '00400012',
    ScheduledProcedureStepStatus: '00400020',
    LocalNamespaceEntityID: '00400031',
    UniversalEntityID: '00400032',
    UniversalEntityIDType: '00400033',
    IdentifierTypeCode: '00400035',
    AssigningFacilitySequence: '00400036',
    ScheduledProcedureStepSequence: '00400100',
    ReferencedNonImageCompositeSOPSeq: '00400220',
    PerformedStationAETitle: '00400241',
    PerformedStationName: '00400242',
    PerformedLocation: '00400243',
    PerformedProcedureStepStartDate: '00400244',
    PerformedProcedureStepStartTime: '00400245',
    PerformedProcedureStepEndDate: '00400250',
    PerformedProcedureStepEndTime: '00400251',
    PerformedProcedureStepStatus: '00400252',
    PerformedProcedureStepID: '00400253',
    PerformedProcedureStepDescription: '00400254',
    PerformedProcedureTypeDescription: '00400255',
    PerformedProtocolCodeSequence: '00400260',
    PerformedProtocolType: '00400261',
    ScheduledStepAttributesSequence: '00400270',
    RequestAttributesSequence: '00400275',
    CommentsOnPerformedProcedureStep: '00400280',
    ProcStepDiscontinueReasonCodeSeq: '00400281',
    QuantitySequence: '00400293',
    Quantity: '00400294',
    MeasuringUnitsSequence: '00400295',
    BillingItemSequence: '00400296',
    TotalTimeOfFluoroscopy: '00400300',
    TotalNumberOfExposures: '00400301',
    EntranceDose: '00400302',
    ExposedArea: '00400303',
    DistanceSourceToEntrance: '00400306',
    DistanceSourceToSupport: '00400307',
    ExposureDoseSequence: '0040030E',
    CommentsOnRadiationDose: '00400310',
    XRayOutput: '00400312',
    HalfValueLayer: '00400314',
    OrganDose: '00400316',
    OrganExposed: '00400318',
    BillingProcedureStepSequence: '00400320',
    FilmConsumptionSequence: '00400321',
    BillingSuppliesAndDevicesSequence: '00400324',
    PerformedSeriesSequence: '00400340',
    CommentsOnScheduledProcedureStep: '00400400',
    ProtocolContextSequence: '00400440',
    ContentItemModifierSequence: '00400441',
    SpecimenAccessionNumber: '0040050A',
    ContainerIdentifier: '00400512',
    ContainerDescription: '0040051A',
    SpecimenSequence: '00400550',
    SpecimenIdentifier: '00400551',
    SpecimenDescriptionSequenceTrial: '00400552',
    SpecimenDescriptionTrial: '00400553',
    SpecimenUID: '00400554',
    AcquisitionContextSequence: '00400555',
    AcquisitionContextDescription: '00400556',
    SpecimenTypeCodeSequence: '0040059A',
    SpecimenShortDescription: '00400600',
    SlideIdentifier: '004006FA',
    ImageCenterPointCoordinatesSeq: '0040071A',
    XOffsetInSlideCoordinateSystem: '0040072A',
    YOffsetInSlideCoordinateSystem: '0040073A',
    ZOffsetInSlideCoordinateSystem: '0040074A',
    PixelSpacingSequence: '004008D8',
    CoordinateSystemAxisCodeSequence: '004008DA',
    MeasurementUnitsCodeSequence: '004008EA',
    VitalStainCodeSequenceTrial: '004009F8',
    RequestedProcedureID: '00401001',
    ReasonForRequestedProcedure: '00401002',
    RequestedProcedurePriority: '00401003',
    PatientTransportArrangements: '00401004',
    RequestedProcedureLocation: '00401005',
    'PlacerOrderNumber-Procedure': '00401006',
    'FillerOrderNumber-Procedure': '00401007',
    ConfidentialityCode: '00401008',
    ReportingPriority: '00401009',
    ReasonForRequestedProcedureCodeSeq: '0040100A',
    NamesOfIntendedRecipientsOfResults: '00401010',
    IntendedRecipientsOfResultsIDSeq: '00401011',
    PersonIdentificationCodeSequence: '00401101',
    PersonAddress: '00401102',
    PersonTelephoneNumbers: '00401103',
    RequestedProcedureComments: '00401400',
    ReasonForImagingServiceRequest: '00402001',
    IssueDateOfImagingServiceRequest: '00402004',
    IssueTimeOfImagingServiceRequest: '00402005',
    'PlacerOrderNum-ImagingServiceReq': '00402016',
    'FillerOrderNum-ImagingServiceReq': '00402017',
    OrderEnteredBy: '00402008',
    OrderEntererLocation: '00402009',
    OrderCallbackPhoneNumber: '00402010',
    ImagingServiceRequestComments: '00402400',
    ConfidentialityOnPatientDataDescr: '00403001',
    GenPurposeScheduledProcStepStatus: '00404001',
    GenPurposePerformedProcStepStatus: '00404002',
    GenPurposeSchedProcStepPriority: '00404003',
    SchedProcessingApplicationsCodeSeq: '00404004',
    SchedProcedureStepStartDateAndTime: '00404005',
    MultipleCopiesFlag: '00404006',
    PerformedProcessingAppsCodeSeq: '00404007',
    HumanPerformerCodeSequence: '00404009',
    SchedProcStepModificationDateTime: '00404010',
    ExpectedCompletionDateAndTime: '00404011',
    ResultingGenPurposePerfProcStepSeq: '00404015',
    RefGenPurposeSchedProcStepSeq: '00404016',
    ScheduledWorkitemCodeSequence: '00404018',
    PerformedWorkitemCodeSequence: '00404019',
    InputAvailabilityFlag: '00404020',
    InputInformationSequence: '00404021',
    RelevantInformationSequence: '00404022',
    RefGenPurSchedProcStepTransUID: '00404023',
    ScheduledStationNameCodeSequence: '00404025',
    ScheduledStationClassCodeSequence: '00404026',
    SchedStationGeographicLocCodeSeq: '00404027',
    PerformedStationNameCodeSequence: '00404028',
    PerformedStationClassCodeSequence: '00404029',
    PerformedStationGeogLocCodeSeq: '00404030',
    RequestedSubsequentWorkItemCodeSeq: '00404031',
    NonDICOMOutputCodeSequence: '00404032',
    OutputInformationSequence: '00404033',
    ScheduledHumanPerformersSequence: '00404034',
    ActualHumanPerformersSequence: '00404035',
    HumanPerformersOrganization: '00404036',
    HumanPerformerName: '00404037',
    RawDataHandling: '00404040',
    EntranceDoseInMilliGy: '00408302',
    RefImageRealWorldValueMappingSeq: '00409094',
    RealWorldValueMappingSequence: '00409096',
    PixelValueMappingCodeSequence: '00409098',
    LUTLabel: '00409210',
    RealWorldValueLastValueMapped: '00409211',
    RealWorldValueLUTData: '00409212',
    RealWorldValueFirstValueMapped: '00409216',
    RealWorldValueIntercept: '00409224',
    RealWorldValueSlope: '00409225',
    RelationshipType: '0040A010',
    VerifyingOrganization: '0040A027',
    VerificationDateTime: '0040A030',
    ObservationDateTime: '0040A032',
    ValueType: '0040A040',
    ConceptNameCodeSequence: '0040A043',
    ContinuityOfContent: '0040A050',
    VerifyingObserverSequence: '0040A073',
    VerifyingObserverName: '0040A075',
    AuthorObserverSequence: '0040A078',
    ParticipantSequence: '0040A07A',
    CustodialOrganizationSequence: '0040A07C',
    ParticipationType: '0040A080',
    ParticipationDateTime: '0040A082',
    ObserverType: '0040A084',
    VerifyingObserverIdentCodeSequence: '0040A088',
    EquivalentCDADocumentSequence: '0040A090',
    ReferencedWaveformChannels: '0040A0B0',
    DateTime: '0040A120',
    Date: '0040A121',
    Time: '0040A122',
    PersonName: '0040A123',
    UID: '0040A124',
    TemporalRangeType: '0040A130',
    ReferencedSamplePositions: '0040A132',
    ReferencedFrameNumbers: '0040A136',
    ReferencedTimeOffsets: '0040A138',
    ReferencedDateTime: '0040A13A',
    TextValue: '0040A160',
    ConceptCodeSequence: '0040A168',
    PurposeOfReferenceCodeSequence: '0040A170',
    AnnotationGroupNumber: '0040A180',
    ModifierCodeSequence: '0040A195',
    MeasuredValueSequence: '0040A300',
    NumericValueQualifierCodeSequence: '0040A301',
    NumericValue: '0040A30A',
    AddressTrial: '0040A353',
    TelephoneNumberTrial: '0040A354',
    PredecessorDocumentsSequence: '0040A360',
    ReferencedRequestSequence: '0040A370',
    PerformedProcedureCodeSequence: '0040A372',
    CurrentRequestedProcEvidenceSeq: '0040A375',
    PertinentOtherEvidenceSequence: '0040A385',
    HL7StructuredDocumentRefSeq: '0040A390',
    CompletionFlag: '0040A491',
    CompletionFlagDescription: '0040A492',
    VerificationFlag: '0040A493',
    ArchiveRequested: '0040A494',
    PreliminaryFlag: '0040A496',
    ContentTemplateSequence: '0040A504',
    IdenticalDocumentsSequence: '0040A525',
    ContentSequence: '0040A730',
    AnnotationSequence: '0040B020',
    TemplateIdentifier: '0040DB00',
    TemplateVersion: '0040DB06',
    TemplateLocalVersion: '0040DB07',
    TemplateExtensionFlag: '0040DB0B',
    TemplateExtensionOrganizationUID: '0040DB0C',
    TemplateExtensionCreatorUID: '0040DB0D',
    ReferencedContentItemIdentifier: '0040DB73',
    HL7InstanceIdentifier: '0040E001',
    HL7DocumentEffectiveTime: '0040E004',
    HL7DocumentTypeCodeSequence: '0040E006',
    RetrieveURI: '0040E010',
    RetrieveLocationUID: '0040E011',
    DocumentTitle: '00420010',
    EncapsulatedDocument: '00420011',
    MIMETypeOfEncapsulatedDocument: '00420012',
    SourceInstanceSequence: '00420013',
    ListOfMIMETypes: '00420014',
    BitmapOfPrescanOptions: '00431001',
    GradientOffsetInX: '00431002',
    GradientOffsetInY: '00431003',
    GradientOffsetInZ: '00431004',
    ImgIsOriginalOrUnoriginal: '00431005',
    NumberOfEPIShots: '00431006',
    ViewsPerSegment: '00431007',
    RespiratoryRateBpm: '00431008',
    RespiratoryTriggerPoint: '00431009',
    TypeOfReceiverUsed: '0043100A',
    PeakRateOfChangeOfGradientField: '0043100B',
    LimitsInUnitsOfPercent: '0043100C',
    PSDEstimatedLimit: '0043100D',
    PSDEstimatedLimitInTeslaPerSecond: '0043100E',
    Saravghead: '0043100F',
    WindowValue: '00431010',
    TotalInputViews: '00431011',
    'X-RayChain': '00431012',
    DeconKernelParameters: '00431013',
    CalibrationParameters: '00431014',
    TotalOutputViews: '00431015',
    NumberOfOverranges: '00431016',
    IBHImageScaleFactors: '00431017',
    BBHCoefficients: '00431018',
    NumberOfBBHChainsToBlend: '00431019',
    StartingChannelNumber: '0043101A',
    PpscanParameters: '0043101B',
    GEImageIntegrity: '0043101C',
    LevelValue: '0043101D',
    DeltaStartTime: '0043101E',
    MaxOverrangesInAView: '0043101F',
    AvgOverrangesAllViews: '00431020',
    CorrectedAfterGlowTerms: '00431021',
    ReferenceChannels: '00431025',
    NoViewsRefChansBlocked: '00431026',
    ScanPitchRatio: '00431027',
    UniqueImageIden: '00431028',
    HistogramTables: '00431029',
    UserDefinedData: '0043102A',
    PrivateScanOptions: '0043102B',
    EffectiveEchoSpacing: '0043102C',
    StringSlopField1: '0043102D',
    StringSlopField2: '0043102E',
    RACordOfTargetReconCenter: '00431031',
    NegScanspacing: '00431033',
    OffsetFrequency: '00431034',
    UserUsageTag: '00431035',
    UserFillMapMSW: '00431036',
    UserFillMapLSW: '00431037',
    'User25-48': '00431038',
    'SlopInt6-9': '00431039',
    TriggerOnPosition: '00431040',
    DegreeOfRotation: '00431041',
    DASTriggerSource: '00431042',
    DASFpaGain: '00431043',
    DASOutputSource: '00431044',
    DASAdInput: '00431045',
    DASCalMode: '00431046',
    DASCalFrequency: '00431047',
    DASRegXm: '00431048',
    DASAutoZero: '00431049',
    StartingChannelOfView: '0043104A',
    DASXmPattern: '0043104B',
    TGGCTriggerMode: '0043104C',
    StartScanToXrayOnDelay: '0043104D',
    DurationOfXrayOn: '0043104E',
    'SlopInt10-17': '00431060',
    ScannerStudyEntityUID: '00431061',
    ScannerStudyID: '00431062',
    ScannerTableEntry: '0043106f',
    ProductPackageIdentifier: '00440001',
    SubstanceAdministrationApproval: '00440002',
    ApprovalStatusFurtherDescription: '00440003',
    ApprovalStatusDateTime: '00440004',
    ProductTypeCodeSequence: '00440007',
    ProductName: '00440008',
    ProductDescription: '00440009',
    ProductLotIdentifier: '0044000A',
    ProductExpirationDateTime: '0044000B',
    SubstanceAdministrationDateTime: '00440010',
    SubstanceAdministrationNotes: '00440011',
    SubstanceAdministrationDeviceID: '00440012',
    ProductParameterSequence: '00440013',
    SubstanceAdminParameterSeq: '00440019',
    NumberOfMacroRowsInDetector: '00451001',
    MacroWidthAtISOCenter: '00451002',
    DASType: '00451003',
    DASGain: '00451004',
    DASTemperature: '00451005',
    TableDirectionInOrOut: '00451006',
    ZSmoothingFactor: '00451007',
    ViewWeightingMode: '00451008',
    SigmaRowNumberWhichRowsWereUsed: '00451009',
    MinimumDasValueFoundInTheScanData: '0045100A',
    MaximumOffsetShiftValueUsed: '0045100B',
    NumberOfViewsShifted: '0045100C',
    ZTrackingFlag: '0045100D',
    MeanZError: '0045100E',
    ZTrackingMaximumError: '0045100F',
    StartingViewForRow2a: '00451010',
    NumberOfViewsInRow2a: '00451011',
    StartingViewForRow1a: '00451012',
    SigmaMode: '00451013',
    NumberOfViewsInRow1a: '00451014',
    StartingViewForRow2b: '00451015',
    NumberOfViewsInRow2b: '00451016',
    StartingViewForRow1b: '00451017',
    NumberOfViewsInRow1b: '00451018',
    AirFilterCalibrationDate: '00451019',
    AirFilterCalibrationTime: '0045101A',
    PhantomCalibrationDate: '0045101B',
    PhantomCalibrationTime: '0045101C',
    ZSlopeCalibrationDate: '0045101D',
    ZSlopeCalibrationTime: '0045101E',
    CrosstalkCalibrationDate: '0045101F',
    CrosstalkCalibrationTime: '00451020',
    IterboneOptionFlag: '00451021',
    PeristalticFlagOption: '00451022',
    LensDescription: '00460012',
    RightLensSequence: '00460014',
    LeftLensSequence: '00460015',
    CylinderSequence: '00460018',
    PrismSequence: '00460028',
    HorizontalPrismPower: '00460030',
    HorizontalPrismBase: '00460032',
    VerticalPrismPower: '00460034',
    VerticalPrismBase: '00460036',
    LensSegmentType: '00460038',
    OpticalTransmittance: '00460040',
    ChannelWidth: '00460042',
    PupilSize: '00460044',
    CornealSize: '00460046',
    DistancePupillaryDistance: '00460060',
    NearPupillaryDistance: '00460062',
    OtherPupillaryDistance: '00460064',
    RadiusOfCurvature: '00460075',
    KeratometricPower: '00460076',
    KeratometricAxis: '00460077',
    BackgroundColor: '00460092',
    Optotype: '00460094',
    OptotypePresentation: '00460095',
    AddNearSequence: '00460100',
    AddIntermediateSequence: '00460101',
    AddOtherSequence: '00460102',
    AddPower: '00460104',
    ViewingDistance: '00460106',
    ViewingDistanceType: '00460125',
    VisualAcuityModifiers: '00460135',
    DecimalVisualAcuity: '00460137',
    OptotypeDetailedDefinition: '00460139',
    SpherePower: '00460146',
    CylinderPower: '00460147',
    CalibrationImage: '00500004',
    DeviceSequence: '00500010',
    DeviceLength: '00500014',
    ContainerComponentWidth: '00500015',
    DeviceDiameter: '00500016',
    DeviceDiameterUnits: '00500017',
    DeviceVolume: '00500018',
    InterMarkerDistance: '00500019',
    ContainerComponentID: '0050001B',
    DeviceDescription: '00500020',
    EnergyWindowVector: '00540010',
    NumberOfEnergyWindows: '00540011',
    EnergyWindowInformationSequence: '00540012',
    EnergyWindowRangeSequence: '00540013',
    EnergyWindowLowerLimit: '00540014',
    EnergyWindowUpperLimit: '00540015',
    RadiopharmaceuticalInformationSeq: '00540016',
    ResidualSyringeCounts: '00540017',
    EnergyWindowName: '00540018',
    DetectorVector: '00540020',
    NumberOfDetectors: '00540021',
    DetectorInformationSequence: '00540022',
    PhaseVector: '00540030',
    NumberOfPhases: '00540031',
    PhaseInformationSequence: '00540032',
    NumberOfFramesInPhase: '00540033',
    PhaseDelay: '00540036',
    PauseBetweenFrames: '00540038',
    PhaseDescription: '00540039',
    RotationVector: '00540050',
    NumberOfRotations: '00540051',
    RotationInformationSequence: '00540052',
    NumberOfFramesInRotation: '00540053',
    RRIntervalVector: '00540060',
    NumberOfRRIntervals: '00540061',
    GatedInformationSequence: '00540062',
    DataInformationSequence: '00540063',
    TimeSlotVector: '00540070',
    NumberOfTimeSlots: '00540071',
    TimeSlotInformationSequence: '00540072',
    TimeSlotTime: '00540073',
    SliceVector: '00540080',
    NumberOfSlices: '00540081',
    AngularViewVector: '00540090',
    TimeSliceVector: '00540100',
    NumberOfTimeSlices: '00540101',
    StartAngle: '00540200',
    TypeOfDetectorMotion: '00540202',
    TriggerVector: '00540210',
    NumberOfTriggersInPhase: '00540211',
    ViewCodeSequence: '00540220',
    ViewModifierCodeSequence: '00540222',
    RadionuclideCodeSequence: '00540300',
    AdministrationRouteCodeSequence: '00540302',
    RadiopharmaceuticalCodeSequence: '00540304',
    CalibrationDataSequence: '00540306',
    EnergyWindowNumber: '00540308',
    ImageID: '00540400',
    PatientOrientationCodeSequence: '00540410',
    PatientOrientationModifierCodeSeq: '00540412',
    PatientGantryRelationshipCodeSeq: '00540414',
    SliceProgressionDirection: '00540500',
    SeriesType: '00541000',
    Units: '00541001',
    CountsSource: '00541002',
    ReprojectionMethod: '00541004',
    RandomsCorrectionMethod: '00541100',
    AttenuationCorrectionMethod: '00541101',
    DecayCorrection: '00541102',
    ReconstructionMethod: '00541103',
    DetectorLinesOfResponseUsed: '00541104',
    ScatterCorrectionMethod: '00541105',
    AxialAcceptance: '00541200',
    AxialMash: '00541201',
    TransverseMash: '00541202',
    DetectorElementSize: '00541203',
    CoincidenceWindowWidth: '00541210',
    SecondaryCountsType: '00541220',
    FrameReferenceTime: '00541300',
    PrimaryCountsAccumulated: '00541310',
    SecondaryCountsAccumulated: '00541311',
    SliceSensitivityFactor: '00541320',
    DecayFactor: '00541321',
    DoseCalibrationFactor: '00541322',
    ScatterFractionFactor: '00541323',
    DeadTimeFactor: '00541324',
    ImageIndex: '00541330',
    CountsIncluded: '00541400',
    DeadTimeCorrectionFlag: '00541401',
    HistogramSequence: '00603000',
    HistogramNumberOfBins: '00603002',
    HistogramFirstBinValue: '00603004',
    HistogramLastBinValue: '00603006',
    HistogramBinWidth: '00603008',
    HistogramExplanation: '00603010',
    HistogramData: '00603020',
    SegmentationType: '00620001',
    SegmentSequence: '00620002',
    SegmentedPropertyCategoryCodeSeq: '00620003',
    SegmentLabel: '00620005',
    SegmentDescription: '00620006',
    SegmentAlgorithmType: '00620008',
    SegmentAlgorithmName: '00620009',
    SegmentIdentificationSequence: '0062000A',
    ReferencedSegmentNumber: '0062000B',
    RecommendedDisplayGrayscaleValue: '0062000C',
    RecommendedDisplayCIELabValue: '0062000D',
    MaximumFractionalValue: '0062000E',
    SegmentedPropertyTypeCodeSequence: '0062000F',
    SegmentationFractionalType: '00620010',
    DeformableRegistrationSequence: '00640002',
    SourceFrameOfReferenceUID: '00640003',
    DeformableRegistrationGridSequence: '00640005',
    GridDimensions: '00640007',
    GridResolution: '00640008',
    VectorGridData: '00640009',
    PreDeformationMatrixRegistSeq: '0064000F',
    PostDeformationMatrixRegistSeq: '00640010',
    NumberOfSurfaces: '00660001',
    SurfaceSequence: '00660002',
    SurfaceNumber: '00660003',
    SurfaceComments: '00660004',
    SurfaceProcessing: '00660009',
    SurfaceProcessingRatio: '0066000A',
    FiniteVolume: '0066000E',
    Manifold: '00660010',
    SurfacePointsSequence: '00660011',
    NumberOfSurfacePoints: '00660015',
    PointCoordinatesData: '00660016',
    PointPositionAccuracy: '00660017',
    MeanPointDistance: '00660018',
    MaximumPointDistance: '00660019',
    AxisOfRotation: '0066001B',
    CenterOfRotation: '0066001C',
    NumberOfVectors: '0066001E',
    VectorDimensionality: '0066001F',
    VectorAccuracy: '00660020',
    VectorCoordinateData: '00660021',
    TrianglePointIndexList: '00660023',
    EdgePointIndexList: '00660024',
    VertexPointIndexList: '00660025',
    TriangleStripSequence: '00660026',
    TriangleFanSequence: '00660027',
    LineSequence: '00660028',
    PrimitivePointIndexList: '00660029',
    SurfaceCount: '0066002A',
    AlgorithmFamilyCodeSequ: '0066002F',
    AlgorithmVersion: '00660031',
    AlgorithmParameters: '00660032',
    FacetSequence: '00660034',
    AlgorithmName: '00660036',
    GraphicAnnotationSequence: '00700001',
    GraphicLayer: '00700002',
    BoundingBoxAnnotationUnits: '00700003',
    AnchorPointAnnotationUnits: '00700004',
    GraphicAnnotationUnits: '00700005',
    UnformattedTextValue: '00700006',
    TextObjectSequence: '00700008',
    GraphicObjectSequence: '00700009',
    BoundingBoxTopLeftHandCorner: '00700010',
    BoundingBoxBottomRightHandCorner: '00700011',
    BoundingBoxTextHorizJustification: '00700012',
    AnchorPoint: '00700014',
    AnchorPointVisibility: '00700015',
    GraphicDimensions: '00700020',
    NumberOfGraphicPoints: '00700021',
    GraphicData: '00700022',
    GraphicType: '00700023',
    GraphicFilled: '00700024',
    ImageRotationRetired: '00700040',
    ImageHorizontalFlip: '00700041',
    ImageRotation: '00700042',
    DisplayedAreaTopLeftTrial: '00700050',
    DisplayedAreaBottomRightTrial: '00700051',
    DisplayedAreaTopLeft: '00700052',
    DisplayedAreaBottomRight: '00700053',
    DisplayedAreaSelectionSequence: '0070005A',
    GraphicLayerSequence: '00700060',
    GraphicLayerOrder: '00700062',
    GraphicLayerRecDisplayGraysclValue: '00700066',
    GraphicLayerRecDisplayRGBValue: '00700067',
    GraphicLayerDescription: '00700068',
    ContentLabel: '00700080',
    ContentDescription: '00700081',
    PresentationCreationDate: '00700082',
    PresentationCreationTime: '00700083',
    ContentCreatorName: '00700084',
    ContentCreatorIDCodeSequence: '00700086',
    PresentationSizeMode: '00700100',
    PresentationPixelSpacing: '00700101',
    PresentationPixelAspectRatio: '00700102',
    PresentationPixelMagRatio: '00700103',
    ShapeType: '00700306',
    RegistrationSequence: '00700308',
    MatrixRegistrationSequence: '00700309',
    MatrixSequence: '0070030A',
    FrameOfRefTransformationMatrixType: '0070030C',
    RegistrationTypeCodeSequence: '0070030D',
    FiducialDescription: '0070030F',
    FiducialIdentifier: '00700310',
    FiducialIdentifierCodeSequence: '00700311',
    ContourUncertaintyRadius: '00700312',
    UsedFiducialsSequence: '00700314',
    GraphicCoordinatesDataSequence: '00700318',
    FiducialUID: '0070031A',
    FiducialSetSequence: '0070031C',
    FiducialSequence: '0070031E',
    GraphicLayerRecomDisplayCIELabVal: '00700401',
    BlendingSequence: '00700402',
    RelativeOpacity: '00700403',
    ReferencedSpatialRegistrationSeq: '00700404',
    BlendingPosition: '00700405',
    HangingProtocolName: '00720002',
    HangingProtocolDescription: '00720004',
    HangingProtocolLevel: '00720006',
    HangingProtocolCreator: '00720008',
    HangingProtocolCreationDateTime: '0072000A',
    HangingProtocolDefinitionSequence: '0072000C',
    HangingProtocolUserIDCodeSequence: '0072000E',
    HangingProtocolUserGroupName: '00720010',
    SourceHangingProtocolSequence: '00720012',
    NumberOfPriorsReferenced: '00720014',
    ImageSetsSequence: '00720020',
    ImageSetSelectorSequence: '00720022',
    ImageSetSelectorUsageFlag: '00720024',
    SelectorAttribute: '00720026',
    SelectorValueNumber: '00720028',
    TimeBasedImageSetsSequence: '00720030',
    ImageSetNumber: '00720032',
    ImageSetSelectorCategory: '00720034',
    RelativeTime: '00720038',
    RelativeTimeUnits: '0072003A',
    AbstractPriorValue: '0072003C',
    AbstractPriorCodeSequence: '0072003E',
    ImageSetLabel: '00720040',
    SelectorAttributeVR: '00720050',
    SelectorSequencePointer: '00720052',
    SelectorSeqPointerPrivateCreator: '00720054',
    SelectorAttributePrivateCreator: '00720056',
    SelectorATValue: '00720060',
    SelectorCSValue: '00720062',
    SelectorISValue: '00720064',
    SelectorLOValue: '00720066',
    SelectorLTValue: '00720068',
    SelectorPNValue: '0072006A',
    SelectorSHValue: '0072006C',
    SelectorSTValue: '0072006E',
    SelectorUTValue: '00720070',
    SelectorDSValue: '00720072',
    SelectorFDValue: '00720074',
    SelectorFLValue: '00720076',
    SelectorULValue: '00720078',
    SelectorUSValue: '0072007A',
    SelectorSLValue: '0072007C',
    SelectorSSValue: '0072007E',
    SelectorCodeSequenceValue: '00720080',
    NumberOfScreens: '00720100',
    NominalScreenDefinitionSequence: '00720102',
    NumberOfVerticalPixels: '00720104',
    NumberOfHorizontalPixels: '00720106',
    DisplayEnvironmentSpatialPosition: '00720108',
    ScreenMinimumGrayscaleBitDepth: '0072010A',
    ScreenMinimumColorBitDepth: '0072010C',
    ApplicationMaximumRepaintTime: '0072010E',
    DisplaySetsSequence: '00720200',
    DisplaySetNumber: '00720202',
    DisplaySetLabel: '00720203',
    DisplaySetPresentationGroup: '00720204',
    DisplaySetPresentationGroupDescr: '00720206',
    PartialDataDisplayHandling: '00720208',
    SynchronizedScrollingSequence: '00720210',
    DisplaySetScrollingGroup: '00720212',
    NavigationIndicatorSequence: '00720214',
    NavigationDisplaySet: '00720216',
    ReferenceDisplaySets: '00720218',
    ImageBoxesSequence: '00720300',
    ImageBoxNumber: '00720302',
    ImageBoxLayoutType: '00720304',
    ImageBoxTileHorizontalDimension: '00720306',
    ImageBoxTileVerticalDimension: '00720308',
    ImageBoxScrollDirection: '00720310',
    ImageBoxSmallScrollType: '00720312',
    ImageBoxSmallScrollAmount: '00720314',
    ImageBoxLargeScrollType: '00720316',
    ImageBoxLargeScrollAmount: '00720318',
    ImageBoxOverlapPriority: '00720320',
    CineRelativeToRealTime: '00720330',
    FilterOperationsSequence: '00720400',
    FilterByCategory: '00720402',
    FilterByAttributePresence: '00720404',
    FilterByOperator: '00720406',
    SynchronizedImageBoxList: '00720432',
    TypeOfSynchronization: '00720434',
    BlendingOperationType: '00720500',
    ReformattingOperationType: '00720510',
    ReformattingThickness: '00720512',
    ReformattingInterval: '00720514',
    ReformattingOpInitialViewDir: '00720516',
    RenderingType3D: '00720520',
    SortingOperationsSequence: '00720600',
    SortByCategory: '00720602',
    SortingDirection: '00720604',
    DisplaySetPatientOrientation: '00720700',
    VOIType: '00720702',
    PseudoColorType: '00720704',
    ShowGrayscaleInverted: '00720706',
    ShowImageTrueSizeFlag: '00720710',
    ShowGraphicAnnotationFlag: '00720712',
    ShowPatientDemographicsFlag: '00720714',
    ShowAcquisitionTechniquesFlag: '00720716',
    DisplaySetHorizontalJustification: '00720717',
    DisplaySetVerticalJustification: '00720718',
    UnifiedProcedureStepState: '00741000',
    UPSProgressInformationSequence: '00741002',
    UnifiedProcedureStepProgress: '00741004',
    UnifiedProcedureStepProgressDescr: '00741006',
    UnifiedProcedureStepComURISeq: '00741008',
    ContactURI: '0074100a',
    ContactDisplayName: '0074100c',
    BeamTaskSequence: '00741020',
    BeamTaskType: '00741022',
    BeamOrderIndex: '00741024',
    DeliveryVerificationImageSequence: '00741030',
    VerificationImageTiming: '00741032',
    DoubleExposureFlag: '00741034',
    DoubleExposureOrdering: '00741036',
    DoubleExposureMeterset: '00741038',
    DoubleExposureFieldDelta: '0074103A',
    RelatedReferenceRTImageSequence: '00741040',
    GeneralMachineVerificationSequence: '00741042',
    ConventionalMachineVerificationSeq: '00741044',
    IonMachineVerificationSequence: '00741046',
    FailedAttributesSequence: '00741048',
    OverriddenAttributesSequence: '0074104A',
    ConventionalControlPointVerifySeq: '0074104C',
    IonControlPointVerificationSeq: '0074104E',
    AttributeOccurrenceSequence: '00741050',
    AttributeOccurrencePointer: '00741052',
    AttributeItemSelector: '00741054',
    AttributeOccurrencePrivateCreator: '00741056',
    ScheduledProcedureStepPriority: '00741200',
    WorklistLabel: '00741202',
    ProcedureStepLabel: '00741204',
    ScheduledProcessingParametersSeq: '00741210',
    PerformedProcessingParametersSeq: '00741212',
    UPSPerformedProcedureSequence: '00741216',
    RelatedProcedureStepSequence: '00741220',
    ProcedureStepRelationshipType: '00741222',
    DeletionLock: '00741230',
    ReceivingAE: '00741234',
    RequestingAE: '00741236',
    ReasonForCancellation: '00741238',
    SCPStatus: '00741242',
    SubscriptionListStatus: '00741244',
    UPSListStatus: '00741246',
    StorageMediaFileSetID: '00880130',
    StorageMediaFileSetUID: '00880140',
    IconImageSequence: '00880200',
    TopicTitle: '00880904',
    TopicSubject: '00880906',
    TopicAuthor: '00880910',
    TopicKeywords: '00880912',
    SOPInstanceStatus: '01000410',
    SOPAuthorizationDateAndTime: '01000420',
    SOPAuthorizationComment: '01000424',
    AuthorizationEquipmentCertNumber: '01000426',
    MACIDNumber: '04000005',
    MACCalculationTransferSyntaxUID: '04000010',
    MACAlgorithm: '04000015',
    DataElementsSigned: '04000020',
    DigitalSignatureUID: '04000100',
    DigitalSignatureDateTime: '04000105',
    CertificateType: '04000110',
    CertificateOfSigner: '04000115',
    Signature: '04000120',
    CertifiedTimestampType: '04000305',
    CertifiedTimestamp: '04000310',
    DigitalSignaturePurposeCodeSeq: '04000401',
    ReferencedDigitalSignatureSeq: '04000402',
    ReferencedSOPInstanceMACSeq: '04000403',
    MAC: '04000404',
    EncryptedAttributesSequence: '04000500',
    EncryptedContentTransferSyntaxUID: '04000510',
    EncryptedContent: '04000520',
    ModifiedAttributesSequence: '04000550',
    OriginalAttributesSequence: '04000561',
    AttributeModificationDateTime: '04000562',
    ModifyingSystem: '04000563',
    SourceOfPreviousValues: '04000564',
    ReasonForTheAttributeModification: '04000565',
    EscapeTriplet: '1000...0',
    RunLengthTriplet: '1000...1',
    HuffmanTableSize: '1000...2',
    HuffmanTableTriplet: '1000...3',
    ShiftTableSize: '1000...4',
    ShiftTableTriplet: '1000...5',
    ZonalMap: '1010....',
    NumberOfCopies: '20000010',
    PrinterConfigurationSequence: '2000001E',
    PrintPriority: '20000020',
    MediumType: '20000030',
    FilmDestination: '20000040',
    FilmSessionLabel: '20000050',
    MemoryAllocation: '20000060',
    MaximumMemoryAllocation: '20000061',
    ColorImagePrintingFlag: '20000062',
    CollationFlag: '20000063',
    AnnotationFlag: '20000065',
    ImageOverlayFlag: '20000067',
    PresentationLUTFlag: '20000069',
    ImageBoxPresentationLUTFlag: '2000006A',
    MemoryBitDepth: '200000A0',
    PrintingBitDepth: '200000A1',
    MediaInstalledSequence: '200000A2',
    OtherMediaAvailableSequence: '200000A4',
    SupportedImageDisplayFormatSeq: '200000A8',
    ReferencedFilmBoxSequence: '20000500',
    ReferencedStoredPrintSequence: '20000510',
    ImageDisplayFormat: '20100010',
    AnnotationDisplayFormatID: '20100030',
    FilmOrientation: '20100040',
    FilmSizeID: '20100050',
    PrinterResolutionID: '20100052',
    DefaultPrinterResolutionID: '20100054',
    MagnificationType: '20100060',
    SmoothingType: '20100080',
    DefaultMagnificationType: '201000A6',
    OtherMagnificationTypesAvailable: '201000A7',
    DefaultSmoothingType: '201000A8',
    OtherSmoothingTypesAvailable: '201000A9',
    BorderDensity: '20100100',
    EmptyImageDensity: '20100110',
    MinDensity: '20100120',
    MaxDensity: '20100130',
    Trim: '20100140',
    ConfigurationInformation: '20100150',
    ConfigurationInformationDescr: '20100152',
    MaximumCollatedFilms: '20100154',
    Illumination: '2010015E',
    ReflectedAmbientLight: '20100160',
    PrinterPixelSpacing: '20100376',
    ReferencedFilmSessionSequence: '20100500',
    ReferencedImageBoxSequence: '20400500',
    ReferencedBasicAnnotationBoxSeq: '20100520',
    ImageBoxPosition: '20200010',
    Polarity: '20200020',
    RequestedImageSize: '20200030',
    'RequestedDecimate-CropBehavior': '20200040',
    RequestedResolutionID: '20200050',
    RequestedImageSizeFlag: '202000A0',
    DecimateCropResult: '202000A2',
    BasicGrayscaleImageSequence: '20200110',
    BasicColorImageSequence: '20200111',
    ReferencedImageOverlayBoxSequence: '20200130',
    ReferencedVOILUTBoxSequence: '20200140',
    AnnotationPosition: '20300010',
    TextString: '20300020',
    ReferencedOverlayPlaneSequence: '20400010',
    ReferencedOverlayPlaneGroups: '20400011',
    OverlayPixelDataSequence: '20400020',
    OverlayMagnificationType: '20400060',
    OverlaySmoothingType: '20400070',
    OverlayOrImageMagnification: '20400072',
    MagnifyToNumberOfColumns: '20400074',
    OverlayForegroundDensity: '20400080',
    OverlayBackgroundDensity: '20400082',
    OverlayMode: '20400090',
    ThresholdDensity: '20400100',
    PresentationLUTSequence: '20500010',
    PresentationLUTShape: '20500020',
    ReferencedPresentationLUTSequence: '20500500',
    PrintJobID: '21000010',
    ExecutionStatus: '21000020',
    ExecutionStatusInfo: '21000030',
    CreationDate: '21000040',
    CreationTime: '21000050',
    Originator: '21000070',
    DestinationAE: '21000140',
    OwnerID: '21000160',
    NumberOfFilms: '21000170',
    ReferencedPrintJobSequence: '21200070',
    PrinterStatus: '21100010',
    PrinterStatusInfo: '21100020',
    PrinterName: '21100030',
    PrintQueueID: '21100099',
    QueueStatus: '21200010',
    PrintJobDescriptionSequence: '21200050',
    PrintManagementCapabilitiesSeq: '21300010',
    PrinterCharacteristicsSequence: '21300015',
    FilmBoxContentSequence: '21300030',
    ImageBoxContentSequence: '21300040',
    AnnotationContentSequence: '21300050',
    ImageOverlayBoxContentSequence: '21300060',
    PresentationLUTContentSequence: '21300080',
    ProposedStudySequence: '213000A0',
    OriginalImageSequence: '213000C0',
    LabelFromInfoExtractedFromInstance: '22000001',
    LabelText: '22000002',
    LabelStyleSelection: '22000003',
    MediaDisposition: '22000004',
    BarcodeValue: '22000005',
    BarcodeSymbology: '22000006',
    AllowMediaSplitting: '22000007',
    IncludeNonDICOMObjects: '22000008',
    IncludeDisplayApplication: '22000009',
    SaveCompInstancesAfterMediaCreate: '2200000A',
    TotalNumberMediaPiecesCreated: '2200000B',
    RequestedMediaApplicationProfile: '2200000C',
    ReferencedStorageMediaSequence: '2200000D',
    FailureAttributes: '2200000E',
    AllowLossyCompression: '2200000F',
    RequestPriority: '22000020',
    RTImageLabel: '30020002',
    RTImageName: '30020003',
    RTImageDescription: '30020004',
    ReportedValuesOrigin: '3002000A',
    RTImagePlane: '3002000C',
    XRayImageReceptorTranslation: '3002000D',
    XRayImageReceptorAngle: '3002000E',
    RTImageOrientation: '30020010',
    ImagePlanePixelSpacing: '30020011',
    RTImagePosition: '30020012',
    RadiationMachineName: '30020020',
    RadiationMachineSAD: '30020022',
    RadiationMachineSSD: '30020024',
    RTImageSID: '30020026',
    SourceToReferenceObjectDistance: '30020028',
    FractionNumber: '30020029',
    ExposureSequence: '30020030',
    MetersetExposure: '30020032',
    DiaphragmPosition: '30020034',
    FluenceMapSequence: '30020040',
    FluenceDataSource: '30020041',
    FluenceDataScale: '30020042',
    FluenceMode: '30020051',
    FluenceModeID: '30020052',
    DVHType: '30040001',
    DoseUnits: '30040002',
    DoseType: '30040004',
    DoseComment: '30040006',
    NormalizationPoint: '30040008',
    DoseSummationType: '3004000A',
    GridFrameOffsetVector: '3004000C',
    DoseGridScaling: '3004000E',
    RTDoseROISequence: '30040010',
    DoseValue: '30040012',
    TissueHeterogeneityCorrection: '30040014',
    DVHNormalizationPoint: '30040040',
    DVHNormalizationDoseValue: '30040042',
    DVHSequence: '30040050',
    DVHDoseScaling: '30040052',
    DVHVolumeUnits: '30040054',
    DVHNumberOfBins: '30040056',
    DVHData: '30040058',
    DVHReferencedROISequence: '30040060',
    DVHROIContributionType: '30040062',
    DVHMinimumDose: '30040070',
    DVHMaximumDose: '30040072',
    DVHMeanDose: '30040074',
    StructureSetLabel: '30060002',
    StructureSetName: '30060004',
    StructureSetDescription: '30060006',
    StructureSetDate: '30060008',
    StructureSetTime: '30060009',
    ReferencedFrameOfReferenceSequence: '30060010',
    RTReferencedStudySequence: '30060012',
    RTReferencedSeriesSequence: '30060014',
    ContourImageSequence: '30060016',
    StructureSetROISequence: '30060020',
    ROINumber: '30060022',
    ReferencedFrameOfReferenceUID: '30060024',
    ROIName: '30060026',
    ROIDescription: '30060028',
    ROIDisplayColor: '3006002A',
    ROIVolume: '3006002C',
    RTRelatedROISequence: '30060030',
    RTROIRelationship: '30060033',
    ROIGenerationAlgorithm: '30060036',
    ROIGenerationDescription: '30060038',
    ROIContourSequence: '30060039',
    ContourSequence: '30060040',
    ContourGeometricType: '30060042',
    ContourSlabThickness: '30060044',
    ContourOffsetVector: '30060045',
    NumberOfContourPoints: '30060046',
    ContourNumber: '30060048',
    AttachedContours: '30060049',
    ContourData: '30060050',
    RTROIObservationsSequence: '30060080',
    ObservationNumber: '30060082',
    ReferencedROINumber: '30060084',
    ROIObservationLabel: '30060085',
    RTROIIdentificationCodeSequence: '30060086',
    ROIObservationDescription: '30060088',
    RelatedRTROIObservationsSequence: '300600A0',
    RTROIInterpretedType: '300600A4',
    ROIInterpreter: '300600A6',
    ROIPhysicalPropertiesSequence: '300600B0',
    ROIPhysicalProperty: '300600B2',
    ROIPhysicalPropertyValue: '300600B4',
    ROIElementalCompositionSequence: '300600B6',
    ROIElementalCompAtomicNumber: '300600B7',
    ROIElementalCompAtomicMassFraction: '300600B8',
    FrameOfReferenceRelationshipSeq: '300600C0',
    RelatedFrameOfReferenceUID: '300600C2',
    FrameOfReferenceTransformType: '300600C4',
    FrameOfReferenceTransformMatrix: '300600C6',
    FrameOfReferenceTransformComment: '300600C8',
    MeasuredDoseReferenceSequence: '30080010',
    MeasuredDoseDescription: '30080012',
    MeasuredDoseType: '30080014',
    MeasuredDoseValue: '30080016',
    TreatmentSessionBeamSequence: '30080020',
    TreatmentSessionIonBeamSequence: '30080021',
    CurrentFractionNumber: '30080022',
    TreatmentControlPointDate: '30080024',
    TreatmentControlPointTime: '30080025',
    TreatmentTerminationStatus: '3008002A',
    TreatmentTerminationCode: '3008002B',
    TreatmentVerificationStatus: '3008002C',
    ReferencedTreatmentRecordSequence: '30080030',
    SpecifiedPrimaryMeterset: '30080032',
    SpecifiedSecondaryMeterset: '30080033',
    DeliveredPrimaryMeterset: '30080036',
    DeliveredSecondaryMeterset: '30080037',
    SpecifiedTreatmentTime: '3008003A',
    DeliveredTreatmentTime: '3008003B',
    ControlPointDeliverySequence: '30080040',
    IonControlPointDeliverySequence: '30080041',
    SpecifiedMeterset: '30080042',
    DeliveredMeterset: '30080044',
    MetersetRateSet: '30080045',
    MetersetRateDelivered: '30080046',
    ScanSpotMetersetsDelivered: '30080047',
    DoseRateDelivered: '30080048',
    TreatmentSummaryCalcDoseRefSeq: '30080050',
    CumulativeDoseToDoseReference: '30080052',
    FirstTreatmentDate: '30080054',
    MostRecentTreatmentDate: '30080056',
    NumberOfFractionsDelivered: '3008005A',
    OverrideSequence: '30080060',
    ParameterSequencePointer: '30080061',
    OverrideParameterPointer: '30080062',
    ParameterItemIndex: '30080063',
    MeasuredDoseReferenceNumber: '30080064',
    ParameterPointer: '30080065',
    OverrideReason: '30080066',
    CorrectedParameterSequence: '30080068',
    CorrectionValue: '3008006A',
    CalculatedDoseReferenceSequence: '30080070',
    CalculatedDoseReferenceNumber: '30080072',
    CalculatedDoseReferenceDescription: '30080074',
    CalculatedDoseReferenceDoseValue: '30080076',
    StartMeterset: '30080078',
    EndMeterset: '3008007A',
    ReferencedMeasuredDoseReferenceSeq: '30080080',
    ReferencedMeasuredDoseReferenceNum: '30080082',
    ReferencedCalculatedDoseRefSeq: '30080090',
    ReferencedCalculatedDoseRefNumber: '30080092',
    BeamLimitingDeviceLeafPairsSeq: '300800A0',
    RecordedWedgeSequence: '300800B0',
    RecordedCompensatorSequence: '300800C0',
    RecordedBlockSequence: '300800D0',
    TreatmentSummaryMeasuredDoseRefSeq: '300800E0',
    RecordedSnoutSequence: '300800F0',
    RecordedRangeShifterSequence: '300800F2',
    RecordedLateralSpreadingDeviceSeq: '300800F4',
    RecordedRangeModulatorSequence: '300800F6',
    RecordedSourceSequence: '30080100',
    SourceSerialNumber: '30080105',
    TreatmentSessionAppSetupSeq: '30080110',
    ApplicationSetupCheck: '30080116',
    RecordedBrachyAccessoryDeviceSeq: '30080120',
    ReferencedBrachyAccessoryDeviceNum: '30080122',
    RecordedChannelSequence: '30080130',
    SpecifiedChannelTotalTime: '30080132',
    DeliveredChannelTotalTime: '30080134',
    SpecifiedNumberOfPulses: '30080136',
    DeliveredNumberOfPulses: '30080138',
    SpecifiedPulseRepetitionInterval: '3008013A',
    DeliveredPulseRepetitionInterval: '3008013C',
    RecordedSourceApplicatorSequence: '30080140',
    ReferencedSourceApplicatorNumber: '30080142',
    RecordedChannelShieldSequence: '30080150',
    ReferencedChannelShieldNumber: '30080152',
    BrachyControlPointDeliveredSeq: '30080160',
    SafePositionExitDate: '30080162',
    SafePositionExitTime: '30080164',
    SafePositionReturnDate: '30080166',
    SafePositionReturnTime: '30080168',
    CurrentTreatmentStatus: '30080200',
    TreatmentStatusComment: '30080202',
    FractionGroupSummarySequence: '30080220',
    ReferencedFractionNumber: '30080223',
    FractionGroupType: '30080224',
    BeamStopperPosition: '30080230',
    FractionStatusSummarySequence: '30080240',
    TreatmentDate: '30080250',
    TreatmentTime: '30080251',
    RTPlanLabel: '300A0002',
    RTPlanName: '300A0003',
    RTPlanDescription: '300A0004',
    RTPlanDate: '300A0006',
    RTPlanTime: '300A0007',
    TreatmentProtocols: '300A0009',
    PlanIntent: '300A000A',
    TreatmentSites: '300A000B',
    RTPlanGeometry: '300A000C',
    PrescriptionDescription: '300A000E',
    DoseReferenceSequence: '300A0010',
    DoseReferenceNumber: '300A0012',
    DoseReferenceUID: '300A0013',
    DoseReferenceStructureType: '300A0014',
    NominalBeamEnergyUnit: '300A0015',
    DoseReferenceDescription: '300A0016',
    DoseReferencePointCoordinates: '300A0018',
    NominalPriorDose: '300A001A',
    DoseReferenceType: '300A0020',
    ConstraintWeight: '300A0021',
    DeliveryWarningDose: '300A0022',
    DeliveryMaximumDose: '300A0023',
    TargetMinimumDose: '300A0025',
    TargetPrescriptionDose: '300A0026',
    TargetMaximumDose: '300A0027',
    TargetUnderdoseVolumeFraction: '300A0028',
    OrganAtRiskFullVolumeDose: '300A002A',
    OrganAtRiskLimitDose: '300A002B',
    OrganAtRiskMaximumDose: '300A002C',
    OrganAtRiskOverdoseVolumeFraction: '300A002D',
    ToleranceTableSequence: '300A0040',
    ToleranceTableNumber: '300A0042',
    ToleranceTableLabel: '300A0043',
    GantryAngleTolerance: '300A0044',
    BeamLimitingDeviceAngleTolerance: '300A0046',
    BeamLimitingDeviceToleranceSeq: '300A0048',
    BeamLimitingDevicePositionTol: '300A004A',
    SnoutPositionTolerance: '300A004B',
    PatientSupportAngleTolerance: '300A004C',
    TableTopEccentricAngleTolerance: '300A004E',
    TableTopPitchAngleTolerance: '300A004F',
    TableTopRollAngleTolerance: '300A0050',
    TableTopVerticalPositionTolerance: '300A0051',
    TableTopLongitudinalPositionTol: '300A0052',
    TableTopLateralPositionTolerance: '300A0053',
    RTPlanRelationship: '300A0055',
    FractionGroupSequence: '300A0070',
    FractionGroupNumber: '300A0071',
    FractionGroupDescription: '300A0072',
    NumberOfFractionsPlanned: '300A0078',
    NumberFractionPatternDigitsPerDay: '300A0079',
    RepeatFractionCycleLength: '300A007A',
    FractionPattern: '300A007B',
    NumberOfBeams: '300A0080',
    BeamDoseSpecificationPoint: '300A0082',
    BeamDose: '300A0084',
    BeamMeterset: '300A0086',
    BeamDosePointDepth: '300A0088',
    BeamDosePointEquivalentDepth: '300A0089',
    BeamDosePointSSD: '300A008A',
    NumberOfBrachyApplicationSetups: '300A00A0',
    BrachyAppSetupDoseSpecPoint: '300A00A2',
    BrachyApplicationSetupDose: '300A00A4',
    BeamSequence: '300A00B0',
    TreatmentMachineName: '300A00B2',
    PrimaryDosimeterUnit: '300A00B3',
    SourceAxisDistance: '300A00B4',
    BeamLimitingDeviceSequence: '300A00B6',
    RTBeamLimitingDeviceType: '300A00B8',
    SourceToBeamLimitingDeviceDistance: '300A00BA',
    IsocenterToBeamLimitingDeviceDist: '300A00BB',
    NumberOfLeafJawPairs: '300A00BC',
    LeafPositionBoundaries: '300A00BE',
    BeamNumber: '300A00C0',
    BeamName: '300A00C2',
    BeamDescription: '300A00C3',
    BeamType: '300A00C4',
    RadiationType: '300A00C6',
    HighDoseTechniqueType: '300A00C7',
    ReferenceImageNumber: '300A00C8',
    PlannedVerificationImageSequence: '300A00CA',
    ImagingDeviceSpecificAcqParams: '300A00CC',
    TreatmentDeliveryType: '300A00CE',
    NumberOfWedges: '300A00D0',
    WedgeSequence: '300A00D1',
    WedgeNumber: '300A00D2',
    WedgeType: '300A00D3',
    WedgeID: '300A00D4',
    WedgeAngle: '300A00D5',
    WedgeFactor: '300A00D6',
    TotalWedgeTrayWaterEquivThickness: '300A00D7',
    WedgeOrientation: '300A00D8',
    IsocenterToWedgeTrayDistance: '300A00D9',
    SourceToWedgeTrayDistance: '300A00DA',
    WedgeThinEdgePosition: '300A00DB',
    BolusID: '300A00DC',
    BolusDescription: '300A00DD',
    NumberOfCompensators: '300A00E0',
    MaterialID: '300A00E1',
    TotalCompensatorTrayFactor: '300A00E2',
    CompensatorSequence: '300A00E3',
    CompensatorNumber: '300A00E4',
    CompensatorID: '300A00E5',
    SourceToCompensatorTrayDistance: '300A00E6',
    CompensatorRows: '300A00E7',
    CompensatorColumns: '300A00E8',
    CompensatorPixelSpacing: '300A00E9',
    CompensatorPosition: '300A00EA',
    CompensatorTransmissionData: '300A00EB',
    CompensatorThicknessData: '300A00EC',
    NumberOfBoli: '300A00ED',
    CompensatorType: '300A00EE',
    NumberOfBlocks: '300A00F0',
    TotalBlockTrayFactor: '300A00F2',
    TotalBlockTrayWaterEquivThickness: '300A00F3',
    BlockSequence: '300A00F4',
    BlockTrayID: '300A00F5',
    SourceToBlockTrayDistance: '300A00F6',
    IsocenterToBlockTrayDistance: '300A00F7',
    BlockType: '300A00F8',
    AccessoryCode: '300A00F9',
    BlockDivergence: '300A00FA',
    BlockMountingPosition: '300A00FB',
    BlockNumber: '300A00FC',
    BlockName: '300A00FE',
    BlockThickness: '300A0100',
    BlockTransmission: '300A0102',
    BlockNumberOfPoints: '300A0104',
    BlockData: '300A0106',
    ApplicatorSequence: '300A0107',
    ApplicatorID: '300A0108',
    ApplicatorType: '300A0109',
    ApplicatorDescription: '300A010A',
    CumulativeDoseReferenceCoefficient: '300A010C',
    FinalCumulativeMetersetWeight: '300A010E',
    NumberOfControlPoints: '300A0110',
    ControlPointSequence: '300A0111',
    ControlPointIndex: '300A0112',
    NominalBeamEnergy: '300A0114',
    DoseRateSet: '300A0115',
    WedgePositionSequence: '300A0116',
    WedgePosition: '300A0118',
    BeamLimitingDevicePositionSequence: '300A011A',
    LeafJawPositions: '300A011C',
    GantryAngle: '300A011E',
    GantryRotationDirection: '300A011F',
    BeamLimitingDeviceAngle: '300A0120',
    BeamLimitingDeviceRotateDirection: '300A0121',
    PatientSupportAngle: '300A0122',
    PatientSupportRotationDirection: '300A0123',
    TableTopEccentricAxisDistance: '300A0124',
    TableTopEccentricAngle: '300A0125',
    TableTopEccentricRotateDirection: '300A0126',
    TableTopVerticalPosition: '300A0128',
    TableTopLongitudinalPosition: '300A0129',
    TableTopLateralPosition: '300A012A',
    IsocenterPosition: '300A012C',
    SurfaceEntryPoint: '300A012E',
    SourceToSurfaceDistance: '300A0130',
    CumulativeMetersetWeight: '300A0134',
    TableTopPitchAngle: '300A0140',
    TableTopPitchRotationDirection: '300A0142',
    TableTopRollAngle: '300A0144',
    TableTopRollRotationDirection: '300A0146',
    HeadFixationAngle: '300A0148',
    GantryPitchAngle: '300A014A',
    GantryPitchRotationDirection: '300A014C',
    GantryPitchAngleTolerance: '300A014E',
    PatientSetupSequence: '300A0180',
    PatientSetupNumber: '300A0182',
    PatientSetupLabel: '300A0183',
    PatientAdditionalPosition: '300A0184',
    FixationDeviceSequence: '300A0190',
    FixationDeviceType: '300A0192',
    FixationDeviceLabel: '300A0194',
    FixationDeviceDescription: '300A0196',
    FixationDevicePosition: '300A0198',
    FixationDevicePitchAngle: '300A0199',
    FixationDeviceRollAngle: '300A019A',
    ShieldingDeviceSequence: '300A01A0',
    ShieldingDeviceType: '300A01A2',
    ShieldingDeviceLabel: '300A01A4',
    ShieldingDeviceDescription: '300A01A6',
    ShieldingDevicePosition: '300A01A8',
    SetupTechnique: '300A01B0',
    SetupTechniqueDescription: '300A01B2',
    SetupDeviceSequence: '300A01B4',
    SetupDeviceType: '300A01B6',
    SetupDeviceLabel: '300A01B8',
    SetupDeviceDescription: '300A01BA',
    SetupDeviceParameter: '300A01BC',
    SetupReferenceDescription: '300A01D0',
    TableTopVerticalSetupDisplacement: '300A01D2',
    TableTopLongitudinalSetupDisplace: '300A01D4',
    TableTopLateralSetupDisplacement: '300A01D6',
    BrachyTreatmentTechnique: '300A0200',
    BrachyTreatmentType: '300A0202',
    TreatmentMachineSequence: '300A0206',
    SourceSequence: '300A0210',
    SourceNumber: '300A0212',
    SourceType: '300A0214',
    SourceManufacturer: '300A0216',
    ActiveSourceDiameter: '300A0218',
    ActiveSourceLength: '300A021A',
    SourceEncapsulationNomThickness: '300A0222',
    SourceEncapsulationNomTransmission: '300A0224',
    SourceIsotopeName: '300A0226',
    SourceIsotopeHalfLife: '300A0228',
    SourceStrengthUnits: '300A0229',
    ReferenceAirKermaRate: '300A022A',
    SourceStrength: '300A022B',
    SourceStrengthReferenceDate: '300A022C',
    SourceStrengthReferenceTime: '300A022E',
    ApplicationSetupSequence: '300A0230',
    ApplicationSetupType: '300A0232',
    ApplicationSetupNumber: '300A0234',
    ApplicationSetupName: '300A0236',
    ApplicationSetupManufacturer: '300A0238',
    TemplateNumber: '300A0240',
    TemplateType: '300A0242',
    TemplateName: '300A0244',
    TotalReferenceAirKerma: '300A0250',
    BrachyAccessoryDeviceSequence: '300A0260',
    BrachyAccessoryDeviceNumber: '300A0262',
    BrachyAccessoryDeviceID: '300A0263',
    BrachyAccessoryDeviceType: '300A0264',
    BrachyAccessoryDeviceName: '300A0266',
    BrachyAccessoryDeviceNomThickness: '300A026A',
    BrachyAccessoryDevNomTransmission: '300A026C',
    ChannelSequence: '300A0280',
    ChannelNumber: '300A0282',
    ChannelLength: '300A0284',
    ChannelTotalTime: '300A0286',
    SourceMovementType: '300A0288',
    NumberOfPulses: '300A028A',
    PulseRepetitionInterval: '300A028C',
    SourceApplicatorNumber: '300A0290',
    SourceApplicatorID: '300A0291',
    SourceApplicatorType: '300A0292',
    SourceApplicatorName: '300A0294',
    SourceApplicatorLength: '300A0296',
    SourceApplicatorManufacturer: '300A0298',
    SourceApplicatorWallNomThickness: '300A029C',
    SourceApplicatorWallNomTrans: '300A029E',
    SourceApplicatorStepSize: '300A02A0',
    TransferTubeNumber: '300A02A2',
    TransferTubeLength: '300A02A4',
    ChannelShieldSequence: '300A02B0',
    ChannelShieldNumber: '300A02B2',
    ChannelShieldID: '300A02B3',
    ChannelShieldName: '300A02B4',
    ChannelShieldNominalThickness: '300A02B8',
    ChannelShieldNominalTransmission: '300A02BA',
    FinalCumulativeTimeWeight: '300A02C8',
    BrachyControlPointSequence: '300A02D0',
    ControlPointRelativePosition: '300A02D2',
    ControlPoint3DPosition: '300A02D4',
    CumulativeTimeWeight: '300A02D6',
    CompensatorDivergence: '300A02E0',
    CompensatorMountingPosition: '300A02E1',
    SourceToCompensatorDistance: '300A02E2',
    TotalCompTrayWaterEquivThickness: '300A02E3',
    IsocenterToCompensatorTrayDistance: '300A02E4',
    CompensatorColumnOffset: '300A02E5',
    IsocenterToCompensatorDistances: '300A02E6',
    CompensatorRelStoppingPowerRatio: '300A02E7',
    CompensatorMillingToolDiameter: '300A02E8',
    IonRangeCompensatorSequence: '300A02EA',
    CompensatorDescription: '300A02EB',
    RadiationMassNumber: '300A0302',
    RadiationAtomicNumber: '300A0304',
    RadiationChargeState: '300A0306',
    ScanMode: '300A0308',
    VirtualSourceAxisDistances: '300A030A',
    SnoutSequence: '300A030C',
    SnoutPosition: '300A030D',
    SnoutID: '300A030F',
    NumberOfRangeShifters: '300A0312',
    RangeShifterSequence: '300A0314',
    RangeShifterNumber: '300A0316',
    RangeShifterID: '300A0318',
    RangeShifterType: '300A0320',
    RangeShifterDescription: '300A0322',
    NumberOfLateralSpreadingDevices: '300A0330',
    LateralSpreadingDeviceSequence: '300A0332',
    LateralSpreadingDeviceNumber: '300A0334',
    LateralSpreadingDeviceID: '300A0336',
    LateralSpreadingDeviceType: '300A0338',
    LateralSpreadingDeviceDescription: '300A033A',
    LateralSpreadingDevWaterEquivThick: '300A033C',
    NumberOfRangeModulators: '300A0340',
    RangeModulatorSequence: '300A0342',
    RangeModulatorNumber: '300A0344',
    RangeModulatorID: '300A0346',
    RangeModulatorType: '300A0348',
    RangeModulatorDescription: '300A034A',
    BeamCurrentModulationID: '300A034C',
    PatientSupportType: '300A0350',
    PatientSupportID: '300A0352',
    PatientSupportAccessoryCode: '300A0354',
    FixationLightAzimuthalAngle: '300A0356',
    FixationLightPolarAngle: '300A0358',
    MetersetRate: '300A035A',
    RangeShifterSettingsSequence: '300A0360',
    RangeShifterSetting: '300A0362',
    IsocenterToRangeShifterDistance: '300A0364',
    RangeShifterWaterEquivThickness: '300A0366',
    LateralSpreadingDeviceSettingsSeq: '300A0370',
    LateralSpreadingDeviceSetting: '300A0372',
    IsocenterToLateralSpreadingDevDist: '300A0374',
    RangeModulatorSettingsSequence: '300A0380',
    RangeModulatorGatingStartValue: '300A0382',
    RangeModulatorGatingStopValue: '300A0384',
    IsocenterToRangeModulatorDistance: '300A038A',
    ScanSpotTuneID: '300A0390',
    NumberOfScanSpotPositions: '300A0392',
    ScanSpotPositionMap: '300A0394',
    ScanSpotMetersetWeights: '300A0396',
    ScanningSpotSize: '300A0398',
    NumberOfPaintings: '300A039A',
    IonToleranceTableSequence: '300A03A0',
    IonBeamSequence: '300A03A2',
    IonBeamLimitingDeviceSequence: '300A03A4',
    IonBlockSequence: '300A03A6',
    IonControlPointSequence: '300A03A8',
    IonWedgeSequence: '300A03AA',
    IonWedgePositionSequence: '300A03AC',
    ReferencedSetupImageSequence: '300A0401',
    SetupImageComment: '300A0402',
    MotionSynchronizationSequence: '300A0410',
    ControlPointOrientation: '300A0412',
    GeneralAccessorySequence: '300A0420',
    GeneralAccessoryID: '300A0421',
    GeneralAccessoryDescription: '300A0422',
    GeneralAccessoryType: '300A0423',
    GeneralAccessoryNumber: '300A0424',
    ReferencedRTPlanSequence: '300C0002',
    ReferencedBeamSequence: '300C0004',
    ReferencedBeamNumber: '300C0006',
    ReferencedReferenceImageNumber: '300C0007',
    StartCumulativeMetersetWeight: '300C0008',
    EndCumulativeMetersetWeight: '300C0009',
    ReferencedBrachyAppSetupSeq: '300C000A',
    ReferencedBrachyAppSetupNumber: '300C000C',
    ReferencedSourceNumber: '300C000E',
    ReferencedFractionGroupSequence: '300C0020',
    ReferencedFractionGroupNumber: '300C0022',
    ReferencedVerificationImageSeq: '300C0040',
    ReferencedReferenceImageSequence: '300C0042',
    ReferencedDoseReferenceSequence: '300C0050',
    ReferencedDoseReferenceNumber: '300C0051',
    BrachyReferencedDoseReferenceSeq: '300C0055',
    ReferencedStructureSetSequence: '300C0060',
    ReferencedPatientSetupNumber: '300C006A',
    ReferencedDoseSequence: '300C0080',
    ReferencedToleranceTableNumber: '300C00A0',
    ReferencedBolusSequence: '300C00B0',
    ReferencedWedgeNumber: '300C00C0',
    ReferencedCompensatorNumber: '300C00D0',
    ReferencedBlockNumber: '300C00E0',
    ReferencedControlPointIndex: '300C00F0',
    ReferencedControlPointSequence: '300C00F2',
    ReferencedStartControlPointIndex: '300C00F4',
    ReferencedStopControlPointIndex: '300C00F6',
    ReferencedRangeShifterNumber: '300C0100',
    ReferencedLateralSpreadingDevNum: '300C0102',
    ReferencedRangeModulatorNumber: '300C0104',
    ApprovalStatus: '300E0002',
    ReviewDate: '300E0004',
    ReviewTime: '300E0005',
    ReviewerName: '300E0008',
    TextGroupLength: '40000000',
    Arbitrary: '40000010',
    TextComments: '40004000',
    ResultsID: '40080040',
    ResultsIDIssuer: '40080042',
    ReferencedInterpretationSequence: '40080050',
    InterpretationRecordedDate: '40080100',
    InterpretationRecordedTime: '40080101',
    InterpretationRecorder: '40080102',
    ReferenceToRecordedSound: '40080103',
    InterpretationTranscriptionDate: '40080108',
    InterpretationTranscriptionTime: '40080109',
    InterpretationTranscriber: '4008010A',
    InterpretationText: '4008010B',
    InterpretationAuthor: '4008010C',
    InterpretationApproverSequence: '40080111',
    InterpretationApprovalDate: '40080112',
    InterpretationApprovalTime: '40080113',
    PhysicianApprovingInterpretation: '40080114',
    InterpretationDiagnosisDescription: '40080115',
    InterpretationDiagnosisCodeSeq: '40080117',
    ResultsDistributionListSequence: '40080118',
    DistributionName: '40080119',
    DistributionAddress: '4008011A',
    InterpretationID: '40080200',
    InterpretationIDIssuer: '40080202',
    InterpretationTypeID: '40080210',
    InterpretationStatusID: '40080212',
    Impressions: '40080300',
    ResultsComments: '40084000',
    MACParametersSequence: '4FFE0001',
    CurveDimensions: '50..0005',
    NumberOfPoints: '50..0010',
    TypeOfData: '50..0020',
    CurveDescription: '50..0022',
    AxisUnits: '50..0030',
    AxisLabels: '50..0040',
    DataValueRepresentation: '50..0103',
    MinimumCoordinateValue: '50..0104',
    MaximumCoordinateValue: '50..0105',
    CurveRange: '50..0106',
    CurveDataDescriptor: '50..0110',
    CoordinateStartValue: '50..0112',
    CoordinateStepValue: '50..0114',
    CurveActivationLayer: '50..1001',
    AudioType: '50..2000',
    AudioSampleFormat: '50..2002',
    NumberOfSamples: '50..2006',
    SampleRate: '50..2008',
    TotalTime: '50..200A',
    AudioSampleData: '50..200C',
    AudioComments: '50..200E',
    CurveLabel: '50..2500',
    ReferencedOverlayGroup: '50..2610',
    CurveData: '50..3000',
    SharedFunctionalGroupsSequence: '52009229',
    PerFrameFunctionalGroupsSequence: '52009230',
    WaveformSequence: '54000100',
    ChannelMinimumValue: '54000110',
    ChannelMaximumValue: '54000112',
    WaveformBitsAllocated: '54001004',
    WaveformSampleInterpretation: '54001006',
    WaveformPaddingValue: '5400100A',
    WaveformData: '54001010',
    FirstOrderPhaseCorrectionAngle: '56000010',
    SpectroscopyData: '56000020',
    OverlayGroupLength: '60000000',
    OverlayRows: '60..0010',
    OverlayColumns: '60..0011',
    OverlayPlanes: '60..0012',
    NumberOfFramesInOverlay: '60..0015',
    OverlayDescription: '60..0022',
    OverlayType: '60..0040',
    OverlaySubtype: '60..0045',
    OverlayOrigin: '60..0050',
    ImageFrameOrigin: '60..0051',
    OverlayPlaneOrigin: '60..0052',
    OverlayCompressionCode: '60..0060',
    OverlayCompressionOriginator: '60..0061',
    OverlayCompressionLabel: '60..0062',
    OverlayCompressionDescription: '60..0063',
    OverlayCompressionStepPointers: '60..0066',
    OverlayRepeatInterval: '60..0068',
    OverlayBitsGrouped: '60..0069',
    OverlayBitsAllocated: '60..0100',
    OverlayBitPosition: '60..0102',
    OverlayFormat: '60..0110',
    OverlayLocation: '60..0200',
    OverlayCodeLabel: '60..0800',
    OverlayNumberOfTables: '60..0802',
    OverlayCodeTableLocation: '60..0803',
    OverlayBitsForCodeWord: '60..0804',
    OverlayActivationLayer: '60..1001',
    OverlayDescriptorGray: '60..1100',
    OverlayDescriptorRed: '60..1101',
    OverlayDescriptorGreen: '60..1102',
    OverlayDescriptorBlue: '60..1103',
    OverlaysGray: '60..1200',
    OverlaysRed: '60..1201',
    OverlaysGreen: '60..1202',
    OverlaysBlue: '60..1203',
    ROIArea: '60..1301',
    ROIMean: '60..1302',
    ROIStandardDeviation: '60..1303',
    OverlayLabel: '60..1500',
    OverlayData: '60..3000',
    OverlayComments: '60..4000',
    PixelDataGroupLength: '7F..0000',
    PixelData: '7F..0010',
    VariableNextDataGroup: '7F..0011',
    VariableCoefficientsSDVN: '7F..0020',
    VariableCoefficientsSDHN: '7F..0030',
    VariableCoefficientsSDDN: '7F..0040',
    DigitalSignaturesSequence: 'FFFAFFFA',
    DataSetTrailingPadding: 'FFFCFFFC',
    StartOfItem: 'FFFEE000',
    EndOfItems: 'FFFEE00D',
    EndOfSequence: 'FFFEE0DD',
  },
  dicom2name: {
    '00100010': 'PatientName',
    '00020000': 'FileMetaInfoGroupLength',
    '00020001': 'FileMetaInfoVersion',
    '00020002': 'MediaStorageSOPClassUID',
    '00020003': 'MediaStorageSOPInstanceUID',
    '00020010': 'TransferSyntaxUID',
    '00020012': 'ImplementationClassUID',
    '00020013': 'ImplementationVersionName',
    '00020016': 'SourceApplicationEntityTitle',
    '00020100': 'PrivateInformationCreatorUID',
    '00020102': 'PrivateInformation',
    '00041130': 'FileSetID',
    '00041141': 'FileSetDescriptorFileID',
    '00041142': 'SpecificCharacterSetOfFile',
    '00041200': 'FirstDirectoryRecordOffset',
    '00041202': 'LastDirectoryRecordOffset',
    '00041212': 'FileSetConsistencyFlag',
    '00041220': 'DirectoryRecordSequence',
    '00041400': 'OffsetOfNextDirectoryRecord',
    '00041410': 'RecordInUseFlag',
    '00041420': 'LowerLevelDirectoryEntityOffset',
    '00041430': 'DirectoryRecordType',
    '00041432': 'PrivateRecordUID',
    '00041500': 'ReferencedFileID',
    '00041504': 'MRDRDirectoryRecordOffset',
    '00041510': 'ReferencedSOPClassUIDInFile',
    '00041511': 'ReferencedSOPInstanceUIDInFile',
    '00041512': 'ReferencedTransferSyntaxUIDInFile',
    '0004151A': 'ReferencedRelatedSOPClassUIDInFile',
    '00041600': 'NumberOfReferences',
    '00080000': 'IdentifyingGroupLength',
    '00080001': 'LengthToEnd',
    '00080005': 'SpecificCharacterSet',
    '00080006': 'LanguageCodeSequence',
    '00080008': 'ImageType',
    '00080010': 'RecognitionCode',
    '00080012': 'InstanceCreationDate',
    '00080013': 'InstanceCreationTime',
    '00080014': 'InstanceCreatorUID',
    '00080016': 'SOPClassUID',
    '00080018': 'SOPInstanceUID',
    '0008001A': 'RelatedGeneralSOPClassUID',
    '0008001B': 'OriginalSpecializedSOPClassUID',
    '00080020': 'StudyDate',
    '00080021': 'SeriesDate',
    '00080022': 'AcquisitionDate',
    '00080023': 'ContentDate',
    '00080024': 'OverlayDate',
    '00080025': 'CurveDate',
    '0008002A': 'AcquisitionDateTime',
    '00080030': 'StudyTime',
    '00080031': 'SeriesTime',
    '00080032': 'AcquisitionTime',
    '00080033': 'ContentTime',
    '00080034': 'OverlayTime',
    '00080035': 'CurveTime',
    '00080040': 'DataSetType',
    '00080041': 'DataSetSubtype',
    '00080042': 'NuclearMedicineSeriesType',
    '00080050': 'AccessionNumber',
    '00080052': 'QueryRetrieveLevel',
    '00080054': 'RetrieveAETitle',
    '00080056': 'InstanceAvailability',
    '00080058': 'FailedSOPInstanceUIDList',
    '00080060': 'Modality',
    '00080061': 'ModalitiesInStudy',
    '00080062': 'SOPClassesInStudy',
    '00080064': 'ConversionType',
    '00080068': 'PresentationIntentType',
    '00080070': 'Manufacturer',
    '00080080': 'InstitutionName',
    '00080081': 'InstitutionAddress',
    '00080082': 'InstitutionCodeSequence',
    '00080090': 'ReferringPhysicianName',
    '00080092': 'ReferringPhysicianAddress',
    '00080094': 'ReferringPhysicianTelephoneNumber',
    '00080096': 'ReferringPhysicianIDSequence',
    '00080100': 'CodeValue',
    '00080102': 'CodingSchemeDesignator',
    '00080103': 'CodingSchemeVersion',
    '00080104': 'CodeMeaning',
    '00080105': 'MappingResource',
    '00080106': 'ContextGroupVersion',
    '00080107': 'ContextGroupLocalVersion',
    '0008010B': 'ContextGroupExtensionFlag',
    '0008010C': 'CodingSchemeUID',
    '0008010D': 'ContextGroupExtensionCreatorUID',
    '0008010F': 'ContextIdentifier',
    '00080110': 'CodingSchemeIDSequence',
    '00080112': 'CodingSchemeRegistry',
    '00080114': 'CodingSchemeExternalID',
    '00080115': 'CodingSchemeName',
    '00102299': 'ResponsibleOrganization',
    '00080117': 'ContextUID',
    '00080201': 'TimezoneOffsetFromUTC',
    '00081000': 'NetworkID',
    '00081010': 'StationName',
    '00081030': 'StudyDescription',
    '00081032': 'ProcedureCodeSequence',
    '0008103E': 'SeriesDescription',
    '00081040': 'InstitutionalDepartmentName',
    '00081048': 'PhysiciansOfRecord',
    '00081049': 'PhysiciansOfRecordIDSequence',
    '00081050': 'PerformingPhysicianName',
    '00081052': 'PerformingPhysicianIDSequence',
    '00081060': 'NameOfPhysicianReadingStudy',
    '00081062': 'PhysicianReadingStudyIDSequence',
    '00081070': 'OperatorsName',
    '00081072': 'OperatorIDSequence',
    '00081080': 'AdmittingDiagnosesDescription',
    '00081084': 'AdmittingDiagnosesCodeSequence',
    '00081090': 'ManufacturersModelName',
    '00081100': 'ReferencedResultsSequence',
    '00081110': 'ReferencedStudySequence',
    '00400330': 'ReferencedProcedureStepSequence',
    '00081115': 'ReferencedSeriesSequence',
    '00081120': 'ReferencedPatientSequence',
    '00081125': 'ReferencedVisitSequence',
    '50..2600': 'ReferencedOverlaySequence',
    '0008113A': 'ReferencedWaveformSequence',
    '00081140': 'ReferencedImageSequence',
    '00081145': 'ReferencedCurveSequence',
    '0008114A': 'ReferencedInstanceSequence',
    '00081150': 'ReferencedSOPClassUID',
    '00081155': 'ReferencedSOPInstanceUID',
    '0008115A': 'SOPClassesSupported',
    '00081160': 'ReferencedFrameNumber',
    '00081161': 'SimpleFrameList',
    '00081162': 'CalculatedFrameList',
    '00081163': 'TimeRange',
    '00081164': 'FrameExtractionSequence',
    '00081195': 'TransactionUID',
    '00081197': 'FailureReason',
    '00081198': 'FailedSOPSequence',
    '00081199': 'ReferencedSOPSequence',
    '00081200': 'OtherReferencedStudiesSequence',
    '00081250': 'RelatedSeriesSequence',
    '00282110': 'LossyImageCompression',
    '00082111': 'DerivationDescription',
    '00082112': 'SourceImageSequence',
    '00082120': 'StageName',
    '00082122': 'StageNumber',
    '00082124': 'NumberOfStages',
    '00082127': 'ViewName',
    '00082128': 'ViewNumber',
    '00082129': 'NumberOfEventTimers',
    '0008212A': 'NumberOfViewsInStage',
    '00082130': 'EventElapsedTimes',
    '00082132': 'EventTimerNames',
    '00082133': 'EventTimerSequence',
    '00082134': 'EventTimeOffset',
    '00082135': 'EventCodeSequence',
    '00082142': 'StartTrim',
    '00082143': 'StopTrim',
    '00082144': 'RecommendedDisplayFrameRate',
    '00082200': 'TransducerPosition',
    '00082204': 'TransducerOrientation',
    '00082208': 'AnatomicStructure',
    '00082218': 'AnatomicRegionSequence',
    '00082220': 'AnatomicRegionModifierSequence',
    '00082228': 'PrimaryAnatomicStructureSequence',
    '00082229': 'AnatomicStructureOrRegionSequence',
    '00082230': 'AnatomicStructureModifierSequence',
    '00082240': 'TransducerPositionSequence',
    '00082242': 'TransducerPositionModifierSequence',
    '00082244': 'TransducerOrientationSequence',
    '00082246': 'TransducerOrientationModifierSeq',
    '00082253': 'AnatomicEntrancePortalCodeSeqTrial',
    '00082255': 'AnatomicApproachDirCodeSeqTrial',
    '00082256': 'AnatomicPerspectiveDescrTrial',
    '00082257': 'AnatomicPerspectiveCodeSeqTrial',
    '00083001': 'AlternateRepresentationSequence',
    '00083010': 'IrradiationEventUID',
    '00084000': 'IdentifyingComments',
    '00089007': 'FrameType',
    '00089092': 'ReferencedImageEvidenceSequence',
    '00089121': 'ReferencedRawDataSequence',
    '00089123': 'CreatorVersionUID',
    '00089124': 'DerivationImageSequence',
    '00089154': 'SourceImageEvidenceSequence',
    '00089205': 'PixelPresentation',
    '00089206': 'VolumetricProperties',
    '00089207': 'VolumeBasedCalculationTechnique',
    '00089208': 'ComplexImageComponent',
    '00089209': 'AcquisitionContrast',
    '00089215': 'DerivationCodeSequence',
    '00089237': 'GrayscalePresentationStateSequence',
    '00089410': 'ReferencedOtherPlaneSequence',
    '00089458': 'FrameDisplaySequence',
    '00089459': 'RecommendedDisplayFrameRateInFloat',
    '00089460': 'SkipFrameRangeFlag',
    '00091001': 'FullFidelity',
    '00091002': 'SuiteID',
    '00091004': 'ProductID',
    '00091027': 'ImageActualDate',
    '00091030': 'ServiceID',
    '00091031': 'MobileLocationNumber',
    '000910E3': 'EquipmentUID',
    '00211018': 'GenesisVersionNow',
    '000910E7': 'ExamRecordChecksum',
    '000910E9': 'ActualSeriesDataTimeStamp',
    '00100000': 'PatientGroupLength',
    '00100020': 'PatientID',
    '00100021': 'IssuerOfPatientID',
    '00100022': 'TypeOfPatientID',
    '00100030': 'PatientBirthDate',
    '00100032': 'PatientBirthTime',
    '00100040': 'PatientSex',
    '00100050': 'PatientInsurancePlanCodeSequence',
    '00100101': 'PatientPrimaryLanguageCodeSeq',
    '00100102': 'PatientPrimaryLanguageCodeModSeq',
    '00101000': 'OtherPatientIDs',
    '00101001': 'OtherPatientNames',
    '00101002': 'OtherPatientIDsSequence',
    '00101005': 'PatientBirthName',
    '00101010': 'PatientAge',
    '00101020': 'PatientSize',
    '00101030': 'PatientWeight',
    '00101040': 'PatientAddress',
    '00101050': 'InsurancePlanIdentification',
    '00101060': 'PatientMotherBirthName',
    '00101080': 'MilitaryRank',
    '00101081': 'BranchOfService',
    '00101090': 'MedicalRecordLocator',
    '00102000': 'MedicalAlerts',
    '00102110': 'Allergies',
    '00102150': 'CountryOfResidence',
    '00102152': 'RegionOfResidence',
    '00102154': 'PatientTelephoneNumbers',
    '00102160': 'EthnicGroup',
    '00102180': 'Occupation',
    '001021A0': 'SmokingStatus',
    '001021B0': 'AdditionalPatientHistory',
    '001021C0': 'PregnancyStatus',
    '001021D0': 'LastMenstrualDate',
    '001021F0': 'PatientReligiousPreference',
    '00102201': 'PatientSpeciesDescription',
    '00102202': 'PatientSpeciesCodeSequence',
    '00102203': 'PatientSexNeutered',
    '00102210': 'AnatomicalOrientationType',
    '00102292': 'PatientBreedDescription',
    '00102293': 'PatientBreedCodeSequence',
    '00102294': 'BreedRegistrationSequence',
    '00102295': 'BreedRegistrationNumber',
    '00102296': 'BreedRegistryCodeSequence',
    '00102297': 'ResponsiblePerson',
    '00102298': 'ResponsiblePersonRole',
    '00104000': 'PatientComments',
    '00109431': 'ExaminedBodyThickness',
    '00111010': 'PatientStatus',
    '00120010': 'ClinicalTrialSponsorName',
    '00120020': 'ClinicalTrialProtocolID',
    '00120021': 'ClinicalTrialProtocolName',
    '00120030': 'ClinicalTrialSiteID',
    '00120031': 'ClinicalTrialSiteName',
    '00120040': 'ClinicalTrialSubjectID',
    '00120042': 'ClinicalTrialSubjectReadingID',
    '00120050': 'ClinicalTrialTimePointID',
    '00120051': 'ClinicalTrialTimePointDescription',
    '00120060': 'ClinicalTrialCoordinatingCenter',
    '00120062': 'PatientIdentityRemoved',
    '00120063': 'DeidentificationMethod',
    '00120064': 'DeidentificationMethodCodeSequence',
    '00120071': 'ClinicalTrialSeriesID',
    '00120072': 'ClinicalTrialSeriesDescription',
    '00120084': 'DistributionType',
    '00120085': 'ConsentForDistributionFlag',
    '00180000': 'AcquisitionGroupLength',
    '00180010': 'ContrastBolusAgent',
    '00180012': 'ContrastBolusAgentSequence',
    '00180014': 'ContrastBolusAdministrationRoute',
    '00180015': 'BodyPartExamined',
    '00180020': 'ScanningSequence',
    '00180021': 'SequenceVariant',
    '00180022': 'ScanOptions',
    '00180023': 'MRAcquisitionType',
    '00180024': 'SequenceName',
    '00180025': 'AngioFlag',
    '00180026': 'InterventionDrugInformationSeq',
    '00180027': 'InterventionDrugStopTime',
    '00180028': 'InterventionDrugDose',
    '00180029': 'InterventionDrugSequence',
    '0018002A': 'AdditionalDrugSequence',
    '00180030': 'Radionuclide',
    '00180031': 'Radiopharmaceutical',
    '00180032': 'EnergyWindowCenterline',
    '00180033': 'EnergyWindowTotalWidth',
    '00180034': 'InterventionDrugName',
    '00180035': 'InterventionDrugStartTime',
    '00180036': 'InterventionSequence',
    '00180037': 'TherapyType',
    '00180038': 'InterventionStatus',
    '00180039': 'TherapyDescription',
    '0018003A': 'InterventionDescription',
    '00180040': 'CineRate',
    '00180042': 'InitialCineRunState',
    '00180050': 'SliceThickness',
    '00180060': 'KVP',
    '00180070': 'CountsAccumulated',
    '00180071': 'AcquisitionTerminationCondition',
    '00180072': 'EffectiveDuration',
    '00180073': 'AcquisitionStartCondition',
    '00180074': 'AcquisitionStartConditionData',
    '00180075': 'AcquisitionEndConditionData',
    '00180080': 'RepetitionTime',
    '00180081': 'EchoTime',
    '00180082': 'InversionTime',
    '00180083': 'NumberOfAverages',
    '00180084': 'ImagingFrequency',
    '00180085': 'ImagedNucleus',
    '00180086': 'EchoNumber',
    '00180087': 'MagneticFieldStrength',
    '00180088': 'SpacingBetweenSlices',
    '00180089': 'NumberOfPhaseEncodingSteps',
    '00180090': 'DataCollectionDiameter',
    '00180091': 'EchoTrainLength',
    '00180093': 'PercentSampling',
    '00180094': 'PercentPhaseFieldOfView',
    '00180095': 'PixelBandwidth',
    '00181000': 'DeviceSerialNumber',
    '00181002': 'DeviceUID',
    '00181003': 'DeviceID',
    '00181004': 'PlateID',
    '00181005': 'GeneratorID',
    '00181006': 'GridID',
    '00181007': 'CassetteID',
    '00181008': 'GantryID',
    '00181010': 'SecondaryCaptureDeviceID',
    '00181011': 'HardcopyCreationDeviceID',
    '00181012': 'DateOfSecondaryCapture',
    '00181014': 'TimeOfSecondaryCapture',
    '00181016': 'SecondaryCaptureDeviceManufacturer',
    '00181017': 'HardcopyDeviceManufacturer',
    '00181018': 'SecondaryCaptureDeviceModelName',
    '00181019': 'SecondaryCaptureDeviceSoftwareVers',
    '0018101A': 'HardcopyDeviceSoftwareVersion',
    '0018101B': 'HardcopyDeviceModelName',
    '00181020': 'SoftwareVersion',
    '00181022': 'VideoImageFormatAcquired',
    '00181023': 'DigitalImageFormatAcquired',
    '00181030': 'ProtocolName',
    '00181040': 'ContrastBolusRoute',
    '00181041': 'ContrastBolusVolume',
    '00181042': 'ContrastBolusStartTime',
    '00181043': 'ContrastBolusStopTime',
    '00181044': 'ContrastBolusTotalDose',
    '00181045': 'SyringeCounts',
    '00181046': 'ContrastFlowRate',
    '00181047': 'ContrastFlowDuration',
    '00181048': 'ContrastBolusIngredient',
    '00181049': 'ContrastBolusConcentration',
    '00181050': 'SpatialResolution',
    '00181060': 'TriggerTime',
    '00181061': 'TriggerSourceOrType',
    '00181062': 'NominalInterval',
    '00181063': 'FrameTime',
    '00181064': 'CardiacFramingType',
    '00181065': 'FrameTimeVector',
    '00181066': 'FrameDelay',
    '00181067': 'ImageTriggerDelay',
    '00181068': 'MultiplexGroupTimeOffset',
    '00181069': 'TriggerTimeOffset',
    '0018106A': 'SynchronizationTrigger',
    '0018106C': 'SynchronizationChannel',
    '0018106E': 'TriggerSamplePosition',
    '00181070': 'RadiopharmaceuticalRoute',
    '00181071': 'RadiopharmaceuticalVolume',
    '00181072': 'RadiopharmaceuticalStartTime',
    '00181073': 'RadiopharmaceuticalStopTime',
    '00181074': 'RadionuclideTotalDose',
    '00181075': 'RadionuclideHalfLife',
    '00181076': 'RadionuclidePositronFraction',
    '00181077': 'RadiopharmaceuticalSpecActivity',
    '00181078': 'RadiopharmaceuticalStartDateTime',
    '00181079': 'RadiopharmaceuticalStopDateTime',
    '00181080': 'BeatRejectionFlag',
    '00181081': 'LowRRValue',
    '00181082': 'HighRRValue',
    '00181083': 'IntervalsAcquired',
    '00181084': 'IntervalsRejected',
    '00181085': 'PVCRejection',
    '00181086': 'SkipBeats',
    '00181088': 'HeartRate',
    '00181090': 'CardiacNumberOfImages',
    '00181094': 'TriggerWindow',
    '00181100': 'ReconstructionDiameter',
    '00181110': 'DistanceSourceToDetector',
    '00181111': 'DistanceSourceToPatient',
    '00181114': 'EstimatedRadiographicMagnification',
    '00181120': 'GantryDetectorTilt',
    '00181121': 'GantryDetectorSlew',
    '00181130': 'TableHeight',
    '00181131': 'TableTraverse',
    '00181134': 'TableMotion',
    '00181135': 'TableVerticalIncrement',
    '00181136': 'TableLateralIncrement',
    '00181137': 'TableLongitudinalIncrement',
    '00181138': 'TableAngle',
    '0018113A': 'TableType',
    '00181140': 'RotationDirection',
    '00181141': 'AngularPosition',
    '00181142': 'RadialPosition',
    '00181143': 'ScanArc',
    '00181144': 'AngularStep',
    '00181145': 'CenterOfRotationOffset',
    '00181146': 'RotationOffset',
    '00181147': 'FieldOfViewShape',
    '00181149': 'FieldOfViewDimensions',
    '00181150': 'ExposureTime',
    '00181151': 'XRayTubeCurrent',
    '00181152': 'Exposure',
    '00181153': 'ExposureInMicroAmpSec',
    '00181154': 'AveragePulseWidth',
    '00181155': 'RadiationSetting',
    '00181156': 'RectificationType',
    '0018115A': 'RadiationMode',
    '0018115E': 'ImageAreaDoseProduct',
    '00181160': 'FilterType',
    '00181161': 'TypeOfFilters',
    '00181162': 'IntensifierSize',
    '00181164': 'ImagerPixelSpacing',
    '00181166': 'Grid',
    '00181170': 'GeneratorPower',
    '00181180': 'CollimatorGridName',
    '00181181': 'CollimatorType',
    '00181182': 'FocalDistance',
    '00181183': 'XFocusCenter',
    '00181184': 'YFocusCenter',
    '00181190': 'FocalSpots',
    '00181191': 'AnodeTargetMaterial',
    '001811A0': 'BodyPartThickness',
    '001811A2': 'CompressionForce',
    '00181200': 'DateOfLastCalibration',
    '00181201': 'TimeOfLastCalibration',
    '00181210': 'ConvolutionKernel',
    '00181240': 'UpperLowerPixelValues',
    '00181242': 'ActualFrameDuration',
    '00181243': 'CountRate',
    '00181244': 'PreferredPlaybackSequencing',
    '00181250': 'ReceiveCoilName',
    '00181251': 'TransmitCoilName',
    '00181260': 'PlateType',
    '00181261': 'PhosphorType',
    '00181300': 'ScanVelocity',
    '00181301': 'WholeBodyTechnique',
    '00181302': 'ScanLength',
    '00181310': 'AcquisitionMatrix',
    '00181312': 'InPlanePhaseEncodingDirection',
    '00181314': 'FlipAngle',
    '00181315': 'VariableFlipAngleFlag',
    '00181316': 'SAR',
    '00181318': 'DB-Dt',
    '00181400': 'AcquisitionDeviceProcessingDescr',
    '00181401': 'AcquisitionDeviceProcessingCode',
    '00181402': 'CassetteOrientation',
    '00181403': 'CassetteSize',
    '00181404': 'ExposuresOnPlate',
    '00181405': 'RelativeXRayExposure',
    '00181450': 'ColumnAngulation',
    '00181460': 'TomoLayerHeight',
    '00181470': 'TomoAngle',
    '00181480': 'TomoTime',
    '00181490': 'TomoType',
    '00181491': 'TomoClass',
    '00181495': 'NumberOfTomosynthesisSourceImages',
    '00181500': 'PositionerMotion',
    '00181508': 'PositionerType',
    '00181510': 'PositionerPrimaryAngle',
    '00181511': 'PositionerSecondaryAngle',
    '00181520': 'PositionerPrimaryAngleIncrement',
    '00181521': 'PositionerSecondaryAngleIncrement',
    '00181530': 'DetectorPrimaryAngle',
    '00181531': 'DetectorSecondaryAngle',
    '00181600': 'ShutterShape',
    '00181602': 'ShutterLeftVerticalEdge',
    '00181604': 'ShutterRightVerticalEdge',
    '00181606': 'ShutterUpperHorizontalEdge',
    '00181608': 'ShutterLowerHorizontalEdge',
    '00181610': 'CenterOfCircularShutter',
    '00181612': 'RadiusOfCircularShutter',
    '00181620': 'VerticesOfPolygonalShutter',
    '00181622': 'ShutterPresentationValue',
    '00181623': 'ShutterOverlayGroup',
    '00181624': 'ShutterPresentationColorCIELabVal',
    '00181700': 'CollimatorShape',
    '00181702': 'CollimatorLeftVerticalEdge',
    '00181704': 'CollimatorRightVerticalEdge',
    '00181706': 'CollimatorUpperHorizontalEdge',
    '00181708': 'CollimatorLowerHorizontalEdge',
    '00181710': 'CenterOfCircularCollimator',
    '00181712': 'RadiusOfCircularCollimator',
    '00181720': 'VerticesOfPolygonalCollimator',
    '00181800': 'AcquisitionTimeSynchronized',
    '00181801': 'TimeSource',
    '00181802': 'TimeDistributionProtocol',
    '00181803': 'NTPSourceAddress',
    '00182001': 'PageNumberVector',
    '00182002': 'FrameLabelVector',
    '00182003': 'FramePrimaryAngleVector',
    '00182004': 'FrameSecondaryAngleVector',
    '00182005': 'SliceLocationVector',
    '00182006': 'DisplayWindowLabelVector',
    '00182010': 'NominalScannedPixelSpacing',
    '00182020': 'DigitizingDeviceTransportDirection',
    '00182030': 'RotationOfScannedFilm',
    '00183100': 'IVUSAcquisition',
    '00183101': 'IVUSPullbackRate',
    '00183102': 'IVUSGatedRate',
    '00183103': 'IVUSPullbackStartFrameNumber',
    '00183104': 'IVUSPullbackStopFrameNumber',
    '00183105': 'LesionNumber',
    '00184000': 'AcquisitionComments',
    '00185000': 'OutputPower',
    '00185010': 'TransducerData',
    '00185012': 'FocusDepth',
    '00185020': 'ProcessingFunction',
    '00185021': 'PostprocessingFunction',
    '00185022': 'MechanicalIndex',
    '00185024': 'BoneThermalIndex',
    '00185026': 'CranialThermalIndex',
    '00185027': 'SoftTissueThermalIndex',
    '00185028': 'SoftTissueFocusThermalIndex',
    '00185029': 'SoftTissueSurfaceThermalIndex',
    '00185030': 'DynamicRange',
    '00185040': 'TotalGain',
    '00185050': 'DepthOfScanField',
    '00185100': 'PatientPosition',
    '00185101': 'ViewPosition',
    '00185104': 'ProjectionEponymousNameCodeSeq',
    '00185210': 'ImageTransformationMatrix',
    '00185212': 'ImageTranslationVector',
    '00186000': 'Sensitivity',
    '00186011': 'SequenceOfUltrasoundRegions',
    '00186012': 'RegionSpatialFormat',
    '00186014': 'RegionDataType',
    '00186016': 'RegionFlags',
    '00186018': 'RegionLocationMinX0',
    '0018601A': 'RegionLocationMinY0',
    '0018601C': 'RegionLocationMaxX1',
    '0018601E': 'RegionLocationMaxY1',
    '00186020': 'ReferencePixelX0',
    '00186022': 'ReferencePixelY0',
    '00186024': 'PhysicalUnitsXDirection',
    '00186026': 'PhysicalUnitsYDirection',
    '00186028': 'ReferencePixelPhysicalValueX',
    '0018602A': 'ReferencePixelPhysicalValueY',
    '0018602C': 'PhysicalDeltaX',
    '0018602E': 'PhysicalDeltaY',
    '00186030': 'TransducerFrequency',
    '00186031': 'TransducerType',
    '00186032': 'PulseRepetitionFrequency',
    '00186034': 'DopplerCorrectionAngle',
    '00186036': 'SteeringAngle',
    '00186038': 'DopplerSampleVolumeXPosRetired',
    '00186039': 'DopplerSampleVolumeXPosition',
    '0018603A': 'DopplerSampleVolumeYPosRetired',
    '0018603B': 'DopplerSampleVolumeYPosition',
    '0018603C': 'TMLinePositionX0Retired',
    '0018603D': 'TMLinePositionX0',
    '0018603E': 'TMLinePositionY0Retired',
    '0018603F': 'TMLinePositionY0',
    '00186040': 'TMLinePositionX1Retired',
    '00186041': 'TMLinePositionX1',
    '00186042': 'TMLinePositionY1Retired',
    '00186043': 'TMLinePositionY1',
    '00186044': 'PixelComponentOrganization',
    '00186046': 'PixelComponentMask',
    '00186048': 'PixelComponentRangeStart',
    '0018604A': 'PixelComponentRangeStop',
    '0018604C': 'PixelComponentPhysicalUnits',
    '0018604E': 'PixelComponentDataType',
    '00186050': 'NumberOfTableBreakPoints',
    '00186052': 'TableOfXBreakPoints',
    '00186054': 'TableOfYBreakPoints',
    '00186056': 'NumberOfTableEntries',
    '00186058': 'TableOfPixelValues',
    '0018605A': 'TableOfParameterValues',
    '00186060': 'RWaveTimeVector',
    '00187000': 'DetectorConditionsNominalFlag',
    '00187001': 'DetectorTemperature',
    '00187004': 'DetectorType',
    '00187005': 'DetectorConfiguration',
    '00187006': 'DetectorDescription',
    '00187008': 'DetectorMode',
    '0018700A': 'DetectorID',
    '0018700C': 'DateOfLastDetectorCalibration',
    '0018700E': 'TimeOfLastDetectorCalibration',
    '00187010': 'DetectorExposuresSinceCalibration',
    '00187011': 'DetectorExposuresSinceManufactured',
    '00187012': 'DetectorTimeSinceLastExposure',
    '00187014': 'DetectorActiveTime',
    '00187016': 'DetectorActiveOffsetFromExposure',
    '0018701A': 'DetectorBinning',
    '00187020': 'DetectorElementPhysicalSize',
    '00187022': 'DetectorElementSpacing',
    '00187024': 'DetectorActiveShape',
    '00187026': 'DetectorActiveDimensions',
    '00187028': 'DetectorActiveOrigin',
    '0018702A': 'DetectorManufacturerName',
    '0018702B': 'DetectorManufacturersModelName',
    '00187030': 'FieldOfViewOrigin',
    '00187032': 'FieldOfViewRotation',
    '00187034': 'FieldOfViewHorizontalFlip',
    '00187040': 'GridAbsorbingMaterial',
    '00187041': 'GridSpacingMaterial',
    '00187042': 'GridThickness',
    '00187044': 'GridPitch',
    '00187046': 'GridAspectRatio',
    '00187048': 'GridPeriod',
    '0018704C': 'GridFocalDistance',
    '00187050': 'FilterMaterial',
    '00187052': 'FilterThicknessMinimum',
    '00187054': 'FilterThicknessMaximum',
    '00187060': 'ExposureControlMode',
    '00187062': 'ExposureControlModeDescription',
    '00187064': 'ExposureStatus',
    '00187065': 'PhototimerSetting',
    '00188150': 'ExposureTimeInMicroSec',
    '00188151': 'XRayTubeCurrentInMicroAmps',
    '00189004': 'ContentQualification',
    '00189005': 'PulseSequenceName',
    '00189006': 'MRImagingModifierSequence',
    '00189008': 'EchoPulseSequence',
    '00189009': 'InversionRecovery',
    '00189010': 'FlowCompensation',
    '00189011': 'MultipleSpinEcho',
    '00189012': 'MultiPlanarExcitation',
    '00189014': 'PhaseContrast',
    '00189015': 'TimeOfFlightContrast',
    '00189016': 'Spoiling',
    '00189017': 'SteadyStatePulseSequence',
    '00189018': 'EchoPlanarPulseSequence',
    '00189019': 'TagAngleFirstAxis',
    '00189020': 'MagnetizationTransfer',
    '00189021': 'T2Preparation',
    '00189022': 'BloodSignalNulling',
    '00189024': 'SaturationRecovery',
    '00189025': 'SpectrallySelectedSuppression',
    '00189026': 'SpectrallySelectedExcitation',
    '00189027': 'SpatialPresaturation',
    '00189028': 'Tagging',
    '00189029': 'OversamplingPhase',
    '00189030': 'TagSpacingFirstDimension',
    '00189032': 'GeometryOfKSpaceTraversal',
    '00189033': 'SegmentedKSpaceTraversal',
    '00189034': 'RectilinearPhaseEncodeReordering',
    '00189035': 'TagThickness',
    '00189036': 'PartialFourierDirection',
    '00189037': 'CardiacSynchronizationTechnique',
    '00189041': 'ReceiveCoilManufacturerName',
    '00189042': 'MRReceiveCoilSequence',
    '00189043': 'ReceiveCoilType',
    '00189044': 'QuadratureReceiveCoil',
    '00189045': 'MultiCoilDefinitionSequence',
    '00189046': 'MultiCoilConfiguration',
    '00189047': 'MultiCoilElementName',
    '00189048': 'MultiCoilElementUsed',
    '00189049': 'MRTransmitCoilSequence',
    '00189050': 'TransmitCoilManufacturerName',
    '00189051': 'TransmitCoilType',
    '00189052': 'SpectralWidth',
    '00189053': 'ChemicalShiftReference',
    '00189054': 'VolumeLocalizationTechnique',
    '00189058': 'MRAcquisitionFrequencyEncodeSteps',
    '00189059': 'Decoupling',
    '00189060': 'DecoupledNucleus',
    '00189061': 'DecouplingFrequency',
    '00189062': 'DecouplingMethod',
    '00189063': 'DecouplingChemicalShiftReference',
    '00189064': 'KSpaceFiltering',
    '00189065': 'TimeDomainFiltering',
    '00189066': 'NumberOfZeroFills',
    '00189067': 'BaselineCorrection',
    '00189069': 'ParallelReductionFactorInPlane',
    '00189070': 'CardiacRRIntervalSpecified',
    '0019105A': 'AcquisitionDuration',
    '00189074': 'FrameAcquisitionDateTime',
    '00189075': 'DiffusionDirectionality',
    '00189076': 'DiffusionGradientDirectionSequence',
    '00189077': 'ParallelAcquisition',
    '00189078': 'ParallelAcquisitionTechnique',
    '00189079': 'InversionTimes',
    '00189080': 'MetaboliteMapDescription',
    '00189081': 'PartialFourier',
    '00189082': 'EffectiveEchoTime',
    '00189083': 'MetaboliteMapCodeSequence',
    '00189084': 'ChemicalShiftSequence',
    '00189085': 'CardiacSignalSource',
    '00189087': 'DiffusionBValue',
    '00189089': 'DiffusionGradientOrientation',
    '00189090': 'VelocityEncodingDirection',
    '00189091': 'VelocityEncodingMinimumValue',
    '00189093': 'NumberOfKSpaceTrajectories',
    '00189094': 'CoverageOfKSpace',
    '00189095': 'SpectroscopyAcquisitionPhaseRows',
    '00189096': 'ParallelReductFactorInPlaneRetired',
    '00189098': 'TransmitterFrequency',
    '00189100': 'ResonantNucleus',
    '00189101': 'FrequencyCorrection',
    '00189103': 'MRSpectroscopyFOV-GeometrySequence',
    '00189104': 'SlabThickness',
    '00189105': 'SlabOrientation',
    '00189106': 'MidSlabPosition',
    '00189107': 'MRSpatialSaturationSequence',
    '00189112': 'MRTimingAndRelatedParametersSeq',
    '00189114': 'MREchoSequence',
    '00189115': 'MRModifierSequence',
    '00189117': 'MRDiffusionSequence',
    '00189118': 'CardiacTriggerSequence',
    '00189119': 'MRAveragesSequence',
    '00189125': 'MRFOV-GeometrySequence',
    '00189126': 'VolumeLocalizationSequence',
    '00189127': 'SpectroscopyAcquisitionDataColumns',
    '00189147': 'DiffusionAnisotropyType',
    '00189151': 'FrameReferenceDateTime',
    '00189152': 'MRMetaboliteMapSequence',
    '00189155': 'ParallelReductionFactorOutOfPlane',
    '00189159': 'SpectroscopyOutOfPlanePhaseSteps',
    '00189166': 'BulkMotionStatus',
    '00189168': 'ParallelReductionFactSecondInPlane',
    '00189169': 'CardiacBeatRejectionTechnique',
    '00189170': 'RespiratoryMotionCompTechnique',
    '00189171': 'RespiratorySignalSource',
    '00189172': 'BulkMotionCompensationTechnique',
    '00189173': 'BulkMotionSignalSource',
    '00189174': 'ApplicableSafetyStandardAgency',
    '00189175': 'ApplicableSafetyStandardDescr',
    '00189176': 'OperatingModeSequence',
    '00189177': 'OperatingModeType',
    '00189178': 'OperatingMode',
    '00189179': 'SpecificAbsorptionRateDefinition',
    '00189180': 'GradientOutputType',
    '00189181': 'SpecificAbsorptionRateValue',
    '00189182': 'GradientOutput',
    '00189183': 'FlowCompensationDirection',
    '00189184': 'TaggingDelay',
    '00189185': 'RespiratoryMotionCompTechDescr',
    '00189186': 'RespiratorySignalSourceID',
    '00189195': 'ChemicalShiftsMinIntegrateLimitHz',
    '00189196': 'ChemicalShiftsMaxIntegrateLimitHz',
    '00189197': 'MRVelocityEncodingSequence',
    '00189198': 'FirstOrderPhaseCorrection',
    '00189199': 'WaterReferencedPhaseCorrection',
    '00189200': 'MRSpectroscopyAcquisitionType',
    '00189214': 'RespiratoryCyclePosition',
    '00189217': 'VelocityEncodingMaximumValue',
    '00189218': 'TagSpacingSecondDimension',
    '00189219': 'TagAngleSecondAxis',
    '00189220': 'FrameAcquisitionDuration',
    '00189226': 'MRImageFrameTypeSequence',
    '00189227': 'MRSpectroscopyFrameTypeSequence',
    '00189231': 'MRAcqPhaseEncodingStepsInPlane',
    '00189232': 'MRAcqPhaseEncodingStepsOutOfPlane',
    '00189234': 'SpectroscopyAcqPhaseColumns',
    '00189236': 'CardiacCyclePosition',
    '00189239': 'SpecificAbsorptionRateSequence',
    '00189240': 'RFEchoTrainLength',
    '00189241': 'GradientEchoTrainLength',
    '00189295': 'ChemicalShiftsMinIntegrateLimitPPM',
    '00189296': 'ChemicalShiftsMaxIntegrateLimitPPM',
    '00189301': 'CTAcquisitionTypeSequence',
    '00189302': 'AcquisitionType',
    '00189303': 'TubeAngle',
    '00189304': 'CTAcquisitionDetailsSequence',
    '00189305': 'RevolutionTime',
    '00189306': 'SingleCollimationWidth',
    '00189307': 'TotalCollimationWidth',
    '00189308': 'CTTableDynamicsSequence',
    '00191023': 'TableSpeed',
    '00189310': 'TableFeedPerRotation',
    '00189311': 'SpiralPitchFactor',
    '00189312': 'CTGeometrySequence',
    '00189313': 'DataCollectionCenterPatient',
    '00189314': 'CTReconstructionSequence',
    '00189315': 'ReconstructionAlgorithm',
    '00189316': 'ConvolutionKernelGroup',
    '00189317': 'ReconstructionFieldOfView',
    '00189318': 'ReconstructionTargetCenterPatient',
    '00189319': 'ReconstructionAngle',
    '00189320': 'ImageFilter',
    '00189321': 'CTExposureSequence',
    '00189322': 'ReconstructionPixelSpacing',
    '00189323': 'ExposureModulationType',
    '00189324': 'EstimatedDoseSaving',
    '00189325': 'CTXRayDetailsSequence',
    '00189326': 'CTPositionSequence',
    '00189327': 'TablePosition',
    '00189328': 'ExposureTimeInMilliSec',
    '00189329': 'CTImageFrameTypeSequence',
    '00189330': 'XRayTubeCurrentInMilliAmps',
    '00189332': 'ExposureInMilliAmpSec',
    '00189333': 'ConstantVolumeFlag',
    '00189334': 'FluoroscopyFlag',
    '00189335': 'SourceToDataCollectionCenterDist',
    '00189337': 'ContrastBolusAgentNumber',
    '00189338': 'ContrastBolusIngredientCodeSeq',
    '00189340': 'ContrastAdministrationProfileSeq',
    '00189341': 'ContrastBolusUsageSequence',
    '00189342': 'ContrastBolusAgentAdministered',
    '00189343': 'ContrastBolusAgentDetected',
    '00189344': 'ContrastBolusAgentPhase',
    '00189345': 'CTDIvol',
    '00189346': 'CTDIPhantomTypeCodeSequence',
    '00189351': 'CalciumScoringMassFactorPatient',
    '00189352': 'CalciumScoringMassFactorDevice',
    '00189353': 'EnergyWeightingFactor',
    '00189360': 'CTAdditionalXRaySourceSequence',
    '00189401': 'ProjectionPixelCalibrationSequence',
    '00189402': 'DistanceSourceToIsocenter',
    '00189403': 'DistanceObjectToTableTop',
    '00189404': 'ObjectPixelSpacingInCenterOfBeam',
    '00189405': 'PositionerPositionSequence',
    '00189406': 'TablePositionSequence',
    '00189407': 'CollimatorShapeSequence',
    '00189412': 'XA-XRFFrameCharacteristicsSequence',
    '00189417': 'FrameAcquisitionSequence',
    '00189420': 'XRayReceptorType',
    '00189423': 'AcquisitionProtocolName',
    '00189424': 'AcquisitionProtocolDescription',
    '00189425': 'ContrastBolusIngredientOpaque',
    '00189426': 'DistanceReceptorPlaneToDetHousing',
    '00189427': 'IntensifierActiveShape',
    '00189428': 'IntensifierActiveDimensions',
    '00189429': 'PhysicalDetectorSize',
    '00189430': 'PositionOfIsocenterProjection',
    '00189432': 'FieldOfViewSequence',
    '00189433': 'FieldOfViewDescription',
    '00189434': 'ExposureControlSensingRegionsSeq',
    '00189435': 'ExposureControlSensingRegionShape',
    '00189436': 'ExposureControlSensRegionLeftEdge',
    '00189437': 'ExposureControlSensRegionRightEdge',
    '00189440': 'CenterOfCircExposControlSensRegion',
    '00189441': 'RadiusOfCircExposControlSensRegion',
    '00189447': 'ColumnAngulationPatient',
    '00189449': 'BeamAngle',
    '00189451': 'FrameDetectorParametersSequence',
    '00189452': 'CalculatedAnatomyThickness',
    '00189455': 'CalibrationSequence',
    '00189456': 'ObjectThicknessSequence',
    '00189457': 'PlaneIdentification',
    '00189461': 'FieldOfViewDimensionsInFloat',
    '00189462': 'IsocenterReferenceSystemSequence',
    '00189463': 'PositionerIsocenterPrimaryAngle',
    '00189464': 'PositionerIsocenterSecondaryAngle',
    '00189465': 'PositionerIsocenterDetRotAngle',
    '00189466': 'TableXPositionToIsocenter',
    '00189467': 'TableYPositionToIsocenter',
    '00189468': 'TableZPositionToIsocenter',
    '00189469': 'TableHorizontalRotationAngle',
    '00189470': 'TableHeadTiltAngle',
    '00189471': 'TableCradleTiltAngle',
    '00189472': 'FrameDisplayShutterSequence',
    '00189473': 'AcquiredImageAreaDoseProduct',
    '00189474': 'CArmPositionerTabletopRelationship',
    '00189476': 'XRayGeometrySequence',
    '00189477': 'IrradiationEventIDSequence',
    '00189504': 'XRay3DFrameTypeSequence',
    '00189506': 'ContributingSourcesSequence',
    '00189507': 'XRay3DAcquisitionSequence',
    '00189508': 'PrimaryPositionerScanArc',
    '00189509': 'SecondaryPositionerScanArc',
    '00189510': 'PrimaryPositionerScanStartAngle',
    '00189511': 'SecondaryPositionerScanStartAngle',
    '00189514': 'PrimaryPositionerIncrement',
    '00189515': 'SecondaryPositionerIncrement',
    '00189516': 'StartAcquisitionDateTime',
    '00189517': 'EndAcquisitionDateTime',
    '00189524': 'ApplicationName',
    '00189525': 'ApplicationVersion',
    '00189526': 'ApplicationManufacturer',
    '00189527': 'AlgorithmType',
    '00189528': 'AlgorithmDescription',
    '00189530': 'XRay3DReconstructionSequence',
    '00189531': 'ReconstructionDescription',
    '00189538': 'PerProjectionAcquisitionSequence',
    '00189601': 'DiffusionBMatrixSequence',
    '00189602': 'DiffusionBValueXX',
    '00189603': 'DiffusionBValueXY',
    '00189604': 'DiffusionBValueXZ',
    '00189605': 'DiffusionBValueYY',
    '00189606': 'DiffusionBValueYZ',
    '00189607': 'DiffusionBValueZZ',
    '00189701': 'DecayCorrectionDateTime',
    '00189715': 'StartDensityThreshold',
    '00189722': 'TerminationTimeThreshold',
    '00189725': 'DetectorGeometry',
    '00189727': 'AxialDetectorDimension',
    '00189735': 'PETPositionSequence',
    '00189739': 'NumberOfIterations',
    '00189740': 'NumberOfSubsets',
    '00189751': 'PETFrameTypeSequence',
    '00189756': 'ReconstructionType',
    '00189758': 'DecayCorrected',
    '00189759': 'AttenuationCorrected',
    '00189760': 'ScatterCorrected',
    '00189761': 'DeadTimeCorrected',
    '00189762': 'GantryMotionCorrected',
    '00189763': 'PatientMotionCorrected',
    '00189765': 'RandomsCorrected',
    '00189767': 'SensitivityCalibrated',
    '00189801': 'DepthsOfFocus',
    '00189804': 'ExclusionStartDatetime',
    '00189805': 'ExclusionDuration',
    '00189807': 'ImageDataTypeSequence',
    '00189808': 'DataType',
    '0018980B': 'AliasedDataType',
    '0018A001': 'ContributingEquipmentSequence',
    '0018A002': 'ContributionDateTime',
    '0018A003': 'ContributionDescription',
    '00191002': 'NumberOfCellsIInDetector',
    '00191003': 'CellNumberAtTheta',
    '00191004': 'CellSpacing',
    '0019100F': 'HorizFrameOfRef',
    '00191011': 'SeriesContrast',
    '00191012': 'LastPseq',
    '00191013': 'StartNumberForBaseline',
    '00191014': 'EndNumberForBaseline',
    '00191015': 'StartNumberForEnhancedScans',
    '00191016': 'EndNumberForEnhancedScans',
    '00191017': 'SeriesPlane',
    '00191018': 'FirstScanRas',
    '00191019': 'FirstScanLocation',
    '0019101A': 'LastScanRas',
    '0019101B': 'LastScanLoc',
    '0019101E': 'DisplayFieldOfView',
    '00191024': 'MidScanTime',
    '00191025': 'MidScanFlag',
    '00191026': 'DegreesOfAzimuth',
    '00191027': 'GantryPeriod',
    '0019102A': 'XRayOnPosition',
    '0019102B': 'XRayOffPosition',
    '0019102C': 'NumberOfTriggers',
    '0019102E': 'AngleOfFirstView',
    '0019102F': 'TriggerFrequency',
    '00191039': 'ScanFOVType',
    '00191040': 'StatReconFlag',
    '00191041': 'ComputeType',
    '00620004': 'SegmentNumber',
    '00191043': 'TotalSegmentsRequested',
    '00191044': 'InterscanDelay',
    '00191047': 'ViewCompressionFactor',
    '0019104A': 'TotalNoOfRefChannels',
    '0019104B': 'DataSizeForScanData',
    '00191052': 'ReconPostProcflag',
    '00191057': 'CTWaterNumber',
    '00191058': 'CTBoneNumber',
    '50..2004': 'NumberOfChannels',
    '0019105F': 'IncrementBetweenChannels',
    '00191060': 'StartingView',
    '00191061': 'NumberOfViews',
    '00191062': 'IncrementBetweenViews',
    '0019106A': 'DependantOnNoViewsProcessed',
    '0019106B': 'FieldOfViewInDetectorCells',
    '00191070': 'ValueOfBackProjectionButton',
    '00191071': 'SetIfFatqEstimatesWereUsed',
    '00191072': 'ZChanAvgOverViews',
    '00191073': 'AvgOfLeftRefChansOverViews',
    '00191074': 'MaxLeftChanOverViews',
    '00191075': 'AvgOfRightRefChansOverViews',
    '00191076': 'MaxRightChanOverViews',
    '0019107D': 'SecondEcho',
    '0019107E': 'NumberOfEchoes',
    '0019107F': 'TableDelta',
    '00191081': 'Contiguous',
    '00191084': 'PeakSAR',
    '00191085': 'MonitorSAR',
    '00191087': 'CardiacRepetitionTime',
    '00191088': 'ImagesPerCardiacCycle',
    '0019108A': 'ActualReceiveGainAnalog',
    '0019108B': 'ActualReceiveGainDigital',
    '0019108D': 'DelayAfterTrigger',
    '0019108F': 'Swappf',
    '00191090': 'PauseInterval',
    '00191091': 'PulseTime',
    '00191092': 'SliceOffsetOnFreqAxis',
    '00191093': 'CenterFrequency',
    '00191094': 'TransmitGain',
    '00191095': 'AnalogReceiverGain',
    '00191096': 'DigitalReceiverGain',
    '00191097': 'BitmapDefiningCVs',
    '00191098': 'CenterFreqMethod',
    '0019109B': 'PulseSeqMode',
    '0019109C': 'PulseSeqName',
    '0019109D': 'PulseSeqDate',
    '0019109E': 'InternalPulseSeqName',
    '0019109F': 'TransmittingCoil',
    '001910A0': 'SurfaceCoilType',
    '001910A1': 'ExtremityCoilFlag',
    '001910A2': 'RawDataRunNumber',
    '001910A3': 'CalibratedFieldStrength',
    '001910A4': 'SATFatWaterBone',
    '001910A5': 'ReceiveBandwidth',
    '001910A7': 'UserData01',
    '001910A8': 'UserData02',
    '001910A9': 'UserData03',
    '001910AA': 'UserData04',
    '001910AB': 'UserData05',
    '001910AC': 'UserData06',
    '001910AD': 'UserData07',
    '001910AE': 'UserData08',
    '001910AF': 'UserData09',
    '001910B0': 'UserData10',
    '001910B1': 'UserData11',
    '001910B2': 'UserData12',
    '001910B3': 'UserData13',
    '001910B4': 'UserData14',
    '001910B5': 'UserData15',
    '001910B6': 'UserData16',
    '001910B7': 'UserData17',
    '001910B8': 'UserData18',
    '001910B9': 'UserData19',
    '001910BA': 'UserData20',
    '001910BB': 'UserData21',
    '001910BC': 'UserData22',
    '001910BD': 'UserData23',
    '001910BE': 'ProjectionAngle',
    '001910C0': 'SaturationPlanes',
    '001910C1': 'SurfaceCoilIntensity',
    '001910C2': 'SATLocationR',
    '001910C3': 'SATLocationL',
    '001910C4': 'SATLocationA',
    '001910C5': 'SATLocationP',
    '001910C6': 'SATLocationH',
    '001910C7': 'SATLocationF',
    '001910C8': 'SATThicknessR-L',
    '001910C9': 'SATThicknessA-P',
    '001910CA': 'SATThicknessH-F',
    '001910CB': 'PrescribedFlowAxis',
    '001910CC': 'VelocityEncoding',
    '001910CD': 'ThicknessDisclaimer',
    '001910CE': 'PrescanType',
    '001910CF': 'PrescanStatus',
    '00431032': 'RawDataType',
    '001910D3': 'ProjectionAlgorithm',
    '001910D5': 'FractionalEcho',
    '001910D6': 'PrepPulse',
    '001910D7': 'CardiacPhases',
    '001910D8': 'VariableEchoflag',
    '001910D9': 'ConcatenatedSAT',
    '001910DA': 'ReferenceChannelUsed',
    '001910DB': 'BackProjectorCoefficient',
    '001910DC': 'PrimarySpeedCorrectionUsed',
    '001910DD': 'OverrangeCorrectionUsed',
    '001910DE': 'DynamicZAlphaValue',
    '001910E0': 'UserData',
    '001910E2': 'VelocityEncodeScale',
    '001910F2': 'FastPhases',
    '001910F9': 'TransmissionGain',
    '00200000': 'RelationshipGroupLength',
    '0020000D': 'StudyInstanceUID',
    '0020000E': 'SeriesInstanceUID',
    '00200010': 'StudyID',
    '00200011': 'SeriesNumber',
    '00200012': 'AcquisitionNumber',
    '00200013': 'InstanceNumber',
    '00200014': 'IsotopeNumber',
    '00200015': 'PhaseNumber',
    '00200016': 'IntervalNumber',
    '00200017': 'TimeSlotNumber',
    '00200018': 'AngleNumber',
    '00200019': 'ItemNumber',
    '00200020': 'PatientOrientation',
    '00200022': 'OverlayNumber',
    '00200024': 'CurveNumber',
    '00200026': 'LookupTableNumber',
    '00211054': 'ImagePosition',
    '00200032': 'ImagePositionPatient',
    '00211055': 'ImageOrientation',
    '00200037': 'ImageOrientationPatient',
    '00200050': 'Location',
    '00200052': 'FrameOfReferenceUID',
    '00200060': 'Laterality',
    '00200062': 'ImageLaterality',
    '00200070': 'ImageGeometryType',
    '00200080': 'MaskingImage',
    '00200100': 'TemporalPositionIdentifier',
    '00200105': 'NumberOfTemporalPositions',
    '00200110': 'TemporalResolution',
    '00200200': 'SynchronizationFrameOfReferenceUID',
    '00201000': 'SeriesInStudy',
    '00201001': 'AcquisitionsInSeries',
    '00201002': 'ImagesInAcquisition',
    '00251007': 'ImagesInSeries',
    '00201004': 'AcquisitionsInStudy',
    '00201005': 'ImagesInStudy',
    '00201020': 'Reference',
    '00201040': 'PositionReferenceIndicator',
    '00201041': 'SliceLocation',
    '00201070': 'OtherStudyNumbers',
    '00201200': 'NumberOfPatientRelatedStudies',
    '00201202': 'NumberOfPatientRelatedSeries',
    '00201204': 'NumberOfPatientRelatedInstances',
    '00201206': 'NumberOfStudyRelatedSeries',
    '00201208': 'NumberOfStudyRelatedInstances',
    '00201209': 'NumberOfSeriesRelatedInstances',
    '002031..': 'SourceImageIDs',
    '00203401': 'ModifyingDeviceID',
    '00203402': 'ModifiedImageID',
    '00203403': 'ModifiedImageDate',
    '00203404': 'ModifyingDeviceManufacturer',
    '00203405': 'ModifiedImageTime',
    '00203406': 'ModifiedImageDescription',
    '00204000': 'ImageComments',
    '00205000': 'OriginalImageIdentification',
    '00205002': 'OriginalImageIdentNomenclature',
    '00209056': 'StackID',
    '00209057': 'InStackPositionNumber',
    '00209071': 'FrameAnatomySequence',
    '00209072': 'FrameLaterality',
    '00209111': 'FrameContentSequence',
    '00209113': 'PlanePositionSequence',
    '00209116': 'PlaneOrientationSequence',
    '00209128': 'TemporalPositionIndex',
    '00209153': 'TriggerDelayTime',
    '00209156': 'FrameAcquisitionNumber',
    '00209157': 'DimensionIndexValues',
    '00209158': 'FrameComments',
    '00209161': 'ConcatenationUID',
    '00209162': 'InConcatenationNumber',
    '00209163': 'InConcatenationTotalNumber',
    '00209164': 'DimensionOrganizationUID',
    '00209165': 'DimensionIndexPointer',
    '00209167': 'FunctionalGroupPointer',
    '00209213': 'DimensionIndexPrivateCreator',
    '00209221': 'DimensionOrganizationSequence',
    '00209222': 'DimensionIndexSequence',
    '00209228': 'ConcatenationFrameOffsetNumber',
    '00209238': 'FunctionalGroupPrivateCreator',
    '00209241': 'NominalPercentageOfCardiacPhase',
    '00209245': 'NominalPercentOfRespiratoryPhase',
    '00209246': 'StartingRespiratoryAmplitude',
    '00209247': 'StartingRespiratoryPhase',
    '00209248': 'EndingRespiratoryAmplitude',
    '00209249': 'EndingRespiratoryPhase',
    '00209250': 'RespiratoryTriggerType',
    '00209251': 'RRIntervalTimeNominal',
    '00209252': 'ActualCardiacTriggerDelayTime',
    '00209253': 'RespiratorySynchronizationSequence',
    '00209254': 'RespiratoryIntervalTime',
    '00209255': 'NominalRespiratoryTriggerDelayTime',
    '00209256': 'RespiratoryTriggerDelayThreshold',
    '00209257': 'ActualRespiratoryTriggerDelayTime',
    '00209301': 'ImagePositionVolume',
    '00209302': 'ImageOrientationVolume',
    '00209308': 'ApexPosition',
    '00209421': 'DimensionDescriptionLabel',
    '00209450': 'PatientOrientationInFrameSequence',
    '00209453': 'FrameLabel',
    '00209518': 'AcquisitionIndex',
    '00209529': 'ContributingSOPInstancesRefSeq',
    '00209536': 'ReconstructionIndex',
    '00211035': 'SeriesFromWhichPrescribed',
    '00211007': 'SeriesRecordChecksum',
    '00211019': 'AcqreconRecordChecksum',
    '00271050': 'TableStartLocation',
    '00211036': 'ImageFromWhichPrescribed',
    '00211037': 'ScreenFormat',
    '0021104A': 'AnatomicalReferenceForScout',
    '0021104F': 'LocationsInAcquisition',
    '00211050': 'GraphicallyPrescribed',
    '00211051': 'RotationFromSourceXRot',
    '00211052': 'RotationFromSourceYRot',
    '00211053': 'RotationFromSourceZRot',
    '0021105A': 'IntegerSlop',
    '0021105F': 'FloatSlop',
    '00211081': 'AutoWindowLevelAlpha',
    '00211082': 'AutoWindowLevelBeta',
    '00211083': 'AutoWindowLevelWindow',
    '00211084': 'ToWindowLevelLevel',
    '00211090': 'TubeFocalSpotPosition',
    '00211091': 'BiopsyPosition',
    '00211092': 'BiopsyTLocation',
    '00211093': 'BiopsyRefLocation',
    '00220001': 'LightPathFilterPassThroughWavelen',
    '00220002': 'LightPathFilterPassBand',
    '00220003': 'ImagePathFilterPassThroughWavelen',
    '00220004': 'ImagePathFilterPassBand',
    '00220005': 'PatientEyeMovementCommanded',
    '00220006': 'PatientEyeMovementCommandCodeSeq',
    '00220007': 'SphericalLensPower',
    '00220008': 'CylinderLensPower',
    '00220009': 'CylinderAxis',
    '0022000A': 'EmmetropicMagnification',
    '0022000B': 'IntraOcularPressure',
    '0022000C': 'HorizontalFieldOfView',
    '0022000D': 'PupilDilated',
    '0022000E': 'DegreeOfDilation',
    '00220010': 'StereoBaselineAngle',
    '00220011': 'StereoBaselineDisplacement',
    '00220012': 'StereoHorizontalPixelOffset',
    '00220013': 'StereoVerticalPixelOffset',
    '00220014': 'StereoRotation',
    '00220015': 'AcquisitionDeviceTypeCodeSequence',
    '00220016': 'IlluminationTypeCodeSequence',
    '00220017': 'LightPathFilterTypeStackCodeSeq',
    '00220018': 'ImagePathFilterTypeStackCodeSeq',
    '00220019': 'LensesCodeSequence',
    '0022001A': 'ChannelDescriptionCodeSequence',
    '0022001B': 'RefractiveStateSequence',
    '0022001C': 'MydriaticAgentCodeSequence',
    '0022001D': 'RelativeImagePositionCodeSequence',
    '00220020': 'StereoPairsSequence',
    '00220021': 'LeftImageSequence',
    '00220022': 'RightImageSequence',
    '00220030': 'AxialLengthOfTheEye',
    '00220031': 'OphthalmicFrameLocationSequence',
    '00220032': 'ReferenceCoordinates',
    '00220035': 'DepthSpatialResolution',
    '00220036': 'MaximumDepthDistortion',
    '00220037': 'AlongScanSpatialResolution',
    '00220038': 'MaximumAlongScanDistortion',
    '00220039': 'OphthalmicImageOrientation',
    '00220041': 'DepthOfTransverseImage',
    '00220042': 'MydriaticAgentConcUnitsSeq',
    '00220048': 'AcrossScanSpatialResolution',
    '00220049': 'MaximumAcrossScanDistortion',
    '0022004E': 'MydriaticAgentConcentration',
    '00220055': 'IlluminationWaveLength',
    '00220056': 'IlluminationPower',
    '00220057': 'IlluminationBandwidth',
    '00220058': 'MydriaticAgentSequence',
    '00231001': 'NumberOfSeriesInStudy',
    '00231002': 'NumberOfUnarchivedSeries',
    '00231010': 'ReferenceImageField',
    '00231050': 'SummaryImage',
    '00231070': 'StartTimeSecsInFirstAxial',
    '00231074': 'NoofUpdatesToHeader',
    '0023107D': 'IndicatesIfTheStudyHasCompleteInfo',
    '00251006': 'LastPulseSequenceUsed',
    '00251010': 'LandmarkCounter',
    '00251011': 'NumberOfAcquisitions',
    '00251014': 'IndicatesNoofUpdatesToHeader',
    '00251017': 'SeriesCompleteFlag',
    '00251018': 'NumberOfImagesArchived',
    '00251019': 'LastImageNumberUsed',
    '0025101A': 'PrimaryReceiverSuiteAndHost',
    '00271006': 'ImageArchiveFlag',
    '00271010': 'ScoutType',
    '0027101C': 'VmaMamp',
    '0027101D': 'VmaPhase',
    '0027101E': 'VmaMod',
    '0027101F': 'VmaClip',
    '00271020': 'SmartScanOnOffFlag',
    '00271030': 'ForeignImageRevision',
    '00271031': 'ImagingMode',
    '00271032': 'PulseSequence',
    '00271033': 'ImagingOptions',
    '00271035': 'PlaneType',
    '00271036': 'ObliquePlane',
    '00271040': 'RASLetterOfImageLocation',
    '00280200': 'ImageLocation',
    '00271042': 'CenterRCoordOfPlaneImage',
    '00271043': 'CenterACoordOfPlaneImage',
    '00271044': 'CenterSCoordOfPlaneImage',
    '00271045': 'NormalRCoord',
    '00271046': 'NormalACoord',
    '00271047': 'NormalSCoord',
    '00271048': 'RCoordOfTopRightCorner',
    '00271049': 'ACoordOfTopRightCorner',
    '0027104A': 'SCoordOfTopRightCorner',
    '0027104B': 'RCoordOfBottomRightCorner',
    '0027104C': 'ACoordOfBottomRightCorner',
    '0027104D': 'SCoordOfBottomRightCorner',
    '00271051': 'TableEndLocation',
    '00271052': 'RASLetterForSideOfImage',
    '00271053': 'RASLetterForAnteriorPosterior',
    '00271054': 'RASLetterForScoutStartLoc',
    '00271055': 'RASLetterForScoutEndLoc',
    '00271060': 'ImageDimensionX',
    '00271061': 'ImageDimensionY',
    '00271062': 'NumberOfExcitations',
    '00280000': 'ImagePresentationGroupLength',
    '00280002': 'SamplesPerPixel',
    '00280003': 'SamplesPerPixelUsed',
    '00280004': 'PhotometricInterpretation',
    '00280005': 'ImageDimensions',
    '00280006': 'PlanarConfiguration',
    '00280008': 'NumberOfFrames',
    '00280009': 'FrameIncrementPointer',
    '0028000A': 'FrameDimensionPointer',
    '00280010': 'Rows',
    '00280011': 'Columns',
    '00280012': 'Planes',
    '00280014': 'UltrasoundColorDataPresent',
    '00280030': 'PixelSpacing',
    '00280031': 'ZoomFactor',
    '00280032': 'ZoomCenter',
    '00280034': 'PixelAspectRatio',
    '00280040': 'ImageFormat',
    '00280050': 'ManipulatedImage',
    '00280051': 'CorrectedImage',
    '0028005F': 'CompressionRecognitionCode',
    '00280060': 'CompressionCode',
    '00280061': 'CompressionOriginator',
    '00280062': 'CompressionLabel',
    '00280063': 'CompressionDescription',
    '00280065': 'CompressionSequence',
    '00280066': 'CompressionStepPointers',
    '00280068': 'RepeatInterval',
    '00280069': 'BitsGrouped',
    '00280070': 'PerimeterTable',
    '00280071': 'PerimeterValue',
    '00280080': 'PredictorRows',
    '00280081': 'PredictorColumns',
    '00280082': 'PredictorConstants',
    '00280090': 'BlockedPixels',
    '00280091': 'BlockRows',
    '00280092': 'BlockColumns',
    '00280093': 'RowOverlap',
    '00280094': 'ColumnOverlap',
    '00280100': 'BitsAllocated',
    '00280101': 'BitsStored',
    '00280102': 'HighBit',
    '00280103': 'PixelRepresentation',
    '': '',
    '00280104': 'SmallestValidPixelValue',
    '00280105': 'LargestValidPixelValue',
    '00280106': 'SmallestImagePixelValue',
    '00280107': 'LargestImagePixelValue',
    '00280108': 'SmallestPixelValueInSeries',
    '00280109': 'LargestPixelValueInSeries',
    '00280110': 'SmallestImagePixelValueInPlane',
    '00280111': 'LargestImagePixelValueInPlane',
    '00280120': 'PixelPaddingValue',
    '00280121': 'PixelPaddingRangeLimit',
    '00280300': 'QualityControlImage',
    '00280301': 'BurnedInAnnotation',
    '00280400': 'TransformLabel',
    '00280401': 'TransformVersionNumber',
    '00280402': 'NumberOfTransformSteps',
    '00280403': 'SequenceOfCompressedData',
    '00280404': 'DetailsOfCoefficients',
    '002804x2': 'CoefficientCoding',
    '002804x3': 'CoefficientCodingPointers',
    '00280700': 'DCTLabel',
    '00280701': 'DataBlockDescription',
    '00280702': 'DataBlock',
    '00280710': 'NormalizationFactorFormat',
    '00280720': 'ZonalMapNumberFormat',
    '00280721': 'ZonalMapLocation',
    '00280722': 'ZonalMapFormat',
    '00280730': 'AdaptiveMapFormat',
    '00280740': 'CodeNumberFormat',
    '002808x0': 'CodeLabel',
    '002808x2': 'NumberOfTables',
    '002808x3': 'CodeTableLocation',
    '002808x4': 'BitsForCodeWord',
    '002808x8': 'ImageDataLocation',
    '00280A02': 'PixelSpacingCalibrationType',
    '00280A04': 'PixelSpacingCalibrationDescription',
    '00281040': 'PixelIntensityRelationship',
    '00281041': 'PixelIntensityRelationshipSign',
    '00281050': 'WindowCenter',
    '00281051': 'WindowWidth',
    '00281052': 'RescaleIntercept',
    '00281053': 'RescaleSlope',
    '00281054': 'RescaleType',
    '00281055': 'WindowCenterAndWidthExplanation',
    '00281056': 'VOI_LUTFunction',
    '00281080': 'GrayScale',
    '00281090': 'RecommendedViewingMode',
    '00281100': 'GrayLookupTableDescriptor',
    '00281101': 'RedPaletteColorTableDescriptor',
    '00281102': 'GreenPaletteColorTableDescriptor',
    '00281103': 'BluePaletteColorTableDescriptor',
    '00281111': 'LargeRedPaletteColorTableDescr',
    '00281112': 'LargeGreenPaletteColorTableDescr',
    '00281113': 'LargeBluePaletteColorTableDescr',
    '00281199': 'PaletteColorTableUID',
    '00281200': 'GrayLookupTableData',
    '00281201': 'RedPaletteColorTableData',
    '00281202': 'GreenPaletteColorTableData',
    '00281203': 'BluePaletteColorTableData',
    '00281211': 'LargeRedPaletteColorTableData',
    '00281212': 'LargeGreenPaletteColorTableData',
    '00281213': 'LargeBluePaletteColorTableData',
    '00281214': 'LargePaletteColorLookupTableUID',
    '00281221': 'SegmentedRedColorTableData',
    '00281222': 'SegmentedGreenColorTableData',
    '00281223': 'SegmentedBlueColorTableData',
    '00281300': 'BreastImplantPresent',
    '00281350': 'PartialView',
    '00281351': 'PartialViewDescription',
    '00281352': 'PartialViewCodeSequence',
    '0028135A': 'SpatialLocationsPreserved',
    '00281402': 'DataPathAssignment',
    '00281404': 'BlendingLUT1Sequence',
    '00281406': 'BlendingWeightConstant',
    '00281408': 'BlendingLookupTableData',
    '0028140C': 'BlendingLUT2Sequence',
    '0028140E': 'DataPathID',
    '0028140F': 'RGBLUTTransferFunction',
    '00281410': 'AlphaLUTTransferFunction',
    '00282000': 'ICCProfile',
    '00282112': 'LossyImageCompressionRatio',
    '00282114': 'LossyImageCompressionMethod',
    '00283000': 'ModalityLUTSequence',
    '00283002': 'LUTDescriptor',
    '00283003': 'LUTExplanation',
    '00283004': 'ModalityLUTType',
    '00283006': 'LUTData',
    '00283010': 'VOILUTSequence',
    '00283110': 'SoftcopyVOILUTSequence',
    '00284000': 'ImagePresentationComments',
    '00285000': 'BiPlaneAcquisitionSequence',
    '00286010': 'RepresentativeFrameNumber',
    '00286020': 'FrameNumbersOfInterest',
    '00286022': 'FrameOfInterestDescription',
    '00286023': 'FrameOfInterestType',
    '00286030': 'MaskPointers',
    '00286040': 'RWavePointer',
    '00286100': 'MaskSubtractionSequence',
    '00286101': 'MaskOperation',
    '00286102': 'ApplicableFrameRange',
    '00286110': 'MaskFrameNumbers',
    '00286112': 'ContrastFrameAveraging',
    '00286114': 'MaskSubPixelShift',
    '00286120': 'TIDOffset',
    '00286190': 'MaskOperationExplanation',
    '00287FE0': 'PixelDataProviderURL',
    '00289001': 'DataPointRows',
    '00289002': 'DataPointColumns',
    '00289003': 'SignalDomainColumns',
    '00289099': 'LargestMonochromePixelValue',
    '00289108': 'DataRepresentation',
    '00289110': 'PixelMeasuresSequence',
    '00289132': 'FrameVOILUTSequence',
    '00289145': 'PixelValueTransformationSequence',
    '00289235': 'SignalDomainRows',
    '00289411': 'DisplayFilterPercentage',
    '00289415': 'FramePixelShiftSequence',
    '00289416': 'SubtractionItemID',
    '00289422': 'PixelIntensityRelationshipLUTSeq',
    '00289443': 'FramePixelDataPropertiesSequence',
    '00289444': 'GeometricalProperties',
    '00289445': 'GeometricMaximumDistortion',
    '00289446': 'ImageProcessingApplied',
    '00289454': 'MaskSelectionMode',
    '00289474': 'LUTFunction',
    '00289478': 'MaskVisibilityPercentage',
    '00289501': 'PixelShiftSequence',
    '00289502': 'RegionPixelShiftSequence',
    '00289503': 'VerticesOfTheRegion',
    '00289506': 'PixelShiftFrameRange',
    '00289507': 'LUTFrameRange',
    '00289520': 'ImageToEquipmentMappingMatrix',
    '00289537': 'EquipmentCoordinateSystemID',
    '00291004': 'LowerRangeOfPixels1a',
    '00291005': 'LowerRangeOfPixels1b',
    '00291006': 'LowerRangeOfPixels1c',
    '00291007': 'LowerRangeOfPixels1d',
    '00291008': 'LowerRangeOfPixels1e',
    '00291009': 'LowerRangeOfPixels1f',
    '0029100A': 'LowerRangeOfPixels1g',
    '00291015': 'LowerRangeOfPixels1h',
    '00291016': 'LowerRangeOfPixels1i',
    '00291017': 'LowerRangeOfPixels2',
    '00291018': 'UpperRangeOfPixels2',
    '0029101A': 'LenOfTotHdrInBytes',
    '00291026': 'VersionOfTheHdrStruct',
    '00291034': 'AdvantageCompOverflow',
    '00291035': 'AdvantageCompUnderflow',
    '00320000': 'StudyGroupLength',
    '0032000A': 'StudyStatusID',
    '0032000C': 'StudyPriorityID',
    '00320012': 'StudyIDIssuer',
    '00320032': 'StudyVerifiedDate',
    '00320033': 'StudyVerifiedTime',
    '00320034': 'StudyReadDate',
    '00320035': 'StudyReadTime',
    '00321000': 'ScheduledStudyStartDate',
    '00321001': 'ScheduledStudyStartTime',
    '00321010': 'ScheduledStudyStopDate',
    '00321011': 'ScheduledStudyStopTime',
    '00321020': 'ScheduledStudyLocation',
    '00321021': 'ScheduledStudyLocationAETitle',
    '00321030': 'ReasonForStudy',
    '00321031': 'RequestingPhysicianIDSequence',
    '00321032': 'RequestingPhysician',
    '00321033': 'RequestingService',
    '00321040': 'StudyArrivalDate',
    '00321041': 'StudyArrivalTime',
    '00321050': 'StudyCompletionDate',
    '00321051': 'StudyCompletionTime',
    '00321055': 'StudyComponentStatusID',
    '00321060': 'RequestedProcedureDescription',
    '00321064': 'RequestedProcedureCodeSequence',
    '00321070': 'RequestedContrastAgent',
    '00324000': 'StudyComments',
    '00380004': 'ReferencedPatientAliasSequence',
    '00380008': 'VisitStatusID',
    '00380010': 'AdmissionID',
    '00380011': 'IssuerOfAdmissionID',
    '00380016': 'RouteOfAdmissions',
    '0038001A': 'ScheduledAdmissionDate',
    '0038001B': 'ScheduledAdmissionTime',
    '0038001C': 'ScheduledDischargeDate',
    '0038001D': 'ScheduledDischargeTime',
    '0038001E': 'ScheduledPatientInstitResidence',
    '00380020': 'AdmittingDate',
    '00380021': 'AdmittingTime',
    '00380030': 'DischargeDate',
    '00380032': 'DischargeTime',
    '00380040': 'DischargeDiagnosisDescription',
    '00380044': 'DischargeDiagnosisCodeSequence',
    '00380050': 'SpecialNeeds',
    '00380060': 'ServiceEpisodeID',
    '00380061': 'IssuerOfServiceEpisodeID',
    '00380062': 'ServiceEpisodeDescription',
    '00380100': 'PertinentDocumentsSequence',
    '00380300': 'CurrentPatientLocation',
    '00380400': 'PatientInstitutionResidence',
    '00380500': 'PatientState',
    '00380502': 'PatientClinicalTrialParticipSeq',
    '00384000': 'VisitComments',
    '003A0004': 'WaveformOriginality',
    '003A0005': 'NumberOfWaveformChannels',
    '003A0010': 'NumberOfWaveformSamples',
    '003A001A': 'SamplingFrequency',
    '003A0020': 'MultiplexGroupLabel',
    '003A0200': 'ChannelDefinitionSequence',
    '003A0202': 'WaveformChannelNumber',
    '003A0203': 'ChannelLabel',
    '003A0205': 'ChannelStatus',
    '003A0208': 'ChannelSourceSequence',
    '003A0209': 'ChannelSourceModifiersSequence',
    '003A020A': 'SourceWaveformSequence',
    '003A020C': 'ChannelDerivationDescription',
    '003A0210': 'ChannelSensitivity',
    '003A0211': 'ChannelSensitivityUnitsSequence',
    '003A0212': 'ChannelSensitivityCorrectionFactor',
    '003A0213': 'ChannelBaseline',
    '003A0214': 'ChannelTimeSkew',
    '003A0215': 'ChannelSampleSkew',
    '003A0218': 'ChannelOffset',
    '003A021A': 'WaveformBitsStored',
    '003A0220': 'FilterLowFrequency',
    '003A0221': 'FilterHighFrequency',
    '003A0222': 'NotchFilterFrequency',
    '003A0223': 'NotchFilterBandwidth',
    '003A0230': 'WaveformDataDisplayScale',
    '003A0231': 'WaveformDisplayBkgCIELabValue',
    '003A0240': 'WaveformPresentationGroupSequence',
    '003A0241': 'PresentationGroupNumber',
    '003A0242': 'ChannelDisplaySequence',
    '003A0244': 'ChannelRecommendDisplayCIELabValue',
    '003A0245': 'ChannelPosition',
    '003A0246': 'DisplayShadingFlag',
    '003A0247': 'FractionalChannelDisplayScale',
    '003A0248': 'AbsoluteChannelDisplayScale',
    '003A0300': 'MultiplexAudioChannelsDescrCodeSeq',
    '003A0301': 'ChannelIdentificationCode',
    '003A0302': 'ChannelMode',
    '00400001': 'ScheduledStationAETitle',
    '00400002': 'ScheduledProcedureStepStartDate',
    '00400003': 'ScheduledProcedureStepStartTime',
    '00400004': 'ScheduledProcedureStepEndDate',
    '00400005': 'ScheduledProcedureStepEndTime',
    '00400006': 'ScheduledPerformingPhysiciansName',
    '00400007': 'ScheduledProcedureStepDescription',
    '00400008': 'ScheduledProtocolCodeSequence',
    '00400009': 'ScheduledProcedureStepID',
    '0040000A': 'StageCodeSequence',
    '0040000B': 'ScheduledPerformingPhysicianIDSeq',
    '00400010': 'ScheduledStationName',
    '00400011': 'ScheduledProcedureStepLocation',
    '00400012': 'PreMedication',
    '00400020': 'ScheduledProcedureStepStatus',
    '00400031': 'LocalNamespaceEntityID',
    '00400032': 'UniversalEntityID',
    '00400033': 'UniversalEntityIDType',
    '00400035': 'IdentifierTypeCode',
    '00400036': 'AssigningFacilitySequence',
    '00400100': 'ScheduledProcedureStepSequence',
    '00400220': 'ReferencedNonImageCompositeSOPSeq',
    '00400241': 'PerformedStationAETitle',
    '00400242': 'PerformedStationName',
    '00400243': 'PerformedLocation',
    '00400244': 'PerformedProcedureStepStartDate',
    '00400245': 'PerformedProcedureStepStartTime',
    '00400250': 'PerformedProcedureStepEndDate',
    '00400251': 'PerformedProcedureStepEndTime',
    '00400252': 'PerformedProcedureStepStatus',
    '00400253': 'PerformedProcedureStepID',
    '00400254': 'PerformedProcedureStepDescription',
    '00400255': 'PerformedProcedureTypeDescription',
    '00400260': 'PerformedProtocolCodeSequence',
    '00400261': 'PerformedProtocolType',
    '00400270': 'ScheduledStepAttributesSequence',
    '00400275': 'RequestAttributesSequence',
    '00400280': 'CommentsOnPerformedProcedureStep',
    '00400281': 'ProcStepDiscontinueReasonCodeSeq',
    '00400293': 'QuantitySequence',
    '00400294': 'Quantity',
    '00400295': 'MeasuringUnitsSequence',
    '00400296': 'BillingItemSequence',
    '00400300': 'TotalTimeOfFluoroscopy',
    '00400301': 'TotalNumberOfExposures',
    '00400302': 'EntranceDose',
    '00400303': 'ExposedArea',
    '00400306': 'DistanceSourceToEntrance',
    '00400307': 'DistanceSourceToSupport',
    '0040030E': 'ExposureDoseSequence',
    '00400310': 'CommentsOnRadiationDose',
    '00400312': 'XRayOutput',
    '00400314': 'HalfValueLayer',
    '00400316': 'OrganDose',
    '00400318': 'OrganExposed',
    '00400320': 'BillingProcedureStepSequence',
    '00400321': 'FilmConsumptionSequence',
    '00400324': 'BillingSuppliesAndDevicesSequence',
    '00400340': 'PerformedSeriesSequence',
    '00400400': 'CommentsOnScheduledProcedureStep',
    '00400440': 'ProtocolContextSequence',
    '00400441': 'ContentItemModifierSequence',
    '0040050A': 'SpecimenAccessionNumber',
    '00400512': 'ContainerIdentifier',
    '0040051A': 'ContainerDescription',
    '00400550': 'SpecimenSequence',
    '00400551': 'SpecimenIdentifier',
    '00400552': 'SpecimenDescriptionSequenceTrial',
    '00400553': 'SpecimenDescriptionTrial',
    '00400554': 'SpecimenUID',
    '00400555': 'AcquisitionContextSequence',
    '00400556': 'AcquisitionContextDescription',
    '0040059A': 'SpecimenTypeCodeSequence',
    '00400600': 'SpecimenShortDescription',
    '004006FA': 'SlideIdentifier',
    '0040071A': 'ImageCenterPointCoordinatesSeq',
    '0040072A': 'XOffsetInSlideCoordinateSystem',
    '0040073A': 'YOffsetInSlideCoordinateSystem',
    '0040074A': 'ZOffsetInSlideCoordinateSystem',
    '004008D8': 'PixelSpacingSequence',
    '004008DA': 'CoordinateSystemAxisCodeSequence',
    '004008EA': 'MeasurementUnitsCodeSequence',
    '004009F8': 'VitalStainCodeSequenceTrial',
    '00401001': 'RequestedProcedureID',
    '00401002': 'ReasonForRequestedProcedure',
    '00401003': 'RequestedProcedurePriority',
    '00401004': 'PatientTransportArrangements',
    '00401005': 'RequestedProcedureLocation',
    '00401006': 'PlacerOrderNumber-Procedure',
    '00401007': 'FillerOrderNumber-Procedure',
    '00401008': 'ConfidentialityCode',
    '00401009': 'ReportingPriority',
    '0040100A': 'ReasonForRequestedProcedureCodeSeq',
    '00401010': 'NamesOfIntendedRecipientsOfResults',
    '00401011': 'IntendedRecipientsOfResultsIDSeq',
    '00401101': 'PersonIdentificationCodeSequence',
    '00401102': 'PersonAddress',
    '00401103': 'PersonTelephoneNumbers',
    '00401400': 'RequestedProcedureComments',
    '00402001': 'ReasonForImagingServiceRequest',
    '00402004': 'IssueDateOfImagingServiceRequest',
    '00402005': 'IssueTimeOfImagingServiceRequest',
    '00402016': 'PlacerOrderNum-ImagingServiceReq',
    '00402017': 'FillerOrderNum-ImagingServiceReq',
    '00402008': 'OrderEnteredBy',
    '00402009': 'OrderEntererLocation',
    '00402010': 'OrderCallbackPhoneNumber',
    '00402400': 'ImagingServiceRequestComments',
    '00403001': 'ConfidentialityOnPatientDataDescr',
    '00404001': 'GenPurposeScheduledProcStepStatus',
    '00404002': 'GenPurposePerformedProcStepStatus',
    '00404003': 'GenPurposeSchedProcStepPriority',
    '00404004': 'SchedProcessingApplicationsCodeSeq',
    '00404005': 'SchedProcedureStepStartDateAndTime',
    '00404006': 'MultipleCopiesFlag',
    '00404007': 'PerformedProcessingAppsCodeSeq',
    '00404009': 'HumanPerformerCodeSequence',
    '00404010': 'SchedProcStepModificationDateTime',
    '00404011': 'ExpectedCompletionDateAndTime',
    '00404015': 'ResultingGenPurposePerfProcStepSeq',
    '00404016': 'RefGenPurposeSchedProcStepSeq',
    '00404018': 'ScheduledWorkitemCodeSequence',
    '00404019': 'PerformedWorkitemCodeSequence',
    '00404020': 'InputAvailabilityFlag',
    '00404021': 'InputInformationSequence',
    '00404022': 'RelevantInformationSequence',
    '00404023': 'RefGenPurSchedProcStepTransUID',
    '00404025': 'ScheduledStationNameCodeSequence',
    '00404026': 'ScheduledStationClassCodeSequence',
    '00404027': 'SchedStationGeographicLocCodeSeq',
    '00404028': 'PerformedStationNameCodeSequence',
    '00404029': 'PerformedStationClassCodeSequence',
    '00404030': 'PerformedStationGeogLocCodeSeq',
    '00404031': 'RequestedSubsequentWorkItemCodeSeq',
    '00404032': 'NonDICOMOutputCodeSequence',
    '00404033': 'OutputInformationSequence',
    '00404034': 'ScheduledHumanPerformersSequence',
    '00404035': 'ActualHumanPerformersSequence',
    '00404036': 'HumanPerformersOrganization',
    '00404037': 'HumanPerformerName',
    '00404040': 'RawDataHandling',
    '00408302': 'EntranceDoseInMilliGy',
    '00409094': 'RefImageRealWorldValueMappingSeq',
    '00409096': 'RealWorldValueMappingSequence',
    '00409098': 'PixelValueMappingCodeSequence',
    '00409210': 'LUTLabel',
    '00409211': 'RealWorldValueLastValueMapped',
    '00409212': 'RealWorldValueLUTData',
    '00409216': 'RealWorldValueFirstValueMapped',
    '00409224': 'RealWorldValueIntercept',
    '00409225': 'RealWorldValueSlope',
    '0040A010': 'RelationshipType',
    '0040A027': 'VerifyingOrganization',
    '0040A030': 'VerificationDateTime',
    '0040A032': 'ObservationDateTime',
    '0040A040': 'ValueType',
    '0040A043': 'ConceptNameCodeSequence',
    '0040A050': 'ContinuityOfContent',
    '0040A073': 'VerifyingObserverSequence',
    '0040A075': 'VerifyingObserverName',
    '0040A078': 'AuthorObserverSequence',
    '0040A07A': 'ParticipantSequence',
    '0040A07C': 'CustodialOrganizationSequence',
    '0040A080': 'ParticipationType',
    '0040A082': 'ParticipationDateTime',
    '0040A084': 'ObserverType',
    '0040A088': 'VerifyingObserverIdentCodeSequence',
    '0040A090': 'EquivalentCDADocumentSequence',
    '0040A0B0': 'ReferencedWaveformChannels',
    '0040A120': 'DateTime',
    '0040A121': 'Date',
    '0040A122': 'Time',
    '0040A123': 'PersonName',
    '0040A124': 'UID',
    '0040A130': 'TemporalRangeType',
    '0040A132': 'ReferencedSamplePositions',
    '0040A136': 'ReferencedFrameNumbers',
    '0040A138': 'ReferencedTimeOffsets',
    '0040A13A': 'ReferencedDateTime',
    '0040A160': 'TextValue',
    '0040A168': 'ConceptCodeSequence',
    '0040A170': 'PurposeOfReferenceCodeSequence',
    '0040A180': 'AnnotationGroupNumber',
    '0040A195': 'ModifierCodeSequence',
    '0040A300': 'MeasuredValueSequence',
    '0040A301': 'NumericValueQualifierCodeSequence',
    '0040A30A': 'NumericValue',
    '0040A353': 'AddressTrial',
    '0040A354': 'TelephoneNumberTrial',
    '0040A360': 'PredecessorDocumentsSequence',
    '0040A370': 'ReferencedRequestSequence',
    '0040A372': 'PerformedProcedureCodeSequence',
    '0040A375': 'CurrentRequestedProcEvidenceSeq',
    '0040A385': 'PertinentOtherEvidenceSequence',
    '0040A390': 'HL7StructuredDocumentRefSeq',
    '0040A491': 'CompletionFlag',
    '0040A492': 'CompletionFlagDescription',
    '0040A493': 'VerificationFlag',
    '0040A494': 'ArchiveRequested',
    '0040A496': 'PreliminaryFlag',
    '0040A504': 'ContentTemplateSequence',
    '0040A525': 'IdenticalDocumentsSequence',
    '0040A730': 'ContentSequence',
    '0040B020': 'AnnotationSequence',
    '0040DB00': 'TemplateIdentifier',
    '0040DB06': 'TemplateVersion',
    '0040DB07': 'TemplateLocalVersion',
    '0040DB0B': 'TemplateExtensionFlag',
    '0040DB0C': 'TemplateExtensionOrganizationUID',
    '0040DB0D': 'TemplateExtensionCreatorUID',
    '0040DB73': 'ReferencedContentItemIdentifier',
    '0040E001': 'HL7InstanceIdentifier',
    '0040E004': 'HL7DocumentEffectiveTime',
    '0040E006': 'HL7DocumentTypeCodeSequence',
    '0040E010': 'RetrieveURI',
    '0040E011': 'RetrieveLocationUID',
    '00420010': 'DocumentTitle',
    '00420011': 'EncapsulatedDocument',
    '00420012': 'MIMETypeOfEncapsulatedDocument',
    '00420013': 'SourceInstanceSequence',
    '00420014': 'ListOfMIMETypes',
    '00431001': 'BitmapOfPrescanOptions',
    '00431002': 'GradientOffsetInX',
    '00431003': 'GradientOffsetInY',
    '00431004': 'GradientOffsetInZ',
    '00431005': 'ImgIsOriginalOrUnoriginal',
    '00431006': 'NumberOfEPIShots',
    '00431007': 'ViewsPerSegment',
    '00431008': 'RespiratoryRateBpm',
    '00431009': 'RespiratoryTriggerPoint',
    '0043100A': 'TypeOfReceiverUsed',
    '0043100B': 'PeakRateOfChangeOfGradientField',
    '0043100C': 'LimitsInUnitsOfPercent',
    '0043100D': 'PSDEstimatedLimit',
    '0043100E': 'PSDEstimatedLimitInTeslaPerSecond',
    '0043100F': 'Saravghead',
    '00431010': 'WindowValue',
    '00431011': 'TotalInputViews',
    '00431012': 'X-RayChain',
    '00431013': 'DeconKernelParameters',
    '00431014': 'CalibrationParameters',
    '00431015': 'TotalOutputViews',
    '00431016': 'NumberOfOverranges',
    '00431017': 'IBHImageScaleFactors',
    '00431018': 'BBHCoefficients',
    '00431019': 'NumberOfBBHChainsToBlend',
    '0043101A': 'StartingChannelNumber',
    '0043101B': 'PpscanParameters',
    '0043101C': 'GEImageIntegrity',
    '0043101D': 'LevelValue',
    '0043101E': 'DeltaStartTime',
    '0043101F': 'MaxOverrangesInAView',
    '00431020': 'AvgOverrangesAllViews',
    '00431021': 'CorrectedAfterGlowTerms',
    '00431025': 'ReferenceChannels',
    '00431026': 'NoViewsRefChansBlocked',
    '00431027': 'ScanPitchRatio',
    '00431028': 'UniqueImageIden',
    '00431029': 'HistogramTables',
    '0043102A': 'UserDefinedData',
    '0043102B': 'PrivateScanOptions',
    '0043102C': 'EffectiveEchoSpacing',
    '0043102D': 'StringSlopField1',
    '0043102E': 'StringSlopField2',
    '00431031': 'RACordOfTargetReconCenter',
    '00431033': 'NegScanspacing',
    '00431034': 'OffsetFrequency',
    '00431035': 'UserUsageTag',
    '00431036': 'UserFillMapMSW',
    '00431037': 'UserFillMapLSW',
    '00431038': 'User25-48',
    '00431039': 'SlopInt6-9',
    '00431040': 'TriggerOnPosition',
    '00431041': 'DegreeOfRotation',
    '00431042': 'DASTriggerSource',
    '00431043': 'DASFpaGain',
    '00431044': 'DASOutputSource',
    '00431045': 'DASAdInput',
    '00431046': 'DASCalMode',
    '00431047': 'DASCalFrequency',
    '00431048': 'DASRegXm',
    '00431049': 'DASAutoZero',
    '0043104A': 'StartingChannelOfView',
    '0043104B': 'DASXmPattern',
    '0043104C': 'TGGCTriggerMode',
    '0043104D': 'StartScanToXrayOnDelay',
    '0043104E': 'DurationOfXrayOn',
    '00431060': 'SlopInt10-17',
    '00431061': 'ScannerStudyEntityUID',
    '00431062': 'ScannerStudyID',
    '0043106f': 'ScannerTableEntry',
    '00440001': 'ProductPackageIdentifier',
    '00440002': 'SubstanceAdministrationApproval',
    '00440003': 'ApprovalStatusFurtherDescription',
    '00440004': 'ApprovalStatusDateTime',
    '00440007': 'ProductTypeCodeSequence',
    '00440008': 'ProductName',
    '00440009': 'ProductDescription',
    '0044000A': 'ProductLotIdentifier',
    '0044000B': 'ProductExpirationDateTime',
    '00440010': 'SubstanceAdministrationDateTime',
    '00440011': 'SubstanceAdministrationNotes',
    '00440012': 'SubstanceAdministrationDeviceID',
    '00440013': 'ProductParameterSequence',
    '00440019': 'SubstanceAdminParameterSeq',
    '00451001': 'NumberOfMacroRowsInDetector',
    '00451002': 'MacroWidthAtISOCenter',
    '00451003': 'DASType',
    '00451004': 'DASGain',
    '00451005': 'DASTemperature',
    '00451006': 'TableDirectionInOrOut',
    '00451007': 'ZSmoothingFactor',
    '00451008': 'ViewWeightingMode',
    '00451009': 'SigmaRowNumberWhichRowsWereUsed',
    '0045100A': 'MinimumDasValueFoundInTheScanData',
    '0045100B': 'MaximumOffsetShiftValueUsed',
    '0045100C': 'NumberOfViewsShifted',
    '0045100D': 'ZTrackingFlag',
    '0045100E': 'MeanZError',
    '0045100F': 'ZTrackingMaximumError',
    '00451010': 'StartingViewForRow2a',
    '00451011': 'NumberOfViewsInRow2a',
    '00451012': 'StartingViewForRow1a',
    '00451013': 'SigmaMode',
    '00451014': 'NumberOfViewsInRow1a',
    '00451015': 'StartingViewForRow2b',
    '00451016': 'NumberOfViewsInRow2b',
    '00451017': 'StartingViewForRow1b',
    '00451018': 'NumberOfViewsInRow1b',
    '00451019': 'AirFilterCalibrationDate',
    '0045101A': 'AirFilterCalibrationTime',
    '0045101B': 'PhantomCalibrationDate',
    '0045101C': 'PhantomCalibrationTime',
    '0045101D': 'ZSlopeCalibrationDate',
    '0045101E': 'ZSlopeCalibrationTime',
    '0045101F': 'CrosstalkCalibrationDate',
    '00451020': 'CrosstalkCalibrationTime',
    '00451021': 'IterboneOptionFlag',
    '00451022': 'PeristalticFlagOption',
    '00460012': 'LensDescription',
    '00460014': 'RightLensSequence',
    '00460015': 'LeftLensSequence',
    '00460018': 'CylinderSequence',
    '00460028': 'PrismSequence',
    '00460030': 'HorizontalPrismPower',
    '00460032': 'HorizontalPrismBase',
    '00460034': 'VerticalPrismPower',
    '00460036': 'VerticalPrismBase',
    '00460038': 'LensSegmentType',
    '00460040': 'OpticalTransmittance',
    '00460042': 'ChannelWidth',
    '00460044': 'PupilSize',
    '00460046': 'CornealSize',
    '00460060': 'DistancePupillaryDistance',
    '00460062': 'NearPupillaryDistance',
    '00460064': 'OtherPupillaryDistance',
    '00460075': 'RadiusOfCurvature',
    '00460076': 'KeratometricPower',
    '00460077': 'KeratometricAxis',
    '00460092': 'BackgroundColor',
    '00460094': 'Optotype',
    '00460095': 'OptotypePresentation',
    '00460100': 'AddNearSequence',
    '00460101': 'AddIntermediateSequence',
    '00460102': 'AddOtherSequence',
    '00460104': 'AddPower',
    '00460106': 'ViewingDistance',
    '00460125': 'ViewingDistanceType',
    '00460135': 'VisualAcuityModifiers',
    '00460137': 'DecimalVisualAcuity',
    '00460139': 'OptotypeDetailedDefinition',
    '00460146': 'SpherePower',
    '00460147': 'CylinderPower',
    '00500004': 'CalibrationImage',
    '00500010': 'DeviceSequence',
    '00500014': 'DeviceLength',
    '00500015': 'ContainerComponentWidth',
    '00500016': 'DeviceDiameter',
    '00500017': 'DeviceDiameterUnits',
    '00500018': 'DeviceVolume',
    '00500019': 'InterMarkerDistance',
    '0050001B': 'ContainerComponentID',
    '00500020': 'DeviceDescription',
    '00540010': 'EnergyWindowVector',
    '00540011': 'NumberOfEnergyWindows',
    '00540012': 'EnergyWindowInformationSequence',
    '00540013': 'EnergyWindowRangeSequence',
    '00540014': 'EnergyWindowLowerLimit',
    '00540015': 'EnergyWindowUpperLimit',
    '00540016': 'RadiopharmaceuticalInformationSeq',
    '00540017': 'ResidualSyringeCounts',
    '00540018': 'EnergyWindowName',
    '00540020': 'DetectorVector',
    '00540021': 'NumberOfDetectors',
    '00540022': 'DetectorInformationSequence',
    '00540030': 'PhaseVector',
    '00540031': 'NumberOfPhases',
    '00540032': 'PhaseInformationSequence',
    '00540033': 'NumberOfFramesInPhase',
    '00540036': 'PhaseDelay',
    '00540038': 'PauseBetweenFrames',
    '00540039': 'PhaseDescription',
    '00540050': 'RotationVector',
    '00540051': 'NumberOfRotations',
    '00540052': 'RotationInformationSequence',
    '00540053': 'NumberOfFramesInRotation',
    '00540060': 'RRIntervalVector',
    '00540061': 'NumberOfRRIntervals',
    '00540062': 'GatedInformationSequence',
    '00540063': 'DataInformationSequence',
    '00540070': 'TimeSlotVector',
    '00540071': 'NumberOfTimeSlots',
    '00540072': 'TimeSlotInformationSequence',
    '00540073': 'TimeSlotTime',
    '00540080': 'SliceVector',
    '00540081': 'NumberOfSlices',
    '00540090': 'AngularViewVector',
    '00540100': 'TimeSliceVector',
    '00540101': 'NumberOfTimeSlices',
    '00540200': 'StartAngle',
    '00540202': 'TypeOfDetectorMotion',
    '00540210': 'TriggerVector',
    '00540211': 'NumberOfTriggersInPhase',
    '00540220': 'ViewCodeSequence',
    '00540222': 'ViewModifierCodeSequence',
    '00540300': 'RadionuclideCodeSequence',
    '00540302': 'AdministrationRouteCodeSequence',
    '00540304': 'RadiopharmaceuticalCodeSequence',
    '00540306': 'CalibrationDataSequence',
    '00540308': 'EnergyWindowNumber',
    '00540400': 'ImageID',
    '00540410': 'PatientOrientationCodeSequence',
    '00540412': 'PatientOrientationModifierCodeSeq',
    '00540414': 'PatientGantryRelationshipCodeSeq',
    '00540500': 'SliceProgressionDirection',
    '00541000': 'SeriesType',
    '00541001': 'Units',
    '00541002': 'CountsSource',
    '00541004': 'ReprojectionMethod',
    '00541100': 'RandomsCorrectionMethod',
    '00541101': 'AttenuationCorrectionMethod',
    '00541102': 'DecayCorrection',
    '00541103': 'ReconstructionMethod',
    '00541104': 'DetectorLinesOfResponseUsed',
    '00541105': 'ScatterCorrectionMethod',
    '00541200': 'AxialAcceptance',
    '00541201': 'AxialMash',
    '00541202': 'TransverseMash',
    '00541203': 'DetectorElementSize',
    '00541210': 'CoincidenceWindowWidth',
    '00541220': 'SecondaryCountsType',
    '00541300': 'FrameReferenceTime',
    '00541310': 'PrimaryCountsAccumulated',
    '00541311': 'SecondaryCountsAccumulated',
    '00541320': 'SliceSensitivityFactor',
    '00541321': 'DecayFactor',
    '00541322': 'DoseCalibrationFactor',
    '00541323': 'ScatterFractionFactor',
    '00541324': 'DeadTimeFactor',
    '00541330': 'ImageIndex',
    '00541400': 'CountsIncluded',
    '00541401': 'DeadTimeCorrectionFlag',
    '00603000': 'HistogramSequence',
    '00603002': 'HistogramNumberOfBins',
    '00603004': 'HistogramFirstBinValue',
    '00603006': 'HistogramLastBinValue',
    '00603008': 'HistogramBinWidth',
    '00603010': 'HistogramExplanation',
    '00603020': 'HistogramData',
    '00620001': 'SegmentationType',
    '00620002': 'SegmentSequence',
    '00620003': 'SegmentedPropertyCategoryCodeSeq',
    '00620005': 'SegmentLabel',
    '00620006': 'SegmentDescription',
    '00620008': 'SegmentAlgorithmType',
    '00620009': 'SegmentAlgorithmName',
    '0062000A': 'SegmentIdentificationSequence',
    '0062000B': 'ReferencedSegmentNumber',
    '0062000C': 'RecommendedDisplayGrayscaleValue',
    '0062000D': 'RecommendedDisplayCIELabValue',
    '0062000E': 'MaximumFractionalValue',
    '0062000F': 'SegmentedPropertyTypeCodeSequence',
    '00620010': 'SegmentationFractionalType',
    '00640002': 'DeformableRegistrationSequence',
    '00640003': 'SourceFrameOfReferenceUID',
    '00640005': 'DeformableRegistrationGridSequence',
    '00640007': 'GridDimensions',
    '00640008': 'GridResolution',
    '00640009': 'VectorGridData',
    '0064000F': 'PreDeformationMatrixRegistSeq',
    '00640010': 'PostDeformationMatrixRegistSeq',
    '00660001': 'NumberOfSurfaces',
    '00660002': 'SurfaceSequence',
    '00660003': 'SurfaceNumber',
    '00660004': 'SurfaceComments',
    '00660009': 'SurfaceProcessing',
    '0066000A': 'SurfaceProcessingRatio',
    '0066000E': 'FiniteVolume',
    '00660010': 'Manifold',
    '00660011': 'SurfacePointsSequence',
    '00660015': 'NumberOfSurfacePoints',
    '00660016': 'PointCoordinatesData',
    '00660017': 'PointPositionAccuracy',
    '00660018': 'MeanPointDistance',
    '00660019': 'MaximumPointDistance',
    '0066001B': 'AxisOfRotation',
    '0066001C': 'CenterOfRotation',
    '0066001E': 'NumberOfVectors',
    '0066001F': 'VectorDimensionality',
    '00660020': 'VectorAccuracy',
    '00660021': 'VectorCoordinateData',
    '00660023': 'TrianglePointIndexList',
    '00660024': 'EdgePointIndexList',
    '00660025': 'VertexPointIndexList',
    '00660026': 'TriangleStripSequence',
    '00660027': 'TriangleFanSequence',
    '00660028': 'LineSequence',
    '00660029': 'PrimitivePointIndexList',
    '0066002A': 'SurfaceCount',
    '0066002F': 'AlgorithmFamilyCodeSequ',
    '00660031': 'AlgorithmVersion',
    '00660032': 'AlgorithmParameters',
    '00660034': 'FacetSequence',
    '00660036': 'AlgorithmName',
    '00700001': 'GraphicAnnotationSequence',
    '00700002': 'GraphicLayer',
    '00700003': 'BoundingBoxAnnotationUnits',
    '00700004': 'AnchorPointAnnotationUnits',
    '00700005': 'GraphicAnnotationUnits',
    '00700006': 'UnformattedTextValue',
    '00700008': 'TextObjectSequence',
    '00700009': 'GraphicObjectSequence',
    '00700010': 'BoundingBoxTopLeftHandCorner',
    '00700011': 'BoundingBoxBottomRightHandCorner',
    '00700012': 'BoundingBoxTextHorizJustification',
    '00700014': 'AnchorPoint',
    '00700015': 'AnchorPointVisibility',
    '00700020': 'GraphicDimensions',
    '00700021': 'NumberOfGraphicPoints',
    '00700022': 'GraphicData',
    '00700023': 'GraphicType',
    '00700024': 'GraphicFilled',
    '00700040': 'ImageRotationRetired',
    '00700041': 'ImageHorizontalFlip',
    '00700042': 'ImageRotation',
    '00700050': 'DisplayedAreaTopLeftTrial',
    '00700051': 'DisplayedAreaBottomRightTrial',
    '00700052': 'DisplayedAreaTopLeft',
    '00700053': 'DisplayedAreaBottomRight',
    '0070005A': 'DisplayedAreaSelectionSequence',
    '00700060': 'GraphicLayerSequence',
    '00700062': 'GraphicLayerOrder',
    '00700066': 'GraphicLayerRecDisplayGraysclValue',
    '00700067': 'GraphicLayerRecDisplayRGBValue',
    '00700068': 'GraphicLayerDescription',
    '00700080': 'ContentLabel',
    '00700081': 'ContentDescription',
    '00700082': 'PresentationCreationDate',
    '00700083': 'PresentationCreationTime',
    '00700084': 'ContentCreatorName',
    '00700086': 'ContentCreatorIDCodeSequence',
    '00700100': 'PresentationSizeMode',
    '00700101': 'PresentationPixelSpacing',
    '00700102': 'PresentationPixelAspectRatio',
    '00700103': 'PresentationPixelMagRatio',
    '00700306': 'ShapeType',
    '00700308': 'RegistrationSequence',
    '00700309': 'MatrixRegistrationSequence',
    '0070030A': 'MatrixSequence',
    '0070030C': 'FrameOfRefTransformationMatrixType',
    '0070030D': 'RegistrationTypeCodeSequence',
    '0070030F': 'FiducialDescription',
    '00700310': 'FiducialIdentifier',
    '00700311': 'FiducialIdentifierCodeSequence',
    '00700312': 'ContourUncertaintyRadius',
    '00700314': 'UsedFiducialsSequence',
    '00700318': 'GraphicCoordinatesDataSequence',
    '0070031A': 'FiducialUID',
    '0070031C': 'FiducialSetSequence',
    '0070031E': 'FiducialSequence',
    '00700401': 'GraphicLayerRecomDisplayCIELabVal',
    '00700402': 'BlendingSequence',
    '00700403': 'RelativeOpacity',
    '00700404': 'ReferencedSpatialRegistrationSeq',
    '00700405': 'BlendingPosition',
    '00720002': 'HangingProtocolName',
    '00720004': 'HangingProtocolDescription',
    '00720006': 'HangingProtocolLevel',
    '00720008': 'HangingProtocolCreator',
    '0072000A': 'HangingProtocolCreationDateTime',
    '0072000C': 'HangingProtocolDefinitionSequence',
    '0072000E': 'HangingProtocolUserIDCodeSequence',
    '00720010': 'HangingProtocolUserGroupName',
    '00720012': 'SourceHangingProtocolSequence',
    '00720014': 'NumberOfPriorsReferenced',
    '00720020': 'ImageSetsSequence',
    '00720022': 'ImageSetSelectorSequence',
    '00720024': 'ImageSetSelectorUsageFlag',
    '00720026': 'SelectorAttribute',
    '00720028': 'SelectorValueNumber',
    '00720030': 'TimeBasedImageSetsSequence',
    '00720032': 'ImageSetNumber',
    '00720034': 'ImageSetSelectorCategory',
    '00720038': 'RelativeTime',
    '0072003A': 'RelativeTimeUnits',
    '0072003C': 'AbstractPriorValue',
    '0072003E': 'AbstractPriorCodeSequence',
    '00720040': 'ImageSetLabel',
    '00720050': 'SelectorAttributeVR',
    '00720052': 'SelectorSequencePointer',
    '00720054': 'SelectorSeqPointerPrivateCreator',
    '00720056': 'SelectorAttributePrivateCreator',
    '00720060': 'SelectorATValue',
    '00720062': 'SelectorCSValue',
    '00720064': 'SelectorISValue',
    '00720066': 'SelectorLOValue',
    '00720068': 'SelectorLTValue',
    '0072006A': 'SelectorPNValue',
    '0072006C': 'SelectorSHValue',
    '0072006E': 'SelectorSTValue',
    '00720070': 'SelectorUTValue',
    '00720072': 'SelectorDSValue',
    '00720074': 'SelectorFDValue',
    '00720076': 'SelectorFLValue',
    '00720078': 'SelectorULValue',
    '0072007A': 'SelectorUSValue',
    '0072007C': 'SelectorSLValue',
    '0072007E': 'SelectorSSValue',
    '00720080': 'SelectorCodeSequenceValue',
    '00720100': 'NumberOfScreens',
    '00720102': 'NominalScreenDefinitionSequence',
    '00720104': 'NumberOfVerticalPixels',
    '00720106': 'NumberOfHorizontalPixels',
    '00720108': 'DisplayEnvironmentSpatialPosition',
    '0072010A': 'ScreenMinimumGrayscaleBitDepth',
    '0072010C': 'ScreenMinimumColorBitDepth',
    '0072010E': 'ApplicationMaximumRepaintTime',
    '00720200': 'DisplaySetsSequence',
    '00720202': 'DisplaySetNumber',
    '00720203': 'DisplaySetLabel',
    '00720204': 'DisplaySetPresentationGroup',
    '00720206': 'DisplaySetPresentationGroupDescr',
    '00720208': 'PartialDataDisplayHandling',
    '00720210': 'SynchronizedScrollingSequence',
    '00720212': 'DisplaySetScrollingGroup',
    '00720214': 'NavigationIndicatorSequence',
    '00720216': 'NavigationDisplaySet',
    '00720218': 'ReferenceDisplaySets',
    '00720300': 'ImageBoxesSequence',
    '00720302': 'ImageBoxNumber',
    '00720304': 'ImageBoxLayoutType',
    '00720306': 'ImageBoxTileHorizontalDimension',
    '00720308': 'ImageBoxTileVerticalDimension',
    '00720310': 'ImageBoxScrollDirection',
    '00720312': 'ImageBoxSmallScrollType',
    '00720314': 'ImageBoxSmallScrollAmount',
    '00720316': 'ImageBoxLargeScrollType',
    '00720318': 'ImageBoxLargeScrollAmount',
    '00720320': 'ImageBoxOverlapPriority',
    '00720330': 'CineRelativeToRealTime',
    '00720400': 'FilterOperationsSequence',
    '00720402': 'FilterByCategory',
    '00720404': 'FilterByAttributePresence',
    '00720406': 'FilterByOperator',
    '00720432': 'SynchronizedImageBoxList',
    '00720434': 'TypeOfSynchronization',
    '00720500': 'BlendingOperationType',
    '00720510': 'ReformattingOperationType',
    '00720512': 'ReformattingThickness',
    '00720514': 'ReformattingInterval',
    '00720516': 'ReformattingOpInitialViewDir',
    '00720520': 'RenderingType3D',
    '00720600': 'SortingOperationsSequence',
    '00720602': 'SortByCategory',
    '00720604': 'SortingDirection',
    '00720700': 'DisplaySetPatientOrientation',
    '00720702': 'VOIType',
    '00720704': 'PseudoColorType',
    '00720706': 'ShowGrayscaleInverted',
    '00720710': 'ShowImageTrueSizeFlag',
    '00720712': 'ShowGraphicAnnotationFlag',
    '00720714': 'ShowPatientDemographicsFlag',
    '00720716': 'ShowAcquisitionTechniquesFlag',
    '00720717': 'DisplaySetHorizontalJustification',
    '00720718': 'DisplaySetVerticalJustification',
    '00741000': 'UnifiedProcedureStepState',
    '00741002': 'UPSProgressInformationSequence',
    '00741004': 'UnifiedProcedureStepProgress',
    '00741006': 'UnifiedProcedureStepProgressDescr',
    '00741008': 'UnifiedProcedureStepComURISeq',
    '0074100a': 'ContactURI',
    '0074100c': 'ContactDisplayName',
    '00741020': 'BeamTaskSequence',
    '00741022': 'BeamTaskType',
    '00741024': 'BeamOrderIndex',
    '00741030': 'DeliveryVerificationImageSequence',
    '00741032': 'VerificationImageTiming',
    '00741034': 'DoubleExposureFlag',
    '00741036': 'DoubleExposureOrdering',
    '00741038': 'DoubleExposureMeterset',
    '0074103A': 'DoubleExposureFieldDelta',
    '00741040': 'RelatedReferenceRTImageSequence',
    '00741042': 'GeneralMachineVerificationSequence',
    '00741044': 'ConventionalMachineVerificationSeq',
    '00741046': 'IonMachineVerificationSequence',
    '00741048': 'FailedAttributesSequence',
    '0074104A': 'OverriddenAttributesSequence',
    '0074104C': 'ConventionalControlPointVerifySeq',
    '0074104E': 'IonControlPointVerificationSeq',
    '00741050': 'AttributeOccurrenceSequence',
    '00741052': 'AttributeOccurrencePointer',
    '00741054': 'AttributeItemSelector',
    '00741056': 'AttributeOccurrencePrivateCreator',
    '00741200': 'ScheduledProcedureStepPriority',
    '00741202': 'WorklistLabel',
    '00741204': 'ProcedureStepLabel',
    '00741210': 'ScheduledProcessingParametersSeq',
    '00741212': 'PerformedProcessingParametersSeq',
    '00741216': 'UPSPerformedProcedureSequence',
    '00741220': 'RelatedProcedureStepSequence',
    '00741222': 'ProcedureStepRelationshipType',
    '00741230': 'DeletionLock',
    '00741234': 'ReceivingAE',
    '00741236': 'RequestingAE',
    '00741238': 'ReasonForCancellation',
    '00741242': 'SCPStatus',
    '00741244': 'SubscriptionListStatus',
    '00741246': 'UPSListStatus',
    '00880130': 'StorageMediaFileSetID',
    '00880140': 'StorageMediaFileSetUID',
    '00880200': 'IconImageSequence',
    '00880904': 'TopicTitle',
    '00880906': 'TopicSubject',
    '00880910': 'TopicAuthor',
    '00880912': 'TopicKeywords',
    '01000410': 'SOPInstanceStatus',
    '01000420': 'SOPAuthorizationDateAndTime',
    '01000424': 'SOPAuthorizationComment',
    '01000426': 'AuthorizationEquipmentCertNumber',
    '04000005': 'MACIDNumber',
    '04000010': 'MACCalculationTransferSyntaxUID',
    '04000015': 'MACAlgorithm',
    '04000020': 'DataElementsSigned',
    '04000100': 'DigitalSignatureUID',
    '04000105': 'DigitalSignatureDateTime',
    '04000110': 'CertificateType',
    '04000115': 'CertificateOfSigner',
    '04000120': 'Signature',
    '04000305': 'CertifiedTimestampType',
    '04000310': 'CertifiedTimestamp',
    '04000401': 'DigitalSignaturePurposeCodeSeq',
    '04000402': 'ReferencedDigitalSignatureSeq',
    '04000403': 'ReferencedSOPInstanceMACSeq',
    '04000404': 'MAC',
    '04000500': 'EncryptedAttributesSequence',
    '04000510': 'EncryptedContentTransferSyntaxUID',
    '04000520': 'EncryptedContent',
    '04000550': 'ModifiedAttributesSequence',
    '04000561': 'OriginalAttributesSequence',
    '04000562': 'AttributeModificationDateTime',
    '04000563': 'ModifyingSystem',
    '04000564': 'SourceOfPreviousValues',
    '04000565': 'ReasonForTheAttributeModification',
    '1000...0': 'EscapeTriplet',
    '1000...1': 'RunLengthTriplet',
    '1000...2': 'HuffmanTableSize',
    '1000...3': 'HuffmanTableTriplet',
    '1000...4': 'ShiftTableSize',
    '1000...5': 'ShiftTableTriplet',
    '1010....': 'ZonalMap',
    20000010: 'NumberOfCopies',
    '2000001E': 'PrinterConfigurationSequence',
    20000020: 'PrintPriority',
    20000030: 'MediumType',
    20000040: 'FilmDestination',
    20000050: 'FilmSessionLabel',
    20000060: 'MemoryAllocation',
    20000061: 'MaximumMemoryAllocation',
    20000062: 'ColorImagePrintingFlag',
    20000063: 'CollationFlag',
    20000065: 'AnnotationFlag',
    20000067: 'ImageOverlayFlag',
    20000069: 'PresentationLUTFlag',
    '2000006A': 'ImageBoxPresentationLUTFlag',
    '200000A0': 'MemoryBitDepth',
    '200000A1': 'PrintingBitDepth',
    '200000A2': 'MediaInstalledSequence',
    '200000A4': 'OtherMediaAvailableSequence',
    '200000A8': 'SupportedImageDisplayFormatSeq',
    20000500: 'ReferencedFilmBoxSequence',
    20000510: 'ReferencedStoredPrintSequence',
    20100010: 'ImageDisplayFormat',
    20100030: 'AnnotationDisplayFormatID',
    20100040: 'FilmOrientation',
    20100050: 'FilmSizeID',
    20100052: 'PrinterResolutionID',
    20100054: 'DefaultPrinterResolutionID',
    20100060: 'MagnificationType',
    20100080: 'SmoothingType',
    '201000A6': 'DefaultMagnificationType',
    '201000A7': 'OtherMagnificationTypesAvailable',
    '201000A8': 'DefaultSmoothingType',
    '201000A9': 'OtherSmoothingTypesAvailable',
    20100100: 'BorderDensity',
    20100110: 'EmptyImageDensity',
    20100120: 'MinDensity',
    20100130: 'MaxDensity',
    20100140: 'Trim',
    20100150: 'ConfigurationInformation',
    20100152: 'ConfigurationInformationDescr',
    20100154: 'MaximumCollatedFilms',
    '2010015E': 'Illumination',
    20100160: 'ReflectedAmbientLight',
    20100376: 'PrinterPixelSpacing',
    20100500: 'ReferencedFilmSessionSequence',
    20400500: 'ReferencedImageBoxSequence',
    20100520: 'ReferencedBasicAnnotationBoxSeq',
    20200010: 'ImageBoxPosition',
    20200020: 'Polarity',
    20200030: 'RequestedImageSize',
    20200040: 'RequestedDecimate-CropBehavior',
    20200050: 'RequestedResolutionID',
    '202000A0': 'RequestedImageSizeFlag',
    '202000A2': 'DecimateCropResult',
    20200110: 'BasicGrayscaleImageSequence',
    20200111: 'BasicColorImageSequence',
    20200130: 'ReferencedImageOverlayBoxSequence',
    20200140: 'ReferencedVOILUTBoxSequence',
    20300010: 'AnnotationPosition',
    20300020: 'TextString',
    20400010: 'ReferencedOverlayPlaneSequence',
    20400011: 'ReferencedOverlayPlaneGroups',
    20400020: 'OverlayPixelDataSequence',
    20400060: 'OverlayMagnificationType',
    20400070: 'OverlaySmoothingType',
    20400072: 'OverlayOrImageMagnification',
    20400074: 'MagnifyToNumberOfColumns',
    20400080: 'OverlayForegroundDensity',
    20400082: 'OverlayBackgroundDensity',
    20400090: 'OverlayMode',
    20400100: 'ThresholdDensity',
    20500010: 'PresentationLUTSequence',
    20500020: 'PresentationLUTShape',
    20500500: 'ReferencedPresentationLUTSequence',
    21000010: 'PrintJobID',
    21000020: 'ExecutionStatus',
    21000030: 'ExecutionStatusInfo',
    21000040: 'CreationDate',
    21000050: 'CreationTime',
    21000070: 'Originator',
    21000140: 'DestinationAE',
    21000160: 'OwnerID',
    21000170: 'NumberOfFilms',
    21200070: 'ReferencedPrintJobSequence',
    21100010: 'PrinterStatus',
    21100020: 'PrinterStatusInfo',
    21100030: 'PrinterName',
    21100099: 'PrintQueueID',
    21200010: 'QueueStatus',
    21200050: 'PrintJobDescriptionSequence',
    21300010: 'PrintManagementCapabilitiesSeq',
    21300015: 'PrinterCharacteristicsSequence',
    21300030: 'FilmBoxContentSequence',
    21300040: 'ImageBoxContentSequence',
    21300050: 'AnnotationContentSequence',
    21300060: 'ImageOverlayBoxContentSequence',
    21300080: 'PresentationLUTContentSequence',
    '213000A0': 'ProposedStudySequence',
    '213000C0': 'OriginalImageSequence',
    22000001: 'LabelFromInfoExtractedFromInstance',
    22000002: 'LabelText',
    22000003: 'LabelStyleSelection',
    22000004: 'MediaDisposition',
    22000005: 'BarcodeValue',
    22000006: 'BarcodeSymbology',
    22000007: 'AllowMediaSplitting',
    22000008: 'IncludeNonDICOMObjects',
    22000009: 'IncludeDisplayApplication',
    '2200000A': 'SaveCompInstancesAfterMediaCreate',
    '2200000B': 'TotalNumberMediaPiecesCreated',
    '2200000C': 'RequestedMediaApplicationProfile',
    '2200000D': 'ReferencedStorageMediaSequence',
    '2200000E': 'FailureAttributes',
    '2200000F': 'AllowLossyCompression',
    22000020: 'RequestPriority',
    30020002: 'RTImageLabel',
    30020003: 'RTImageName',
    30020004: 'RTImageDescription',
    '3002000A': 'ReportedValuesOrigin',
    '3002000C': 'RTImagePlane',
    '3002000D': 'XRayImageReceptorTranslation',
    '3002000E': 'XRayImageReceptorAngle',
    30020010: 'RTImageOrientation',
    30020011: 'ImagePlanePixelSpacing',
    30020012: 'RTImagePosition',
    30020020: 'RadiationMachineName',
    30020022: 'RadiationMachineSAD',
    30020024: 'RadiationMachineSSD',
    30020026: 'RTImageSID',
    30020028: 'SourceToReferenceObjectDistance',
    30020029: 'FractionNumber',
    30020030: 'ExposureSequence',
    30020032: 'MetersetExposure',
    30020034: 'DiaphragmPosition',
    30020040: 'FluenceMapSequence',
    30020041: 'FluenceDataSource',
    30020042: 'FluenceDataScale',
    30020051: 'FluenceMode',
    30020052: 'FluenceModeID',
    30040001: 'DVHType',
    30040002: 'DoseUnits',
    30040004: 'DoseType',
    30040006: 'DoseComment',
    30040008: 'NormalizationPoint',
    '3004000A': 'DoseSummationType',
    '3004000C': 'GridFrameOffsetVector',
    '3004000E': 'DoseGridScaling',
    30040010: 'RTDoseROISequence',
    30040012: 'DoseValue',
    30040014: 'TissueHeterogeneityCorrection',
    30040040: 'DVHNormalizationPoint',
    30040042: 'DVHNormalizationDoseValue',
    30040050: 'DVHSequence',
    30040052: 'DVHDoseScaling',
    30040054: 'DVHVolumeUnits',
    30040056: 'DVHNumberOfBins',
    30040058: 'DVHData',
    30040060: 'DVHReferencedROISequence',
    30040062: 'DVHROIContributionType',
    30040070: 'DVHMinimumDose',
    30040072: 'DVHMaximumDose',
    30040074: 'DVHMeanDose',
    30060002: 'StructureSetLabel',
    30060004: 'StructureSetName',
    30060006: 'StructureSetDescription',
    30060008: 'StructureSetDate',
    30060009: 'StructureSetTime',
    30060010: 'ReferencedFrameOfReferenceSequence',
    30060012: 'RTReferencedStudySequence',
    30060014: 'RTReferencedSeriesSequence',
    30060016: 'ContourImageSequence',
    30060020: 'StructureSetROISequence',
    30060022: 'ROINumber',
    30060024: 'ReferencedFrameOfReferenceUID',
    30060026: 'ROIName',
    30060028: 'ROIDescription',
    '3006002A': 'ROIDisplayColor',
    '3006002C': 'ROIVolume',
    30060030: 'RTRelatedROISequence',
    30060033: 'RTROIRelationship',
    30060036: 'ROIGenerationAlgorithm',
    30060038: 'ROIGenerationDescription',
    30060039: 'ROIContourSequence',
    30060040: 'ContourSequence',
    30060042: 'ContourGeometricType',
    30060044: 'ContourSlabThickness',
    30060045: 'ContourOffsetVector',
    30060046: 'NumberOfContourPoints',
    30060048: 'ContourNumber',
    30060049: 'AttachedContours',
    30060050: 'ContourData',
    30060080: 'RTROIObservationsSequence',
    30060082: 'ObservationNumber',
    30060084: 'ReferencedROINumber',
    30060085: 'ROIObservationLabel',
    30060086: 'RTROIIdentificationCodeSequence',
    30060088: 'ROIObservationDescription',
    '300600A0': 'RelatedRTROIObservationsSequence',
    '300600A4': 'RTROIInterpretedType',
    '300600A6': 'ROIInterpreter',
    '300600B0': 'ROIPhysicalPropertiesSequence',
    '300600B2': 'ROIPhysicalProperty',
    '300600B4': 'ROIPhysicalPropertyValue',
    '300600B6': 'ROIElementalCompositionSequence',
    '300600B7': 'ROIElementalCompAtomicNumber',
    '300600B8': 'ROIElementalCompAtomicMassFraction',
    '300600C0': 'FrameOfReferenceRelationshipSeq',
    '300600C2': 'RelatedFrameOfReferenceUID',
    '300600C4': 'FrameOfReferenceTransformType',
    '300600C6': 'FrameOfReferenceTransformMatrix',
    '300600C8': 'FrameOfReferenceTransformComment',
    30080010: 'MeasuredDoseReferenceSequence',
    30080012: 'MeasuredDoseDescription',
    30080014: 'MeasuredDoseType',
    30080016: 'MeasuredDoseValue',
    30080020: 'TreatmentSessionBeamSequence',
    30080021: 'TreatmentSessionIonBeamSequence',
    30080022: 'CurrentFractionNumber',
    30080024: 'TreatmentControlPointDate',
    30080025: 'TreatmentControlPointTime',
    '3008002A': 'TreatmentTerminationStatus',
    '3008002B': 'TreatmentTerminationCode',
    '3008002C': 'TreatmentVerificationStatus',
    30080030: 'ReferencedTreatmentRecordSequence',
    30080032: 'SpecifiedPrimaryMeterset',
    30080033: 'SpecifiedSecondaryMeterset',
    30080036: 'DeliveredPrimaryMeterset',
    30080037: 'DeliveredSecondaryMeterset',
    '3008003A': 'SpecifiedTreatmentTime',
    '3008003B': 'DeliveredTreatmentTime',
    30080040: 'ControlPointDeliverySequence',
    30080041: 'IonControlPointDeliverySequence',
    30080042: 'SpecifiedMeterset',
    30080044: 'DeliveredMeterset',
    30080045: 'MetersetRateSet',
    30080046: 'MetersetRateDelivered',
    30080047: 'ScanSpotMetersetsDelivered',
    30080048: 'DoseRateDelivered',
    30080050: 'TreatmentSummaryCalcDoseRefSeq',
    30080052: 'CumulativeDoseToDoseReference',
    30080054: 'FirstTreatmentDate',
    30080056: 'MostRecentTreatmentDate',
    '3008005A': 'NumberOfFractionsDelivered',
    30080060: 'OverrideSequence',
    30080061: 'ParameterSequencePointer',
    30080062: 'OverrideParameterPointer',
    30080063: 'ParameterItemIndex',
    30080064: 'MeasuredDoseReferenceNumber',
    30080065: 'ParameterPointer',
    30080066: 'OverrideReason',
    30080068: 'CorrectedParameterSequence',
    '3008006A': 'CorrectionValue',
    30080070: 'CalculatedDoseReferenceSequence',
    30080072: 'CalculatedDoseReferenceNumber',
    30080074: 'CalculatedDoseReferenceDescription',
    30080076: 'CalculatedDoseReferenceDoseValue',
    30080078: 'StartMeterset',
    '3008007A': 'EndMeterset',
    30080080: 'ReferencedMeasuredDoseReferenceSeq',
    30080082: 'ReferencedMeasuredDoseReferenceNum',
    30080090: 'ReferencedCalculatedDoseRefSeq',
    30080092: 'ReferencedCalculatedDoseRefNumber',
    '300800A0': 'BeamLimitingDeviceLeafPairsSeq',
    '300800B0': 'RecordedWedgeSequence',
    '300800C0': 'RecordedCompensatorSequence',
    '300800D0': 'RecordedBlockSequence',
    '300800E0': 'TreatmentSummaryMeasuredDoseRefSeq',
    '300800F0': 'RecordedSnoutSequence',
    '300800F2': 'RecordedRangeShifterSequence',
    '300800F4': 'RecordedLateralSpreadingDeviceSeq',
    '300800F6': 'RecordedRangeModulatorSequence',
    30080100: 'RecordedSourceSequence',
    30080105: 'SourceSerialNumber',
    30080110: 'TreatmentSessionAppSetupSeq',
    30080116: 'ApplicationSetupCheck',
    30080120: 'RecordedBrachyAccessoryDeviceSeq',
    30080122: 'ReferencedBrachyAccessoryDeviceNum',
    30080130: 'RecordedChannelSequence',
    30080132: 'SpecifiedChannelTotalTime',
    30080134: 'DeliveredChannelTotalTime',
    30080136: 'SpecifiedNumberOfPulses',
    30080138: 'DeliveredNumberOfPulses',
    '3008013A': 'SpecifiedPulseRepetitionInterval',
    '3008013C': 'DeliveredPulseRepetitionInterval',
    30080140: 'RecordedSourceApplicatorSequence',
    30080142: 'ReferencedSourceApplicatorNumber',
    30080150: 'RecordedChannelShieldSequence',
    30080152: 'ReferencedChannelShieldNumber',
    30080160: 'BrachyControlPointDeliveredSeq',
    30080162: 'SafePositionExitDate',
    30080164: 'SafePositionExitTime',
    30080166: 'SafePositionReturnDate',
    30080168: 'SafePositionReturnTime',
    30080200: 'CurrentTreatmentStatus',
    30080202: 'TreatmentStatusComment',
    30080220: 'FractionGroupSummarySequence',
    30080223: 'ReferencedFractionNumber',
    30080224: 'FractionGroupType',
    30080230: 'BeamStopperPosition',
    30080240: 'FractionStatusSummarySequence',
    30080250: 'TreatmentDate',
    30080251: 'TreatmentTime',
    '300A0002': 'RTPlanLabel',
    '300A0003': 'RTPlanName',
    '300A0004': 'RTPlanDescription',
    '300A0006': 'RTPlanDate',
    '300A0007': 'RTPlanTime',
    '300A0009': 'TreatmentProtocols',
    '300A000A': 'PlanIntent',
    '300A000B': 'TreatmentSites',
    '300A000C': 'RTPlanGeometry',
    '300A000E': 'PrescriptionDescription',
    '300A0010': 'DoseReferenceSequence',
    '300A0012': 'DoseReferenceNumber',
    '300A0013': 'DoseReferenceUID',
    '300A0014': 'DoseReferenceStructureType',
    '300A0015': 'NominalBeamEnergyUnit',
    '300A0016': 'DoseReferenceDescription',
    '300A0018': 'DoseReferencePointCoordinates',
    '300A001A': 'NominalPriorDose',
    '300A0020': 'DoseReferenceType',
    '300A0021': 'ConstraintWeight',
    '300A0022': 'DeliveryWarningDose',
    '300A0023': 'DeliveryMaximumDose',
    '300A0025': 'TargetMinimumDose',
    '300A0026': 'TargetPrescriptionDose',
    '300A0027': 'TargetMaximumDose',
    '300A0028': 'TargetUnderdoseVolumeFraction',
    '300A002A': 'OrganAtRiskFullVolumeDose',
    '300A002B': 'OrganAtRiskLimitDose',
    '300A002C': 'OrganAtRiskMaximumDose',
    '300A002D': 'OrganAtRiskOverdoseVolumeFraction',
    '300A0040': 'ToleranceTableSequence',
    '300A0042': 'ToleranceTableNumber',
    '300A0043': 'ToleranceTableLabel',
    '300A0044': 'GantryAngleTolerance',
    '300A0046': 'BeamLimitingDeviceAngleTolerance',
    '300A0048': 'BeamLimitingDeviceToleranceSeq',
    '300A004A': 'BeamLimitingDevicePositionTol',
    '300A004B': 'SnoutPositionTolerance',
    '300A004C': 'PatientSupportAngleTolerance',
    '300A004E': 'TableTopEccentricAngleTolerance',
    '300A004F': 'TableTopPitchAngleTolerance',
    '300A0050': 'TableTopRollAngleTolerance',
    '300A0051': 'TableTopVerticalPositionTolerance',
    '300A0052': 'TableTopLongitudinalPositionTol',
    '300A0053': 'TableTopLateralPositionTolerance',
    '300A0055': 'RTPlanRelationship',
    '300A0070': 'FractionGroupSequence',
    '300A0071': 'FractionGroupNumber',
    '300A0072': 'FractionGroupDescription',
    '300A0078': 'NumberOfFractionsPlanned',
    '300A0079': 'NumberFractionPatternDigitsPerDay',
    '300A007A': 'RepeatFractionCycleLength',
    '300A007B': 'FractionPattern',
    '300A0080': 'NumberOfBeams',
    '300A0082': 'BeamDoseSpecificationPoint',
    '300A0084': 'BeamDose',
    '300A0086': 'BeamMeterset',
    '300A0088': 'BeamDosePointDepth',
    '300A0089': 'BeamDosePointEquivalentDepth',
    '300A008A': 'BeamDosePointSSD',
    '300A00A0': 'NumberOfBrachyApplicationSetups',
    '300A00A2': 'BrachyAppSetupDoseSpecPoint',
    '300A00A4': 'BrachyApplicationSetupDose',
    '300A00B0': 'BeamSequence',
    '300A00B2': 'TreatmentMachineName',
    '300A00B3': 'PrimaryDosimeterUnit',
    '300A00B4': 'SourceAxisDistance',
    '300A00B6': 'BeamLimitingDeviceSequence',
    '300A00B8': 'RTBeamLimitingDeviceType',
    '300A00BA': 'SourceToBeamLimitingDeviceDistance',
    '300A00BB': 'IsocenterToBeamLimitingDeviceDist',
    '300A00BC': 'NumberOfLeafJawPairs',
    '300A00BE': 'LeafPositionBoundaries',
    '300A00C0': 'BeamNumber',
    '300A00C2': 'BeamName',
    '300A00C3': 'BeamDescription',
    '300A00C4': 'BeamType',
    '300A00C6': 'RadiationType',
    '300A00C7': 'HighDoseTechniqueType',
    '300A00C8': 'ReferenceImageNumber',
    '300A00CA': 'PlannedVerificationImageSequence',
    '300A00CC': 'ImagingDeviceSpecificAcqParams',
    '300A00CE': 'TreatmentDeliveryType',
    '300A00D0': 'NumberOfWedges',
    '300A00D1': 'WedgeSequence',
    '300A00D2': 'WedgeNumber',
    '300A00D3': 'WedgeType',
    '300A00D4': 'WedgeID',
    '300A00D5': 'WedgeAngle',
    '300A00D6': 'WedgeFactor',
    '300A00D7': 'TotalWedgeTrayWaterEquivThickness',
    '300A00D8': 'WedgeOrientation',
    '300A00D9': 'IsocenterToWedgeTrayDistance',
    '300A00DA': 'SourceToWedgeTrayDistance',
    '300A00DB': 'WedgeThinEdgePosition',
    '300A00DC': 'BolusID',
    '300A00DD': 'BolusDescription',
    '300A00E0': 'NumberOfCompensators',
    '300A00E1': 'MaterialID',
    '300A00E2': 'TotalCompensatorTrayFactor',
    '300A00E3': 'CompensatorSequence',
    '300A00E4': 'CompensatorNumber',
    '300A00E5': 'CompensatorID',
    '300A00E6': 'SourceToCompensatorTrayDistance',
    '300A00E7': 'CompensatorRows',
    '300A00E8': 'CompensatorColumns',
    '300A00E9': 'CompensatorPixelSpacing',
    '300A00EA': 'CompensatorPosition',
    '300A00EB': 'CompensatorTransmissionData',
    '300A00EC': 'CompensatorThicknessData',
    '300A00ED': 'NumberOfBoli',
    '300A00EE': 'CompensatorType',
    '300A00F0': 'NumberOfBlocks',
    '300A00F2': 'TotalBlockTrayFactor',
    '300A00F3': 'TotalBlockTrayWaterEquivThickness',
    '300A00F4': 'BlockSequence',
    '300A00F5': 'BlockTrayID',
    '300A00F6': 'SourceToBlockTrayDistance',
    '300A00F7': 'IsocenterToBlockTrayDistance',
    '300A00F8': 'BlockType',
    '300A00F9': 'AccessoryCode',
    '300A00FA': 'BlockDivergence',
    '300A00FB': 'BlockMountingPosition',
    '300A00FC': 'BlockNumber',
    '300A00FE': 'BlockName',
    '300A0100': 'BlockThickness',
    '300A0102': 'BlockTransmission',
    '300A0104': 'BlockNumberOfPoints',
    '300A0106': 'BlockData',
    '300A0107': 'ApplicatorSequence',
    '300A0108': 'ApplicatorID',
    '300A0109': 'ApplicatorType',
    '300A010A': 'ApplicatorDescription',
    '300A010C': 'CumulativeDoseReferenceCoefficient',
    '300A010E': 'FinalCumulativeMetersetWeight',
    '300A0110': 'NumberOfControlPoints',
    '300A0111': 'ControlPointSequence',
    '300A0112': 'ControlPointIndex',
    '300A0114': 'NominalBeamEnergy',
    '300A0115': 'DoseRateSet',
    '300A0116': 'WedgePositionSequence',
    '300A0118': 'WedgePosition',
    '300A011A': 'BeamLimitingDevicePositionSequence',
    '300A011C': 'LeafJawPositions',
    '300A011E': 'GantryAngle',
    '300A011F': 'GantryRotationDirection',
    '300A0120': 'BeamLimitingDeviceAngle',
    '300A0121': 'BeamLimitingDeviceRotateDirection',
    '300A0122': 'PatientSupportAngle',
    '300A0123': 'PatientSupportRotationDirection',
    '300A0124': 'TableTopEccentricAxisDistance',
    '300A0125': 'TableTopEccentricAngle',
    '300A0126': 'TableTopEccentricRotateDirection',
    '300A0128': 'TableTopVerticalPosition',
    '300A0129': 'TableTopLongitudinalPosition',
    '300A012A': 'TableTopLateralPosition',
    '300A012C': 'IsocenterPosition',
    '300A012E': 'SurfaceEntryPoint',
    '300A0130': 'SourceToSurfaceDistance',
    '300A0134': 'CumulativeMetersetWeight',
    '300A0140': 'TableTopPitchAngle',
    '300A0142': 'TableTopPitchRotationDirection',
    '300A0144': 'TableTopRollAngle',
    '300A0146': 'TableTopRollRotationDirection',
    '300A0148': 'HeadFixationAngle',
    '300A014A': 'GantryPitchAngle',
    '300A014C': 'GantryPitchRotationDirection',
    '300A014E': 'GantryPitchAngleTolerance',
    '300A0180': 'PatientSetupSequence',
    '300A0182': 'PatientSetupNumber',
    '300A0183': 'PatientSetupLabel',
    '300A0184': 'PatientAdditionalPosition',
    '300A0190': 'FixationDeviceSequence',
    '300A0192': 'FixationDeviceType',
    '300A0194': 'FixationDeviceLabel',
    '300A0196': 'FixationDeviceDescription',
    '300A0198': 'FixationDevicePosition',
    '300A0199': 'FixationDevicePitchAngle',
    '300A019A': 'FixationDeviceRollAngle',
    '300A01A0': 'ShieldingDeviceSequence',
    '300A01A2': 'ShieldingDeviceType',
    '300A01A4': 'ShieldingDeviceLabel',
    '300A01A6': 'ShieldingDeviceDescription',
    '300A01A8': 'ShieldingDevicePosition',
    '300A01B0': 'SetupTechnique',
    '300A01B2': 'SetupTechniqueDescription',
    '300A01B4': 'SetupDeviceSequence',
    '300A01B6': 'SetupDeviceType',
    '300A01B8': 'SetupDeviceLabel',
    '300A01BA': 'SetupDeviceDescription',
    '300A01BC': 'SetupDeviceParameter',
    '300A01D0': 'SetupReferenceDescription',
    '300A01D2': 'TableTopVerticalSetupDisplacement',
    '300A01D4': 'TableTopLongitudinalSetupDisplace',
    '300A01D6': 'TableTopLateralSetupDisplacement',
    '300A0200': 'BrachyTreatmentTechnique',
    '300A0202': 'BrachyTreatmentType',
    '300A0206': 'TreatmentMachineSequence',
    '300A0210': 'SourceSequence',
    '300A0212': 'SourceNumber',
    '300A0214': 'SourceType',
    '300A0216': 'SourceManufacturer',
    '300A0218': 'ActiveSourceDiameter',
    '300A021A': 'ActiveSourceLength',
    '300A0222': 'SourceEncapsulationNomThickness',
    '300A0224': 'SourceEncapsulationNomTransmission',
    '300A0226': 'SourceIsotopeName',
    '300A0228': 'SourceIsotopeHalfLife',
    '300A0229': 'SourceStrengthUnits',
    '300A022A': 'ReferenceAirKermaRate',
    '300A022B': 'SourceStrength',
    '300A022C': 'SourceStrengthReferenceDate',
    '300A022E': 'SourceStrengthReferenceTime',
    '300A0230': 'ApplicationSetupSequence',
    '300A0232': 'ApplicationSetupType',
    '300A0234': 'ApplicationSetupNumber',
    '300A0236': 'ApplicationSetupName',
    '300A0238': 'ApplicationSetupManufacturer',
    '300A0240': 'TemplateNumber',
    '300A0242': 'TemplateType',
    '300A0244': 'TemplateName',
    '300A0250': 'TotalReferenceAirKerma',
    '300A0260': 'BrachyAccessoryDeviceSequence',
    '300A0262': 'BrachyAccessoryDeviceNumber',
    '300A0263': 'BrachyAccessoryDeviceID',
    '300A0264': 'BrachyAccessoryDeviceType',
    '300A0266': 'BrachyAccessoryDeviceName',
    '300A026A': 'BrachyAccessoryDeviceNomThickness',
    '300A026C': 'BrachyAccessoryDevNomTransmission',
    '300A0280': 'ChannelSequence',
    '300A0282': 'ChannelNumber',
    '300A0284': 'ChannelLength',
    '300A0286': 'ChannelTotalTime',
    '300A0288': 'SourceMovementType',
    '300A028A': 'NumberOfPulses',
    '300A028C': 'PulseRepetitionInterval',
    '300A0290': 'SourceApplicatorNumber',
    '300A0291': 'SourceApplicatorID',
    '300A0292': 'SourceApplicatorType',
    '300A0294': 'SourceApplicatorName',
    '300A0296': 'SourceApplicatorLength',
    '300A0298': 'SourceApplicatorManufacturer',
    '300A029C': 'SourceApplicatorWallNomThickness',
    '300A029E': 'SourceApplicatorWallNomTrans',
    '300A02A0': 'SourceApplicatorStepSize',
    '300A02A2': 'TransferTubeNumber',
    '300A02A4': 'TransferTubeLength',
    '300A02B0': 'ChannelShieldSequence',
    '300A02B2': 'ChannelShieldNumber',
    '300A02B3': 'ChannelShieldID',
    '300A02B4': 'ChannelShieldName',
    '300A02B8': 'ChannelShieldNominalThickness',
    '300A02BA': 'ChannelShieldNominalTransmission',
    '300A02C8': 'FinalCumulativeTimeWeight',
    '300A02D0': 'BrachyControlPointSequence',
    '300A02D2': 'ControlPointRelativePosition',
    '300A02D4': 'ControlPoint3DPosition',
    '300A02D6': 'CumulativeTimeWeight',
    '300A02E0': 'CompensatorDivergence',
    '300A02E1': 'CompensatorMountingPosition',
    '300A02E2': 'SourceToCompensatorDistance',
    '300A02E3': 'TotalCompTrayWaterEquivThickness',
    '300A02E4': 'IsocenterToCompensatorTrayDistance',
    '300A02E5': 'CompensatorColumnOffset',
    '300A02E6': 'IsocenterToCompensatorDistances',
    '300A02E7': 'CompensatorRelStoppingPowerRatio',
    '300A02E8': 'CompensatorMillingToolDiameter',
    '300A02EA': 'IonRangeCompensatorSequence',
    '300A02EB': 'CompensatorDescription',
    '300A0302': 'RadiationMassNumber',
    '300A0304': 'RadiationAtomicNumber',
    '300A0306': 'RadiationChargeState',
    '300A0308': 'ScanMode',
    '300A030A': 'VirtualSourceAxisDistances',
    '300A030C': 'SnoutSequence',
    '300A030D': 'SnoutPosition',
    '300A030F': 'SnoutID',
    '300A0312': 'NumberOfRangeShifters',
    '300A0314': 'RangeShifterSequence',
    '300A0316': 'RangeShifterNumber',
    '300A0318': 'RangeShifterID',
    '300A0320': 'RangeShifterType',
    '300A0322': 'RangeShifterDescription',
    '300A0330': 'NumberOfLateralSpreadingDevices',
    '300A0332': 'LateralSpreadingDeviceSequence',
    '300A0334': 'LateralSpreadingDeviceNumber',
    '300A0336': 'LateralSpreadingDeviceID',
    '300A0338': 'LateralSpreadingDeviceType',
    '300A033A': 'LateralSpreadingDeviceDescription',
    '300A033C': 'LateralSpreadingDevWaterEquivThick',
    '300A0340': 'NumberOfRangeModulators',
    '300A0342': 'RangeModulatorSequence',
    '300A0344': 'RangeModulatorNumber',
    '300A0346': 'RangeModulatorID',
    '300A0348': 'RangeModulatorType',
    '300A034A': 'RangeModulatorDescription',
    '300A034C': 'BeamCurrentModulationID',
    '300A0350': 'PatientSupportType',
    '300A0352': 'PatientSupportID',
    '300A0354': 'PatientSupportAccessoryCode',
    '300A0356': 'FixationLightAzimuthalAngle',
    '300A0358': 'FixationLightPolarAngle',
    '300A035A': 'MetersetRate',
    '300A0360': 'RangeShifterSettingsSequence',
    '300A0362': 'RangeShifterSetting',
    '300A0364': 'IsocenterToRangeShifterDistance',
    '300A0366': 'RangeShifterWaterEquivThickness',
    '300A0370': 'LateralSpreadingDeviceSettingsSeq',
    '300A0372': 'LateralSpreadingDeviceSetting',
    '300A0374': 'IsocenterToLateralSpreadingDevDist',
    '300A0380': 'RangeModulatorSettingsSequence',
    '300A0382': 'RangeModulatorGatingStartValue',
    '300A0384': 'RangeModulatorGatingStopValue',
    '300A038A': 'IsocenterToRangeModulatorDistance',
    '300A0390': 'ScanSpotTuneID',
    '300A0392': 'NumberOfScanSpotPositions',
    '300A0394': 'ScanSpotPositionMap',
    '300A0396': 'ScanSpotMetersetWeights',
    '300A0398': 'ScanningSpotSize',
    '300A039A': 'NumberOfPaintings',
    '300A03A0': 'IonToleranceTableSequence',
    '300A03A2': 'IonBeamSequence',
    '300A03A4': 'IonBeamLimitingDeviceSequence',
    '300A03A6': 'IonBlockSequence',
    '300A03A8': 'IonControlPointSequence',
    '300A03AA': 'IonWedgeSequence',
    '300A03AC': 'IonWedgePositionSequence',
    '300A0401': 'ReferencedSetupImageSequence',
    '300A0402': 'SetupImageComment',
    '300A0410': 'MotionSynchronizationSequence',
    '300A0412': 'ControlPointOrientation',
    '300A0420': 'GeneralAccessorySequence',
    '300A0421': 'GeneralAccessoryID',
    '300A0422': 'GeneralAccessoryDescription',
    '300A0423': 'GeneralAccessoryType',
    '300A0424': 'GeneralAccessoryNumber',
    '300C0002': 'ReferencedRTPlanSequence',
    '300C0004': 'ReferencedBeamSequence',
    '300C0006': 'ReferencedBeamNumber',
    '300C0007': 'ReferencedReferenceImageNumber',
    '300C0008': 'StartCumulativeMetersetWeight',
    '300C0009': 'EndCumulativeMetersetWeight',
    '300C000A': 'ReferencedBrachyAppSetupSeq',
    '300C000C': 'ReferencedBrachyAppSetupNumber',
    '300C000E': 'ReferencedSourceNumber',
    '300C0020': 'ReferencedFractionGroupSequence',
    '300C0022': 'ReferencedFractionGroupNumber',
    '300C0040': 'ReferencedVerificationImageSeq',
    '300C0042': 'ReferencedReferenceImageSequence',
    '300C0050': 'ReferencedDoseReferenceSequence',
    '300C0051': 'ReferencedDoseReferenceNumber',
    '300C0055': 'BrachyReferencedDoseReferenceSeq',
    '300C0060': 'ReferencedStructureSetSequence',
    '300C006A': 'ReferencedPatientSetupNumber',
    '300C0080': 'ReferencedDoseSequence',
    '300C00A0': 'ReferencedToleranceTableNumber',
    '300C00B0': 'ReferencedBolusSequence',
    '300C00C0': 'ReferencedWedgeNumber',
    '300C00D0': 'ReferencedCompensatorNumber',
    '300C00E0': 'ReferencedBlockNumber',
    '300C00F0': 'ReferencedControlPointIndex',
    '300C00F2': 'ReferencedControlPointSequence',
    '300C00F4': 'ReferencedStartControlPointIndex',
    '300C00F6': 'ReferencedStopControlPointIndex',
    '300C0100': 'ReferencedRangeShifterNumber',
    '300C0102': 'ReferencedLateralSpreadingDevNum',
    '300C0104': 'ReferencedRangeModulatorNumber',
    '300E0002': 'ApprovalStatus',
    '300E0004': 'ReviewDate',
    '300E0005': 'ReviewTime',
    '300E0008': 'ReviewerName',
    40000000: 'TextGroupLength',
    40000010: 'Arbitrary',
    40004000: 'TextComments',
    40080040: 'ResultsID',
    40080042: 'ResultsIDIssuer',
    40080050: 'ReferencedInterpretationSequence',
    40080100: 'InterpretationRecordedDate',
    40080101: 'InterpretationRecordedTime',
    40080102: 'InterpretationRecorder',
    40080103: 'ReferenceToRecordedSound',
    40080108: 'InterpretationTranscriptionDate',
    40080109: 'InterpretationTranscriptionTime',
    '4008010A': 'InterpretationTranscriber',
    '4008010B': 'InterpretationText',
    '4008010C': 'InterpretationAuthor',
    40080111: 'InterpretationApproverSequence',
    40080112: 'InterpretationApprovalDate',
    40080113: 'InterpretationApprovalTime',
    40080114: 'PhysicianApprovingInterpretation',
    40080115: 'InterpretationDiagnosisDescription',
    40080117: 'InterpretationDiagnosisCodeSeq',
    40080118: 'ResultsDistributionListSequence',
    40080119: 'DistributionName',
    '4008011A': 'DistributionAddress',
    40080200: 'InterpretationID',
    40080202: 'InterpretationIDIssuer',
    40080210: 'InterpretationTypeID',
    40080212: 'InterpretationStatusID',
    40080300: 'Impressions',
    40084000: 'ResultsComments',
    '4FFE0001': 'MACParametersSequence',
    '50..0005': 'CurveDimensions',
    '50..0010': 'NumberOfPoints',
    '50..0020': 'TypeOfData',
    '50..0022': 'CurveDescription',
    '50..0030': 'AxisUnits',
    '50..0040': 'AxisLabels',
    '50..0103': 'DataValueRepresentation',
    '50..0104': 'MinimumCoordinateValue',
    '50..0105': 'MaximumCoordinateValue',
    '50..0106': 'CurveRange',
    '50..0110': 'CurveDataDescriptor',
    '50..0112': 'CoordinateStartValue',
    '50..0114': 'CoordinateStepValue',
    '50..1001': 'CurveActivationLayer',
    '50..2000': 'AudioType',
    '50..2002': 'AudioSampleFormat',
    '50..2006': 'NumberOfSamples',
    '50..2008': 'SampleRate',
    '50..200A': 'TotalTime',
    '50..200C': 'AudioSampleData',
    '50..200E': 'AudioComments',
    '50..2500': 'CurveLabel',
    '50..2610': 'ReferencedOverlayGroup',
    '50..3000': 'CurveData',
    52009229: 'SharedFunctionalGroupsSequence',
    52009230: 'PerFrameFunctionalGroupsSequence',
    54000100: 'WaveformSequence',
    54000110: 'ChannelMinimumValue',
    54000112: 'ChannelMaximumValue',
    54001004: 'WaveformBitsAllocated',
    54001006: 'WaveformSampleInterpretation',
    '5400100A': 'WaveformPaddingValue',
    54001010: 'WaveformData',
    56000010: 'FirstOrderPhaseCorrectionAngle',
    56000020: 'SpectroscopyData',
    60000000: 'OverlayGroupLength',
    '60..0010': 'OverlayRows',
    '60..0011': 'OverlayColumns',
    '60..0012': 'OverlayPlanes',
    '60..0015': 'NumberOfFramesInOverlay',
    '60..0022': 'OverlayDescription',
    '60..0040': 'OverlayType',
    '60..0045': 'OverlaySubtype',
    '60..0050': 'OverlayOrigin',
    '60..0051': 'ImageFrameOrigin',
    '60..0052': 'OverlayPlaneOrigin',
    '60..0060': 'OverlayCompressionCode',
    '60..0061': 'OverlayCompressionOriginator',
    '60..0062': 'OverlayCompressionLabel',
    '60..0063': 'OverlayCompressionDescription',
    '60..0066': 'OverlayCompressionStepPointers',
    '60..0068': 'OverlayRepeatInterval',
    '60..0069': 'OverlayBitsGrouped',
    '60..0100': 'OverlayBitsAllocated',
    '60..0102': 'OverlayBitPosition',
    '60..0110': 'OverlayFormat',
    '60..0200': 'OverlayLocation',
    '60..0800': 'OverlayCodeLabel',
    '60..0802': 'OverlayNumberOfTables',
    '60..0803': 'OverlayCodeTableLocation',
    '60..0804': 'OverlayBitsForCodeWord',
    '60..1001': 'OverlayActivationLayer',
    '60..1100': 'OverlayDescriptorGray',
    '60..1101': 'OverlayDescriptorRed',
    '60..1102': 'OverlayDescriptorGreen',
    '60..1103': 'OverlayDescriptorBlue',
    '60..1200': 'OverlaysGray',
    '60..1201': 'OverlaysRed',
    '60..1202': 'OverlaysGreen',
    '60..1203': 'OverlaysBlue',
    '60..1301': 'ROIArea',
    '60..1302': 'ROIMean',
    '60..1303': 'ROIStandardDeviation',
    '60..1500': 'OverlayLabel',
    '60..3000': 'OverlayData',
    '60..4000': 'OverlayComments',
    '7F..0000': 'PixelDataGroupLength',
    '7F..0010': 'PixelData',
    '7F..0011': 'VariableNextDataGroup',
    '7F..0020': 'VariableCoefficientsSDVN',
    '7F..0030': 'VariableCoefficientsSDHN',
    '7F..0040': 'VariableCoefficientsSDDN',
    FFFAFFFA: 'DigitalSignaturesSequence',
    FFFCFFFC: 'DataSetTrailingPadding',
    FFFEE000: 'StartOfItem',
    FFFEE00D: 'EndOfItems',
    FFFEE0DD: 'EndOfSequence',
  },
};
