<template>
  <div class="fluid-container footer">
    <p class="text-center">
      Entrolytics&trade; by Motilent | The Entrolytics platform and associated tools are for research and non-clinical use only
      | <router-link
        to="/termsconditions"
      >
        Terms and Conditions
      </router-link>
      |
      Support:
      <a
        href="mailto:support@motilent.co.uk?subject=Entrolytics Support"
        style="padding-top: 0.5rem"
      >
        support@motilent.co.uk</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'NavFooter',
};

</script>
