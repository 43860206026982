<template>
  <svg
    className="icon"
    width="100mm"
    height="20mm"
    viewBox="0 0 393.07106 64.251968"
  >
    <g transform="translate(-5496.754 -946.64)" style="fill:#FFFFFF;">
      <path d="M5588.2 1000.2v6.4c-.1 1.3.3 2.7 1.1 3.7 1 1.1 2.5 1.7 4 1.6.7 0 1.5-.1 2.2-.3l-.6-2.6c-.5.1-1 .2-1.6.2-.7 0-1.4-.2-1.9-.7-.3-.5-.5-1.2-.4-1.8v-6.6h4.5v-2.5h-4.5v-4.3h-2.8v6.9zm-13.1-2.6h-2.8v14.1h2.8v-9.7c1-1.2 2.5-2 4-2 1.1 0 2.2.5 2.9 1.4.7.9 1 2.1.8 3.3v7h2.8v-6.9c.1-1.8-.3-3.5-1.4-5-1.2-1.6-3.1-2.5-5.1-2.5-1.6 0-3.1.6-4.2 1.6l.2-1.3zm-11.5 2.2c1.8.1 3.3 1.3 3.7 3.1h-7.7c.5-1.8 2.2-3.1 4-3.1m6.7 5.5v-.6c0-4.2-2.8-7.3-6.8-7.3s-6.9 3.1-6.9 7.3c-.1 3.9 3 7.2 7 7.3h.3c2.3.1 4.5-.9 5.9-2.6l-1.9-1.8c-1 1.2-2.5 1.9-4 1.9-2.4 0-4.4-1.9-4.6-4.3l11 .1zm-18.6 6.4h2.8v-18.4h-2.8v18.4zm-6.1 0h2.8v-14.1h-2.8v14.1zm-9.5-11.5v6.4c-.1 1.3.3 2.7 1.1 3.7 1 1.1 2.5 1.7 4 1.6.7 0 1.5-.1 2.2-.3l-.6-2.6c-.5.1-1 .2-1.6.2-.7 0-1.4-.2-1.9-.7-.3-.5-.5-1.2-.4-1.8v-6.6h4.5v-2.5h-4.5v-4.3h-2.8v6.9zm-13.9 4.5c.1-2.4 2.2-4.3 4.6-4.2s4.3 2.2 4.2 4.6c-.1 2.4-2.1 4.2-4.4 4.2-2.4 0-4.4-2-4.4-4.4v-.2m-2.8 0c0 4 3.3 7.2 7.3 7.2s7.2-3.3 7.2-7.3-3.3-7.2-7.3-7.2c-3.9-.1-7.2 3-7.3 7 .1 0 .1.2.1.3m-5.3-7.1v14.1h3v-21h-2.1l-8.1 12.8-8.1-12.8h-2.1v21h3v-14.1l6.1 9.6h2.1l6.2-9.6z" />
      <path d="M5567.7 982v5.3h-2.7c-3.9 0-7.1-3.2-7.1-7.1v-26.4c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8v26.4c0 3.9-3.2 7.1-7.1 7.1-3.9 0-7.1-3.2-7.1-7.1v-26.4c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8v26.4c0 3.9-3.2 7.1-7.1 7.1-3.9 0-7.1-3.2-7.1-7.1v-7.8c0-1-.8-1.8-1.8-1.8h-2.8v-5.3h2.8c3.9 0 7.1 3.2 7.1 7.1v7.8c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8v-26.4c0-3.9 3.2-7.1 7.1-7.1 3.9 0 7.1 3.2 7.1 7.1v26.4c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8v-26.4c0-3.9 3.2-7.1 7.1-7.1s7.1 3.2 7.1 7.1v26.4c0 1 .8 1.8 1.8 1.8h2.7z" />
      <path d="M5573.7 987.3c1.5 0 2.7-1.2 2.7-2.7s-1.2-2.7-2.7-2.7c-1.5 0-2.7 1.2-2.7 2.7 0 1.5 1.2 2.7 2.7 2.7" />
      <path d="M5573.7 987.9c-1.8 0-3.3-1.5-3.3-3.3 0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 1.8-1.5 3.3-3.3 3.3z" />
      <path d="M5511.3 970.5c1.5 0 2.7-1.2 2.7-2.7s-1.2-2.7-2.7-2.7-2.7 1.2-2.7 2.7c0 1.5 1.2 2.7 2.7 2.7" />
      <path d="M5545.2 993.4c0 1 .8 1.8 1.8 1.8h.1c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8c-1.1-.1-1.9.7-1.9 1.8" />
    </g>
  </svg>
</template>

<script>

export default {
  name: 'EntrolyticsLogo',
};
</script>
