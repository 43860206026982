export default {
  name2customdicom: {
    SumFavorites: '00012345',
    SumComments: '00012346',
  },
  customdicom2name: {
    '00012345': 'SumFavorites',
    '00012346': 'SumComments',
  },
};
