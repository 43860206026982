/* eslint-disable */
import Vue from 'vue';

export function getClientRolesFromToken() {
  const clientRoles = {
    AlbumComments: false,
    AlbumSettings: false,
    AlbumSettingsReportProviders: false,
    AlbumSettingsTokens: false,
    CreateAlbum: false,
    CommentsPrivate: false,
    InboxAlbum: false,
    StudyComments: false,
    StudyListPatientId: false,
    StudyListPatientName: false,
    StudyListStudyDescription: false,
    StudyListStudyDate: false,
    StudyMetadata: false,
    StudySeries: false,
    UserTokens: false
  };

  if (Vue.prototype.$keycloak.authenticated) {
    if (
      Vue.prototype.$keycloak.tokenParsed.resource_access &&
      Vue.prototype.$keycloak.tokenParsed.resource_access[
        process.env.VUE_APP_CLIENTID
      ]
    ) {
      const roles =
        Vue.prototype.$keycloak.tokenParsed.resource_access[
          process.env.VUE_APP_CLIENTID
        ].roles;

      clientRoles.AlbumComments = roles.includes('album-comments');
      clientRoles.AlbumSettings = roles.includes('album-settings');
      clientRoles.AlbumSettingsReportProviders = roles.includes('album-settings-report-providers');
      clientRoles.AlbumSettingsTokens= roles.includes('album-settings-tokens');
      clientRoles.CreateAlbum = roles.includes('create-album');
      clientRoles.CommentsPrivate = roles.includes('comments-private');
      clientRoles.InboxAlbum = roles.includes('inbox-album');
      clientRoles.StudyComments = roles.includes('study-comments');
      clientRoles.StudyListPatientId = roles.includes('study-list-patient-id');
      clientRoles.StudyListPatientName = roles.includes('study-list-patient-name');
      clientRoles.StudyListStudyDescription = roles.includes('study-list-study-description');
      clientRoles.StudyListStudyDate = roles.includes('study-list-study-date');
      clientRoles.StudyMetadata = roles.includes('study-metadata');
      clientRoles.StudySeries = roles.includes('study-series');
      clientRoles.UserTokens = roles.includes('user-tokens');
    }
  }
  return clientRoles;
}

export const CurrentUser = {
  computed: {
    authenticated() {
      return Vue.prototype.$keycloak.authenticated;
    },
    currentuserOnView() {
      return window.location.pathname.includes('view');
    },
    currentuserCapabilitiesToken() {
      if (window.location.pathname.includes('view')) {
        const [, , token] = window.location.pathname.split('/');
        return token;
      }
      return undefined;
    },
    currentuserKeycloakToken() {
      return Vue.prototype.$keycloak.token;
    },
    currentuserSub() {
      return Vue.prototype.$keycloak.idTokenParsed !== null
        ? Vue.prototype.$keycloak.idTokenParsed.sub
        : null;
    },
    currentuserEmail() {
      return Vue.prototype.$keycloak.idTokenParsed !== null
        ? Vue.prototype.$keycloak.idTokenParsed.email
        : null;
    },
    currentuserFullname() {
      return Vue.prototype.$keycloak.idTokenParsed !== null
        ? Vue.prototype.$keycloak.idTokenParsed.name
        : null;
    },
  },
  methods: {
    currentuserAccessToken() {
      if (window.location.pathname.includes('view')) {
        const [, , token] = window.location.pathname.split('/');
        return token;
      }
      if (Vue.prototype.$keycloak.authenticated) {
        return Vue.prototype.$keycloak.token;
      }
      return '';
    },
    getClientRoles() {
      return getClientRolesFromToken();
    },
  },
};
