<i18n>
{
  "en": {
    "studies": "Studies",
    "comments": "Comments",
    "settings": "Settings"
  },
  "fr": {
    "studies": "Etudes",
    "comments": "Commentaires",
    "settings": "Réglages"
  }
}
</i18n>

<template>
  <nav class="nav nav-pills nav-fill flex-column flex-lg-row text-center justify-content-lg-end">
    <router-link
      v-if="album.is_admin || getClientRoles().AlbumComments || getClientRoles().AlbumSettings"
      :to="{ name: 'albumview', params: { view: 'studies' }}"
      class="nav-link"
      :class="(currentView === 'studies' || currentView === undefined)?'active':''"
      active-class="active"
    >
      {{ $t('studies') }}
    </router-link>
    <router-link
      v-if="getClientRoles().AlbumComments"
      :to="{ name: 'albumview', params: { view: 'comments' }}"
      class="nav-link"
      active-class="active"
    >
      {{ $t('comments') }}
    </router-link>
    <router-link
      v-if="album.is_admin || getClientRoles().AlbumSettings"
      :to="{ name: 'albumview', params: { view: 'settings' }}"
      class="nav-link"
      active-class="active"
    >
      {{ $t('settings') }}
    </router-link>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import { CurrentUser } from '@/mixins/currentuser.js';

export default {
  name: 'AlbumMenu',
  mixins: [CurrentUser],
  computed: {
    currentView() {
      return this.$route.params.view;
    },
    ...mapGetters({
      album: 'album',
    }),
  }
};
</script>
