var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.UI.show)?_c('div',{staticClass:"chat-popup container-fluid p-0"},[_c('div',{staticClass:"closeBtn d-flex"},[(_vm.sending === true && _vm.UI.getInfo === false)?_c('div',{staticClass:"p-2"},[_c('clip-loader',{attrs:{"loading":_vm.sending,"size":'20px',"color":'white'}})],1):(_vm.sending === false && _vm.UI.getInfo === false)?_c('div',{staticClass:"p-2"},[(_vm.error.length === 0 && _vm.totalUnknownFilesError === 0)?_c('done-icon',{attrs:{"height":'20',"width":'20'}}):_vm._e(),(
            (_vm.error.length > 0 || _vm.totalUnknownFilesError > 0) &&
              _vm.error.length + _vm.totalUnknownFilesError < _vm.totalSize
          )?_c('v-icon',{attrs:{"name":"warning","height":'20',"width":'20',"color":"red"}}):_vm._e(),(
            (_vm.error.length === _vm.totalSize ||
              _vm.totalUnknownFilesError === _vm.totalSize) &&
              _vm.totalSize !== 0
          )?_c('error-icon',{attrs:{"height":'20',"width":'20',"color":"red"}}):_vm._e()],1):(_vm.UI.getInfo === true)?_c('div',{staticClass:"p-2 text-danger"},[_c('v-icon',{staticClass:"align-middle",attrs:{"name":"warning","height":'20',"width":'20'}})],1):_vm._e(),_c('div',{staticClass:"p-2"},[(_vm.sending === true && _vm.UI.getInfo === false)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('titleBoxSending'))+" ")]):(_vm.sending === false && _vm.UI.getInfo === false)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('titleBoxSended'))+" ")]):(_vm.UI.getInfo === true)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('titleBoxDicomize'))+" ")]):_vm._e()]),_c('div',{staticClass:"ml-auto p-1"},[_c('button',{staticClass:"btn btn-link btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.closeWindow()}}},[_c('close-icon',{attrs:{"height":_vm.UI.SVGHeaderHeight,"width":_vm.UI.SVGHeaderWidth}})],1)])]),(_vm.UI.hide === false)?_c('div',{staticClass:"p-2"},[(_vm.UI.getInfo)?_c('div',{staticClass:"mb-2"},[_c('input-dicomize',{attrs:{"files-to-dicomize":_vm.filesToDicomize,"create-study":_vm.studyUIDToSend === '' ? true : false},on:{"valid-dicom-value":_vm.validDicomValue}})],1):_vm._e(),(_vm.files.length > 0 && _vm.sending === true)?_c('div',[(_vm.UI.cancel === false)?_c('div',[_c('b-progress-bar',{staticClass:"text-center",attrs:{"value":_vm.countSentFiles + _vm.progress,"max":_vm.totalSize,"show-progress":"","animated":""}},[_vm._v(" "+_vm._s(_vm.countSentFiles)+" / "+_vm._s(_vm.totalSize)+" ")]),_c('div',{staticClass:"d-flex justify-content-center mt-1 mb-1"},[_c('button',{staticClass:"btn btn-link btn-sm text-center text-warning",attrs:{"type":"button"},on:{"click":function($event){return _vm.setCancel()}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('block-icon',{attrs:{"height":_vm.UI.SVGheight,"width":_vm.UI.SVGwidth,"color":"red"}})],1)])],1):_c('div',[_c('clip-loader',{attrs:{"loading":_vm.UI.cancel,"size":_vm.UI.SpinnerCancelSize,"color":'red'}})],1)]):(
          _vm.UI.show === true &&
            (_vm.countSentFiles === _vm.totalSize || _vm.sending === false)
        )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-11 mt-2 mb-2 ml-3"},[_vm._v(" "+_vm._s(_vm.$tc( 'filesSend', _vm.countSentFiles - _vm.error.length - _vm.totalUnknownFilesError, { count: _vm.countSentFiles - _vm.error.length - _vm.totalUnknownFilesError, }, ))+" "+_vm._s(_vm.$tc('locationSend', _vm.sourceIsAlbum ? 0 : 1))+" "),(_vm.sourceIsAlbum)?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'album',
                params: { album_id: _vm.sourceSending.value },
              }}},[_vm._v(" "+_vm._s(_vm.$t('album'))+" ")])],1):_vm._e(),(_vm.studyUIDToSend !== '')?_c('span'):_vm._e(),(Object.keys(_vm.listErrorUnknownFiles).length > 0)?_c('div',_vm._l((_vm.listErrorUnknownFiles),function(item,key){return _c('div',{key:item.key},[_vm._v(" "+_vm._s(_vm.$tc('unknownError', item, { count: item }))+" "),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v(" "+_vm._s(_vm.generateTextError(key))+" ")])])}),0):_vm._e(),(_vm.error.length > 0)?_c('div',[_c('div',{staticClass:"mb-1"},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc('filesErrors', _vm.error.length, { count: _vm.error.length }))+" ")])]),_c('div',{staticClass:"mb-1"},[_c('a',{staticClass:"text-center text-neutral",on:{"click":_vm.retry}},[_vm._v(" "+_vm._s(_vm.$t('reload'))+" ")])]),_c('a',{staticClass:"text-center text-warning",on:{"click":function($event){_vm.UI.showErrors = !_vm.UI.showErrors}}},[(!_vm.UI.showErrors)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('showError'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('hideError'))+" ")]),_c('error-icon',{attrs:{"height":_vm.UI.SVGheight,"width":_vm.UI.SVGwidth,"color":"red"}})],1)]):_vm._e()])]):_vm._e(),(_vm.error.length > 0 && _vm.UI.showErrors)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-2 mb-2"},[_c('list-error-files',{attrs:{"error-files":_vm.error},on:{"show-errors":_vm.setShowErrors}})],1)]):_vm._e()]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }